import React, { useState, useEffect } from "react";
import { CRUDLayout, DataStatus, THead, Tr, ThFixed, Th, TBody, TdFixed, Td, TableLayout } from "components";
import Axios from "axios";
import COAGroupApi from "./__COAGroupApi__";

export const COAGroupList = () => {
  const menuTitle = "CHART OF ACCOUNT GROUP"
  const [listCOAGroup, setListCOAGroup] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getData = () => {
    setIsLoading(true);

    Axios.all([
      COAGroupApi.getList(),
      // COAGroupApi.dropdownAccountType(1)
    ])
      .then(Axios.spread((res, drop) => {
        // const type = drop?.data?.payload ? drop?.data?.payload?.map(val => ({ label: val?.account_type_name, value: val?.account_type_id })) : []

        // setDropdownType(type)
        setListCOAGroup(res?.data?.payload)
      }))
      .catch((err) => alert(err))
      .finally(() => setIsLoading(false));
  };
  useEffect(() => {
    getData();
    return () => setIsLoading(false)
  }, []);

  const Table = () => {
    return (
      <div className="px-3 pt-3">
        <CRUDLayout.Table>
          <THead>
            <Tr className="text-center">
              <ThFixed>No</ThFixed>
              <ThFixed><div style={{ width: 100 }}>CODE</div></ThFixed>
              <ThFixed><div style={{ width: 300 }}>COA GROUP</div></ThFixed>
              <ThFixed><div style={{ width: 300 }}>REPORT TYPE</div></ThFixed>
              <Th>DESCRIPTION</Th>
            </Tr>
          </THead>
          <TBody>
            { listCOAGroup.length < 0 
              ? <Tr><Td colSpan={6}><div className="text-center">Tidak Ada Data</div></Td></Tr>
              : listCOAGroup?.map((val, index) => {
                  return (
                    <Tr key={val.id}>
                      <TdFixed>{index + 1}</TdFixed>
                      <TdFixed>{val?.group_coa_code ?? "-"}</TdFixed>
                      <Td>{val?.group_coa_name ?? "-"}</Td>
                      <Td>{val?.group_coa_type ? val?.group_coa_type === 1 ? "BALANCE SHEET" : "PROFIT AND LOST" : "-"}</Td>
                      <Td>{val?.description ?? "-"}</Td>
                    </Tr>
                  );
                }
            )}
          </TBody>
        </CRUDLayout.Table>
      </div>
    );
  };

  return (
    <CRUDLayout>
      {isLoading 
        ? <DataStatus loading={isLoading} text="Memuat Data" />
        : !Array.isArray(listCOAGroup)
        ? <DataStatus text="Tidak dapat memuat data" />
        : <TableLayout noMarginTop label={menuTitle}>
            <Table />
          </TableLayout>
      }
    </CRUDLayout>
  );
};

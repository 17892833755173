export const formPersonalValues = (data = {}) => ({
    employee_code: data?.employee_code ?? data ?? "",
    employee_name: data?.employee_name ?? "",
    account: data?.account ?? "",
    // passwd: data?.passwd ?? "",
    // confirm_passwd: data?.confirm_passwd ?? "",
    passwd: "",
    confirm_passwd: "",
    Customer_Foto: data?.foto ?? ""
  });
  
import React from 'react'
import { CreateButton } from 'components/Button'
import { Card } from 'react-bootstrap'
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai"
import { Input } from 'antd';

export const TableLayout = ({children, label, withCreateButton, withBackButton, onClick, noMarginTop, infoButton}) => {
  return (
    <Card style={{ marginTop: noMarginTop ? "" : "170px" }}>
        <Card.Header className="bg-white d-flex justify-content-between align-items-center">
            <b className='text-uppercase' style={{ fontSize: "15px", textShadow: "0 0.5px 0.5px #000" }}>{label}</b>
            {withCreateButton && 
              <CreateButton 
                background="black" 
                label={<div className='d-flex align-items-center'>CREATE NEW <AiOutlineRight className='ml-2' /></div>} 
                onClick={onClick} 
              />
            }
            {withBackButton &&
              <CreateButton 
                tooltipText="Back"
                background="black" 
                label={<div className='d-flex align-items-center'><AiOutlineLeft className='mr-2' />BACK</div>} 
                onClick={onClick} 
              />
            }
            {infoButton &&
              <div style={{ width: "250px" }}>
                <Input disabled />
              </div>
            }
        </Card.Header>
        {children}
    </Card>
  )
}

import Services from "../../../../../../services";

class ShowAllApi {
  getList(params) {
    return Services.get("/coa/", { params });
  }
  dropdownCOAGroup(params) {
    return Services.get("/groupcoa/", { params });
  }
  dropdownCOAType(id) {
    return Services.get(`/acctype/byidgroup?groupid=${id}`);
  }
  careteCOA(data) {
    return Services.post("/coa/add", data);
  }
  updateCOA(data, id) {
    return Services.put(`/coa/edit?coa_id=${id}`, data);
  }
  delete(id) {
    return Services.delete(`/coa/delete?coa_id=${id}`);
  }
}

export default new ShowAllApi();

import * as Yup from "yup";

export const formValidationSchema = Yup.object().shape({
    location_name: Yup.string().required("Location is required !"),
    address: Yup.string().required("Address is required !"),
    country_id: Yup.string().required("Country is required !"),
    province_id: Yup.string().required("Province is required !"),
    regency_id: Yup.string().required("Regency is required !"),
    subdistrict_id: Yup.string().required("Subdistrict is required !"),
    village_id: Yup.string().required("Village is required !"),
});

import React from 'react'
import { Modal } from 'react-bootstrap'

export const CRUDModalSection = ({show, size, children, title, type, onHide, deleteCenter }) => {
  return (
    <Modal show={show} size={size} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title className='d-flex align-items-center'>
          <div>
            <div style={{ color: "#8E8E8E", fontSize: "12px", marginBottom: "-10px" }}>
                {type === "READ" ? "Detail" : type === "update" ? "Update" : type === "create" ? "Create" : type === "delete" ? "Delete" : "-"}
            </div>
            <b className='text-uppercase' style={{ fontSize: "15px", textShadow: "0 0.5px 0.5px #000" }}>{title ? title : ""}</b>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {type === "delete" && <h5 className={`mb-4 ${deleteCenter && "text-center"}`}>Apakah anda yakin akan menghapus data ini?</h5>}
        {children}
      </Modal.Body>
    </Modal>
  )
}

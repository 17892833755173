import React, { useState, useContext } from "react"
import { Col, Row } from "react-bootstrap"
import { IoPersonOutline, IoLockClosedOutline, IoEyeOutline, IoEyeOffOutline } from "react-icons/io5"
import { Formik } from "formik"
import * as Yup from "yup"
import JWTDecode from "jwt-decode"
import { ActionButton } from "../../components"
import { AuthMethod, AuthContext } from "../../utilities"
import { AuthApi } from "../../api"
import Config from "../../config"
import "./Login.css"


const Login = () => {
  const { dispatch } = useContext(AuthContext)
  const formData = new FormData();
  formData?.append('username', 'riski@gmail.com');
  formData?.append('password', '1234');

  const Input = props => (
    <div className="mb-3">
      <div className="input-group input-group-sm">
        <div className="input-group-prepend">
          <span className={`input-group-text bg-white ${props.error ? 'border border-danger' : ''}`}>
            {props.icon}
          </span>
        </div>
        <input 
          {...props}
          className={`form-control ${props.error ? 'is-invalid' : ''}`}
          style={{borderLeft: 'none'}}
        />
      </div>
      {props.error && (
        <div class="text-danger">
          <small>{props.errorText}</small>
        </div>
      )}
    </div>
  )
  const InputPassword = props => {
    const [showPass, setShowPass] = useState(false)

    return (
      <div className="mb-3">
        <div className="input-group input-group-sm">
          <div className="input-group-prepend">
            <span className={`input-group-text bg-white ${props.error ? 'border border-danger' : ''}`}>
              {props.icon}
            </span>
          </div>
          <input 
            {...props}
            type={showPass ? 'text' : 'password'}
            className={`form-control ${props.error ? 'is-invalid' : ''}`}
            style={{
              borderLeft: 'none',
              borderRight: 'none',
            }}
          />
          <div className="input-group-append" 
            style={{cursor: 'pointer'}}
            onClick={() => setShowPass(!showPass)}
          >
            <span className={`input-group-text bg-white ${props.error ? 'border border-danger' : ''}`}>
              {showPass ? <IoEyeOutline /> : <IoEyeOffOutline />}
            </span>
          </div>
        </div>
        {props.error && (
          <div class="text-danger">
            <small>{props.errorText}</small>
          </div>
        )}
      </div>
    )
  }
  const FormSection = () =>  {
    const formInitialValues = {
      username: "",
      password: ""
    }
    const formValidationSchema = Yup.object().shape({
      username: Yup.string().required("Masukan username"),
      password: Yup.string().required("Masukan password"),
    })
    const formSubmitHandler = (value, { resetForm }) => {
      const formData = new FormData();
      formData.append('username', String(value?.username));
      formData.append('password', String(value?.password));

      AuthApi.login(formData)
        .then((res) => {
          // console.log("res", res)
          const token = res?.data?.access_token
          sessionStorage?.setItem('token', token)
          const decode = JWTDecode(token)
          // console.log("decode", decode)
          const { username, hak } = decode

          dispatch({
            type: AuthMethod.LOGIN,
            payload: {
              isAuthenticated: true,
              token: token,
              username: username,
              role: hak,
            },
          })
            .catch(() => {
              return dispatch({type: AuthMethod?.LOGOUT})
            })
        })
        .catch(() => {
          resetForm({
            values: {
              username: value?.username,
              password: "",
            },
            errors: {
              username: "Username and password dont't match !",
              password: true,
            },
            touched: {
              username: true,
              password: true,
            },
          })
        })
    }

    return(
      <Formik
        enableReinitialize
        initialValues={formInitialValues}
        validationSchema={formValidationSchema}
        onSubmit={formSubmitHandler}
      >
        {({ values, errors, touched, isSubmitting, handleChange, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <div className="label text-white text-center mb-3" style={{ fontSize: "38px" }}><b>Welcome</b></div>
            <Input
              icon={<IoPersonOutline />}
              type="text"
              name="username"
              placeholder="Email"
              value={values.username}
              onChange={handleChange}
              error={errors.username && touched.username && true}
              errorText={errors.username}
            />
            <InputPassword
              icon={<IoLockClosedOutline />}
              type="text"
              name="password"
              placeholder="Password"
              value={values.password}
              onChange={handleChange}
              error={errors.password && touched.password && true}
              errorText={errors.password}
            />
            <ActionButton 
              type="submit" 
              text="Login" 
              className="col mt-2" 
              style={{ backgroundColor: "#B9A16A", border: "none" }}
              loading={isSubmitting}
            />
            <div className="text-white text-center mt-3" style={{ fontSize: "10px" }}>
              <span style={{ color: "#7B8499" }}>By continuing you agree to the Flying HAWK Indonesia<br /></span> terms
              of service <span style={{ color: "#7B8499" }}>and</span> privacy policy.
            </div>
          </form>
        )}
      </Formik>
    )
  }
  

  return (
    <div className="d-flex justify-content-center align-items-center p-3" style={{height: '100vh', width: '100%', backgroundColor: "#000000"}}>
      <Col xs={12} sm={6} md={4} lg={3} className="py-4 rounded shadow" style={{ backgroundColor: "#000000" }}>
        <img className="logo_login_true" src={Config.LOGO} width={320} />
        <FormSection />
      </Col>
      <div className="logo_login ml-5" style={{ border: "0.5px solid", height: "250px", color: "#E8E8E8" }}></div>
      <img className="logo_login" src={Config.LOGO} width={320} />
    </div>
  )
}
export default Login

import * as Yup from "yup";

export const formValidationProfile = Yup.object().shape({
    handphone: Yup.string().required("Handphone is required !"),
    email: Yup.string().required("Email is required !"),
    website: Yup.string().required("Website is required !"),
    card_label: Yup.string().required("Card label is required !"),
    group_card: Yup.string().required("Group is required !"),
    category_vendor_id: Yup.string().required("Category is required !"),
    source_from: Yup.string().required("Source is required !"),
    employee_id: Yup.string().required("Marketing is required !"),
    media: Yup.string().required("Media is required !"),
    price_level_id: Yup.string().required("Price level is required !"),
    payment_term_id: Yup.string().required("Payment term is required !"),
    max_balance: Yup.string().required("Max balance is required !"),
    bank_id: Yup.string().required("Bank name is required !"),
    bank_account: Yup.string().required("Bank account is required !"),
    bank_beneficiary: Yup.string().required("Bank beneficiary is required !"),
    bank_branch: Yup.string().required("Bank branch is required !"),
});

export const formWorkStatusValues = (data = {}) => ({
  employee_status_id: data?.employee_status_id ?? "",
  date_start: data?.date_start ?? "",
  date_end: data?.date_end ?? "",
  group_position_id: data?.group_position_id ?? "",
  job_position_id: data?.job_position_id ?? "",
  location_id: data?.location_id ?? "",
  department_id: data?.department_id ?? "",
  divisi_id: data?.divisi_id ?? "",
  gender: data?.gender ?? "",
  salary_type_id: data?.salary_type_id ?? "",
});
  
import React, { useState, useEffect } from "react";
import {
  CRUDLayout,
  DataStatus,
  UpdateButton,
  DeleteButton,
  THead,
  Tr,
  ThFixed,
  Th,
  TBody,
  TdFixed,
  Td,
  ReadButton,
  TableLayout,
  CRUDModalSection,
  CloseButton,
  Alert
} from "components";
import { Formik, Form } from "formik";
import TaxCodeApi from "./__TaxCodeApi__";
import { Content } from "./__TaxCodeComps__";
import { formInitialValues, formValidationSchema, formSubmitMapper } from "./__TaxCodeUtillities__";

export const TaxCodeList = () => {
  const menuTitle = "TAX CODE"
  const [listTaxCode, setListTaxCode] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [modalConfig, setModalConfig] = useState({
    show: false,
    action: "",
    data: {}
  });

  const getData = () => {
    setIsLoading(true);

    TaxCodeApi.getList()
      .then(res => setListTaxCode(res?.data?.payload))
      .catch((err) => alert(err))
      .finally(() => setIsLoading(false));
  };
  useEffect(() => {
    getData();

    return () => setIsLoading(false)
  }, []);

  const CreateModal = () => {
    const submitValidationHandler = (errors) =>
    new Promise((resolve, reject) => {
      const getError = Object.values(errors);

      if (getError.length > 0) {
        reject(getError);
      } else {
        resolve();
      }
    });
    const preSubmitHandler = (formik) => {
      const { values, validateForm, setTouched, setErrors, handleSubmit, setSubmitting } = formik;

      setSubmitting(true);
      validateForm().then(async (err) => {
        setErrors(err);
        setTouched(err);

        await submitValidationHandler(err, values)
          .then(() => handleSubmit())
          .catch((err) => window?.alert(err))
          .finally(() => setSubmitting(false));
      });
    };
    const formSubmitHandler = (values) => {
      setModalConfig({ ...modalConfig, show: false, action: "CREATE"  })
      const nonEmptyValues = Object.fromEntries(Object.entries(values).filter(([key, value]) => value !== ""));

      TaxCodeApi.create(nonEmptyValues)
        .then(() => setModalConfig({ ...modalConfig, show: true, action: "CREATE_SUCCESS"  }))
        .catch((err) =>  window?.alert(err))
        .finally(() => {
          setModalConfig({ ...modalConfig, show: false, action: "CREATE"  })
          getData();
        });
    };

    return (
      <Formik 
        enableReinitialize 
        initialValues={formInitialValues({})} 
        validationSchema={formValidationSchema} 
        onSubmit={(values) => formSubmitHandler(values)}
      >
        {(formik) => (
          <Form>
            <CRUDModalSection
              type="create" 
              title={menuTitle}
              show={Boolean(modalConfig?.show && modalConfig?.action === "CREATE")}
              onHide={() => setModalConfig({ ...modalConfig, show: false, action: "CREATE"  })}
            >
              <Content 
                type="CREATE"
                modalConfig={modalConfig} 
                setModalConfig={setModalConfig} 
                onSubmit={() => preSubmitHandler(formik)} 
              />
            </CRUDModalSection>
          </Form>
        )}
      </Formik>
    );
  };
  const UpdateModal = () => {
    const submitValidationHandler = (errors) =>
    new Promise((resolve, reject) => {
      const getError = Object.values(errors);

      if (getError.length > 0) {
        reject(getError);
      } else {
        resolve();
      }
    });
    const preSubmitHandler = (formik) => {
      const { values, validateForm, setTouched, setErrors, handleSubmit, setSubmitting } = formik;

      setSubmitting(true);
      validateForm().then(async (err) => {
        setErrors(err);
        setTouched(err);

        await submitValidationHandler(err, values)
          .then(() => handleSubmit())
          .catch((err) => window?.alert(err))
          .finally(() => setSubmitting(false));
      });
    };
    const formSubmitHandler = (values) => {
      setModalConfig({ ...modalConfig, show: false, action: "UPDATE"  })
      const nonEmptyValues = Object.fromEntries(Object.entries(values).filter(([key, value]) => value !== ""));

      TaxCodeApi.update(nonEmptyValues, modalConfig?.data?.tax_id)
        .then(() => setModalConfig({ ...modalConfig, show: true, action: "UPDATE_SUCCESS"  }))
        .catch((err) =>  window?.alert(err))
        .finally(() => {
          setModalConfig({ ...modalConfig, show: false, action: "UPDATE"  })
          getData();
        });
    };

    return (
      <Formik 
        enableReinitialize 
        initialValues={formInitialValues(modalConfig?.data)} 
        validationSchema={formValidationSchema} 
        onSubmit={(values) => formSubmitHandler(values)}
      >
        {(formik) => (
          <Form>
            <CRUDModalSection
              type="update" 
              title={menuTitle}
              show={Boolean(modalConfig?.show && modalConfig?.action === "UPDATE")}
              onHide={() => setModalConfig({ ...modalConfig, show: false, action: "UPDATE"  })}
            >
              <Content 
                type="UPDATE" 
                modalConfig={modalConfig} 
                setModalConfig={setModalConfig} 
                onSubmit={() => preSubmitHandler(formik)} 
              />
            </CRUDModalSection>
          </Form>
        )}
      </Formik>
    );
  };
  const DetailModal = () => {
    return (
      <Formik>
        <CRUDModalSection 
          type={modalConfig?.action}
          title={menuTitle}
          show={Boolean(modalConfig?.show && modalConfig?.action === "READ")}
          onHide={() => setModalConfig({ ...modalConfig, show: false, action: "READ" })}
        >
          <Content action="READ" data={modalConfig?.data} />
          <hr className='my-3'/>
          <div className="mt-3 d-flex">
            <CloseButton icon onClick={() => setModalConfig({ ...modalConfig, show: false, action: "DETAIL"  })} />
          </div>
        </CRUDModalSection>
      </Formik>
    )
  }
  const DeleteModal = () => {
    const deleteDataHandler = () => {
      TaxCodeApi.delete(modalConfig?.data?.tax_id)
        .then(() => setModalConfig({ ...modalConfig, show: true, action: "DELETE_SUCCESS"  }))
        .catch((err) => setModalConfig({ ...modalConfig, show: true, action: "DELETE_ERROR", data: `Delete Error! (${err?.response?.data?.message})` }))
        .finally(() => {
          setModalConfig({ ...modalConfig, show: false, action: "DELETE"  })
          getData();
        });
    };

    return (
      <CRUDModalSection
        type="delete"
        title={menuTitle} 
        show={Boolean(modalConfig?.show && modalConfig?.action === "DELETE")}
        onHide={() => setModalConfig({ ...modalConfig, show: false, action: "DELETE"  })}
      >
        <Content action="READ" data={modalConfig?.data} />

        <Alert textCenter text="Data akan dihapus secara permanen dan tidak dapat dikembalikan !" variant="danger" show={true} />

        <hr className='my-3'/>
        <div className="mt-3 d-flex">
          <CloseButton icon onClick={() => setModalConfig({ ...modalConfig, show: false, action: "UPDATE"  })} />
          <DeleteButton icon forModal label="Delete" onClick={deleteDataHandler} />
        </div>
      </CRUDModalSection>
    );
  };

  const Table = () => {
    return (
      <div className="px-3 pt-3">
        <CRUDLayout.Table>
          <THead>
            <Tr className="text-center">
              <ThFixed>No</ThFixed>
              <Th><div style={{ maxWidth: 70 }}>CODE</div></Th>
              <Th><div style={{ maxWidth: 130 }}>NAME</div></Th>
              <Th><div style={{ maxWidth: 100 }}>TYPE</div></Th>
              <Th>RATE</Th>
              <Th>COA COLLECT</Th>
              <Th>COA PAID</Th>
              <Th>NOTED</Th>
              <ThFixed>ACTION</ThFixed>
            </Tr>
          </THead>
          <TBody>
            { listTaxCode.length < 0 
              ? <Tr><Td colSpan={6}><div className="text-center">Tidak Ada Data</div></Td></Tr>
              : listTaxCode?.map((val, index) => {
                  return (
                    <Tr key={val.id}>
                      <TdFixed>{index + 1}</TdFixed>
                      <Td>{val?.tax_code ?? "-"}</Td>
                      <Td>{val?.tax_name ?? "-"}</Td>
                      <Td>{val?.type_tax_name ?? "-"}</Td>
                      <Td><div className="text-center">{`${val?.rate}%` ?? "-"}</div></Td>
                      <Td>{`${val?.coa_collect_grp_code}-${val?.coa_collect_coa_code} ${val?.coa_collect_coa_name}` ?? "-"}</Td>
                      <Td>{`${val?.coa_paid_grp_code}-${val?.coa_paid_coa_code} ${val?.coa_paid_coa_name}` ?? "-"}</Td>
                      <Td>{val?.note ?? "-"}</Td>
                      <TdFixed >
                        <div className="d-flex">
                          <ReadButton icon onClick={() => setModalConfig({ ...modalConfig, show: true, action: "READ", data: val  })}/>
                          <UpdateButton icon onClick={() => setModalConfig({ ...modalConfig, show: true, action: "UPDATE", data: val  })} />
                          <DeleteButton icon onClick={() => setModalConfig({ ...modalConfig, show: true, action: "DELETE", data: val  })} />
                        </div>
                      </TdFixed>
                    </Tr>
                  );
            })}
          </TBody>
        </CRUDLayout.Table>
      </div>
    );
  };

  return (
    <CRUDLayout>
      {isLoading 
        ? <DataStatus loading={isLoading} text="Memuat Data" />
        : !Array.isArray(listTaxCode)
        ? <DataStatus text="Tidak dapat memuat data" />
        : <TableLayout noMarginTop label={menuTitle} withCreateButton onClick={() => setModalConfig({ ...modalConfig, show: true, action: "CREATE"  })}>
            <Table />
          </TableLayout>
      }

      <CreateModal />
      <UpdateModal />
      <DeleteModal />
      <DetailModal />
    </CRUDLayout>
  );
};

import * as Yup from "yup";

export const formValidationEducation = Yup.object().shape({
    sd_id: Yup.string().required("SD is required !"),
    smp_id: Yup.string().required("SMP is required !"),
    sma_id: Yup.string().required("SMA is required !"),
    academy_id: Yup.string().required("Academy is required !"),
    university_id: Yup.string().required("University is required !"),
    magister_id: Yup.string().required("Magister is required !"),
    doctoral_id: Yup.string().required("Doctoral is required !"),
});

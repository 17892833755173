import React, { useState, useEffect } from "react";
import { useFormikContext } from "formik";
import { SaveButton, SelectSearch } from "components";
import CreateNewEmployeeApi from "../__CreateNewEmployeeApi__";
import Axios from "axios";

export const FormEducation = ({ action, isDisabled }) => {
  const { values, errors, touched, setValues, isSubmitting } = useFormikContext() ?? {}

  const [dropdownSD, setDropdownSD] = useState([]);
  const [dropdownSMP, setDropdownSMP] = useState([]);
  const [dropdownSMA, setDropdownSMA] = useState([]);
  const [dropdownAcademy, setDropdownAcademy] = useState([]);
  const [dropdownUniversity, setDropdownUniversity] = useState([]);
  const [dropdownMagister, setDropdownMagister] = useState([]);
  const [dropdownDoctoral, setDropdownDoctoral] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getData = () => {
    setIsLoading(true);

    Axios.all([ 
        CreateNewEmployeeApi.dropdownSD(),
        CreateNewEmployeeApi.dropdownSMP(),
        CreateNewEmployeeApi.dropdownSMA(),
        CreateNewEmployeeApi.dropdownAcademy(),
        CreateNewEmployeeApi.dropdownUniversity(),
        CreateNewEmployeeApi.dropdownMagister(),
        CreateNewEmployeeApi.dropdownDoctoral(),
    ])
      .then(Axios.spread((sd, smp, sma, academy, university, magister, doctoral) => {
        const getSD = sd?.data?.payload ? sd?.data?.payload?.map(val => ({ label: val?.sd_name, value: val?.sd_id })) : []
        const getSMP = smp?.data?.payload ? smp?.data?.payload?.map(val => ({ label: val?.smp_name, value: val?.smp_id })) : []
        const getSMA = sma?.data?.payload ? sma?.data?.payload?.map(val => ({ label: val?.sma_name, value: val?.sma_id })) : []
        const getAcademy = academy?.data?.payload ? academy?.data?.payload?.map(val => ({ label: val?.academy_name, value: val?.academy_id })) : []
        const getUniversity = university?.data?.payload ? university?.data?.payload?.map(val => ({ label: val?.university_name, value: val?.university_id })) : []
        const getMagister = magister?.data?.payload ? magister?.data?.payload?.map(val => ({ label: val?.magister_name, value: val?.magister_id })) : []
        const getDoctoral = doctoral?.data?.payload ? doctoral?.data?.payload?.map(val => ({ label: val?.doctoral_name, value: val?.doctoral_id })) : []

        setDropdownSD(getSD)
        setDropdownSMP(getSMP)
        setDropdownSMA(getSMA)
        setDropdownAcademy(getAcademy)
        setDropdownUniversity(getUniversity)
        setDropdownMagister(getMagister)
        setDropdownDoctoral(getDoctoral)
      }))
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };
  useEffect(() => {
    getData();

    return () => setIsLoading(false)
  }, []);

  return (
    <>
        <SelectSearch
            label="SD"
            placeholder={action !== "READ" && "Select sd"}
            option={dropdownSD}
            // defaultValue={values?.sd_id ? dropdownSD?.find(item => item?.value === values?.sd_id) : []}
            value={values?.sd_id ? dropdownSD?.find(item => item?.value === values?.sd_id) : []}
            onChange={(val) => setValues({ ...values, sd_id: val?.value })}
            error={Boolean(errors?.sd_id && touched?.sd_id)}
            loading={isLoading}
            isDisabled={action === "READ"}
        />
        <SelectSearch
            label="SMP"
            placeholder={action !== "READ" && "Select smp"}
            option={dropdownSMP}
            // defaultValue={values?.smp_id ? dropdownSMP?.find(item => item?.value === values?.smp_id) : []}
            value={values?.smp_id ? dropdownSMP?.find(item => item?.value === values?.smp_id) : []}
            onChange={(val) => setValues({ ...values, smp_id: val?.value })}
            error={Boolean(errors?.smp_id && touched?.smp_id)}
            loading={isLoading}
            isDisabled={action === "READ"}
        />
        <SelectSearch
            label="SMU/SMK"
            placeholder={action !== "READ" && "Select smu/smk"}
            option={dropdownSMA}
            // defaultValue={values?.sma_id ? dropdownSMA?.find(item => item?.value === values?.sma_id) : []}
            value={values?.sma_id ? dropdownSMA?.find(item => item?.value === values?.sma_id) : []}
            onChange={(val) => setValues({ ...values, sma_id: val?.value })}
            error={Boolean(errors?.sma_id && touched?.sma_id)}
            loading={isLoading}
            isDisabled={action === "READ"}
        />
        <SelectSearch
            label="Academy"
            placeholder={action !== "READ" && "Select academy"}
            option={dropdownAcademy}
            // defaultValue={values?.academy_id ? dropdownAcademy?.find(item => item?.value === values?.academy_id) : []}
            value={values?.academy_id ? dropdownAcademy?.find(item => item?.value === values?.academy_id) : []}
            onChange={(val) => setValues({ ...values, academy_id: val?.value })}
            error={Boolean(errors?.academy_id && touched?.academy_id)}
            loading={isLoading}
            isDisabled={action === "READ"}
        />
        <SelectSearch
            label="University"
            placeholder={action !== "READ" && "Select university"}
            option={dropdownUniversity}
            // defaultValue={values?.university_id ? dropdownUniversity?.find(item => item?.value === values?.university_id) : []}
            value={values?.university_id ? dropdownUniversity?.find(item => item?.value === values?.university_id) : []}
            onChange={(val) => setValues({ ...values, university_id: val?.value })}
            error={Boolean(errors?.university_id && touched?.university_id)}
            loading={isLoading}
            isDisabled={action === "READ"}
        />
        <SelectSearch
            label="Magister"
            placeholder={action !== "READ" && "Select magister"}
            option={dropdownMagister}
            // defaultValue={values?.magister_id ? dropdownMagister?.find(item => item?.value === values?.magister_id) : []}
            value={values?.magister_id ? dropdownMagister?.find(item => item?.value === values?.magister_id) : []}
            onChange={(val) => setValues({ ...values, magister_id: val?.value })}
            error={Boolean(errors?.magister_id && touched?.magister_id)}
            loading={isLoading}
            isDisabled={action === "READ"}
        />
        <SelectSearch
            label="Doctoral"
            placeholder={action !== "READ" && "Select doctoral"}
            option={dropdownDoctoral}
            // defaultValue={values?.doctoral_id ? dropdownDoctoral?.find(item => item?.value === values?.doctoral_id) : []}
            value={values?.doctoral_id ? dropdownDoctoral?.find(item => item?.value === values?.doctoral_id) : []}
            onChange={(val) => setValues({ ...values, doctoral_id: val?.value })}
            error={Boolean(errors?.doctoral_id && touched?.doctoral_id)}
            loading={isLoading}
            isDisabled={action === "READ"}
        />
        {action !== "READ" && <div className="d-flex justify-content-end mt-3">
            <SaveButton icon forModal type="submit" disabled={isDisabled} />
        </div>}
    </>
  );
};
import React from 'react'
import { Button, OverlayTrigger, Tooltip, Spinner } from 'react-bootstrap'
import { AiOutlinePlusCircle } from 'react-icons/ai'

export const SaveButton = ({onClick, label, icon, tooltipText, tooltipPlacement, forModal, type, loading, disabled}) => {
  return (
    <OverlayTrigger
      placement={tooltipPlacement}
      overlay={<Tooltip>{tooltipText ? tooltipText : 'Save data'}</Tooltip>}
    >
      <Button 
        size={ !forModal && "sm" }
        className='d-flex align-items-center ml-1'
        type={type}
        style={{ backgroundColor: forModal ? "black" : "transparent", borderRight: "100%", borderColor: "black" }}
        onClick={onClick}
        disabled={disabled}
      >
        {loading
          ? <div className="d-flex justify-content-center align-items-center">
              <div className="d-flex align-items-center">
                <Spinner animation="border" variant="white" size="sm" />
                <div className="ml-2">Processing...</div>
              </div>
            </div>
          : <>
              {icon && <AiOutlinePlusCircle className={`${!forModal && "text-black"} ${label ? "mr-1" : ""}`} style={{ fontSize: '.9rem' }} />}
              <div className={`text-uppercase ${icon && "ml-2"}`}>{label ? label : 'Save'}</div>
            </>
        }
      </Button>
    </OverlayTrigger>
  )
}

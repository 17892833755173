import React, { useState, useEffect } from "react";
import { useFormikContext } from "formik";
import { Input, SaveButton, SelectSearch, InputLink } from "components";
import Axios from "axios";
import { Row, Col } from "react-bootstrap";
import CreateNewVendorApi from "../__CreateNewVendorApi__";

export const FormProfile = ({ action, isDisabled }) => {
  const { values, errors, touched, setValues } = useFormikContext() ?? {}

  const [dropdownCardLabel, setDropdownCardLabel] = useState([]);
  const dropdownGroup = [
    { value: 1, label: "Employee" },
    { value: 2, label: "Customer" },
    { value: 3, label: "Vendor" },
  ]
  const [dropdownCategory, setDropdownCategory] = useState([]);
  const [dropdownMarketing, setDropdownMarketing] = useState([]);
  const [dropdownBankName, setDropdownBankName] = useState([]);
  const [dropdownPriceLevel, setDropdownPriceLevel] = useState([]);
  const [dropdownPaymentTerm, setDropdownPaymentTerm] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getData = () => {
    setIsLoading(true);

    Axios.all([ 
        CreateNewVendorApi.dropdownCardLabel(),
        CreateNewVendorApi.dropdownCategory(),
        CreateNewVendorApi.dropdownMarketing(),
        CreateNewVendorApi.dropdownBankName(),
        CreateNewVendorApi.dropdownPriceLevel(),
        CreateNewVendorApi.dropdownPaymentTerm(),
    ])
      .then(Axios.spread((card, category, marketing, bank, price, payment) => {
        const getCardLabel = card?.data?.payload ? card?.data?.payload?.map(val => ({ label: val?.card_label_name, value: val?.card_label_id })) : []
        const getCategory = category?.data?.payload ? category?.data?.payload?.map(val => ({ label: val?.category_vendor_name, value: val?.category_vendor_id })) : []
        const getMarketing = marketing?.data?.payload ? marketing?.data?.payload?.map(val => ({ label: val?.employee_name, value: val?.employee_id })) : []
        const getBankName = bank?.data?.payload ? bank?.data?.payload?.map(val => ({ label: val?.bank_name, value: val?.bank_id })) : []
        const getPriceLevel = price?.data?.payload ? price?.data?.payload?.map(val => ({ label: val?.price_level_name, value: val?.price_level_id })) : []
        const getPaymentTerm = payment?.data?.payload ? payment?.data?.payload?.map(val => ({ label: val?.payment_term_name, value: val?.payment_term_id })) : []

        setDropdownCardLabel(getCardLabel)
        setDropdownCategory(getCategory)
        setDropdownMarketing(getMarketing)
        setDropdownBankName(getBankName)
        setDropdownPriceLevel(getPriceLevel)
        setDropdownPaymentTerm(getPaymentTerm)
      }))
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };
  useEffect(() => {
    getData();

    return () => setIsLoading(false)
  }, []);

  return (
    <>
      <Row>
        <Col>
          <Input
            label="Handphone"
            type="text"
            placeholder={action !== "READ" && "Input handphone"}
            value={values?.handphone}
            onChange={(e) => setValues({ ...values, handphone: e?.target?.value })}
            error={Boolean(errors?.handphone && touched?.handphone)}
            errorText={Boolean(errors?.handphone && touched?.handphone) && errors?.handphone}
            readOnly={action === "READ"}
          />
          <Input
            label="Email"
            type="text"
            placeholder={action !== "READ" && "Input email"}
            value={values?.email}
            onChange={(e) => setValues({ ...values, email: e?.target?.value })}
            error={Boolean(errors?.email && touched?.email)}
            errorText={Boolean(errors?.email && touched?.email) && errors?.email}
            readOnly={action === "READ"}
          />
          <Input
            label="Website"
            type="text"
            placeholder={action !== "READ" && "Input website"}
            value={values?.website}
            onChange={(e) => setValues({ ...values, website: e?.target?.value })}
            error={Boolean(errors?.website && touched?.website)}
            errorText={Boolean(errors?.website && touched?.website) && errors?.website}
            readOnly={action === "READ"}
          />
          <SelectSearch
            label="Card Label"
            placeholder={action !== "READ" && "Select card label"}
            option={dropdownCardLabel}
            defaultValue={values?.card_label ? dropdownCardLabel?.find(item => item?.value === values?.card_label) : []}
            onChange={(val) => setValues({ ...values, card_label: val?.value })}
            error={Boolean(errors?.card_label && touched?.card_label)}
            loading={isLoading}
            isDisabled={action === "READ"}
          />
          <SelectSearch
            label="Group"
            placeholder={action !== "READ" && "Select group"}
            option={dropdownGroup}
            defaultValue={values?.group_card ? dropdownGroup?.find(item => item?.value === values?.group_card) : []}
            onChange={(val) => setValues({ ...values, group_card: val?.value })}
            error={Boolean(errors?.group_card && touched?.group_card)}
            loading={isLoading}
            isDisabled={action === "READ"}
          />
          <SelectSearch
            label="Category"
            placeholder={action !== "READ" && "Select category"}
            option={dropdownCategory}
            defaultValue={values?.category_vendor_id ? dropdownCategory?.find(item => item?.value === values?.category_vendor_id) : []}
            onChange={(val) => setValues({ ...values, category_vendor_id: val?.value })}
            error={Boolean(errors?.category_vendor_id && touched?.category_vendor_id)}
            loading={isLoading}
            isDisabled={action === "READ"}
          />
          <Input
            label="Source From"
            type="text"
            placeholder={action !== "READ" && "Input source from"}
            value={values?.source_from}
            onChange={(e) => setValues({ ...values, source_from: e?.target?.value })}
            error={Boolean(errors?.source_from && touched?.source_from)}
            errorText={Boolean(errors?.source_from && touched?.source_from) && errors?.source_from}
            readOnly={action === "READ"}
          />
          <SelectSearch
            label="Marketing"
            placeholder={action !== "READ" && "Select marketing"}
            option={dropdownMarketing}
            defaultValue={values?.employee_id ? dropdownMarketing?.find(item => item?.value === values?.employee_id) : []}
            onChange={(val) => setValues({ ...values, employee_id: val?.value })}
            error={Boolean(errors?.employee_id && touched?.employee_id)}
            loading={isLoading}
            isDisabled={action === "READ"}
          />
          <Input
            label="Media"
            type="text"
            placeholder={action !== "READ" && "Input media"}
            value={values?.media}
            onChange={(e) => setValues({ ...values, media: e?.target?.value })}
            error={Boolean(errors?.media && touched?.media)}
            errorText={Boolean(errors?.media && touched?.media) && errors?.media}
            readOnly={action === "READ"}
          />
        </Col>
        <Col>
          <SelectSearch
            label="Price Level"
            placeholder={action !== "READ" && "Select price level"}
            option={dropdownPriceLevel}
            defaultValue={values?.price_level_id ? dropdownPriceLevel?.find(item => item?.value === values?.price_level_id) : []}
            onChange={(val) => setValues({ ...values, price_level_id: val?.value })}
            error={Boolean(errors?.price_level_id && touched?.price_level_id)}
            loading={isLoading}
            isDisabled={action === "READ"}
          />
          <SelectSearch
            label="Payment Term"
            placeholder={action !== "READ" && "Select payment term"}
            option={dropdownPaymentTerm}
            defaultValue={values?.payment_term_id ? dropdownPaymentTerm?.find(item => item?.value === values?.payment_term_id) : []}
            onChange={(val) => setValues({ ...values, payment_term_id: val?.value })}
            error={Boolean(errors?.payment_term_id && touched?.payment_term_id)}
            loading={isLoading}
            isDisabled={action === "READ"}
          />
          <Input
            label="Max Balance"
            type="text"
            placeholder={action !== "READ" && "Input max balance"}
            value={values?.max_balance}
            onChange={(e) => setValues({ ...values, max_balance: e?.target?.value })}
            error={Boolean(errors?.max_balance && touched?.max_balance)}
            errorText={Boolean(errors?.max_balance && touched?.max_balance) && errors?.max_balance}
            readOnly={action === "READ"}
          />
          <SelectSearch
            label="Bank Name"
            placeholder={action !== "READ" && "Select bank name"}
            option={dropdownBankName}
            defaultValue={values?.bank_id ? dropdownBankName?.find(item => item?.value === values?.bank_id) : []}
            onChange={(val) => setValues({ ...values, bank_id: val?.value })}
            error={Boolean(errors?.bank_id && touched?.bank_id)}
            loading={isLoading}
            isDisabled={action === "READ"}
          />
          <Input
            label="Bank Account"
            type="text"
            placeholder={action !== "READ" && "Input bank account"}
            value={values?.bank_account}
            onChange={(e) => setValues({ ...values, bank_account: e?.target?.value })}
            error={Boolean(errors?.bank_account && touched?.bank_account)}
            errorText={Boolean(errors?.bank_account && touched?.bank_account) && errors?.bank_account}
            readOnly={action === "READ"}
          />
          <Input
            label="Bank Beneficiary"
            type="text"
            placeholder={action !== "READ" && "Input bank beneficiary"}
            value={values?.bank_beneficiary}
            onChange={(e) => setValues({ ...values, bank_beneficiary: e?.target?.value })}
            error={Boolean(errors?.bank_beneficiary && touched?.bank_beneficiary)}
            errorText={Boolean(errors?.bank_beneficiary && touched?.bank_beneficiary) && errors?.bank_beneficiary}
            readOnly={action === "READ"}
          />
          <Input
            label="Bank Branch"
            type="text"
            placeholder={action !== "READ" && "Input bank branch"}
            value={values?.bank_branch}
            onChange={(e) => setValues({ ...values, bank_branch: e?.target?.value })}
            error={Boolean(errors?.bank_branch && touched?.bank_branch)}
            errorText={Boolean(errors?.bank_branch && touched?.bank_branch) && errors?.bank_branch}
            readOnly={action === "READ"}
          />
        </Col>
      </Row>
      {action !== "READ" && <div className="d-flex justify-content-end mt-3">
        <SaveButton icon forModal type="submit" disabled={isDisabled} />
      </div>}
    </>
  );
};

import React, { useState, useEffect } from "react";
import { useFormikContext } from "formik";
import { Input, SaveButton, SelectSearch } from "components";
import CreateNewEmployeeApi from "../__CreateNewEmployeeApi__";

export const FormBank = ({ action, isDisabled }) => {
  const { values, errors, touched, setValues, isSubmitting } = useFormikContext() ?? {}

  const [dropdownBank, setDropdownBank] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getData = () => {
    setIsLoading(true);

    CreateNewEmployeeApi.dropdownBank()
      .then(bank => {
        const getBank = bank?.data?.payload ? bank?.data?.payload?.map(val => ({ label: val?.bank_name, value: val?.bank_id })) : []

        setDropdownBank(getBank)
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };
  useEffect(() => {
    getData();

    return () => setIsLoading(false)
  }, []);

  if(action === "READ") {
    return (
      <>
        <SelectSearch
          label="Bank"
          placeholder={action !== "READ" && "Select bank"}
          option={dropdownBank}
          value={values?.bank_id ? dropdownBank?.find(item => item?.value === values?.bank_id) : []}
          onChange={(val) => setValues({ ...values, bank_id: val?.value })}
          error={Boolean(errors?.bank_id && touched?.bank_id)}
          loading={isLoading}
          isDisabled={action === "READ"}
        />
        <Input
          label="Branch"
          type="text"
          placeholder={action !== "READ" && "Input branch"}
          value={values?.branch}
          onChange={(e) => setValues({ ...values, branch: e?.target?.value })}
          error={Boolean(errors?.branch && touched?.branch)}
          errorText={Boolean(errors?.branch && touched?.branch) && errors?.branch}
          readOnly={action === "READ"}
        />
        <Input
          label="Account Bank Number"
          type="text"
          placeholder={action !== "READ" && "Input account bank number"}
          value={values?.account_number}
          onChange={(e) => setValues({ ...values, account_number: e?.target?.value })}
          error={Boolean(errors?.account_number && touched?.account_number)}
          errorText={Boolean(errors?.account_number && touched?.account_number) && errors?.account_number}
          readOnly={action === "READ"}
        />
        <Input
          label="Account Bank Name"
          type="text"
          placeholder={action !== "READ" && "Input account bank name"}
          value={values?.account_name}
          onChange={(e) => setValues({ ...values, account_name: e?.target?.value })}
          error={Boolean(errors?.account_name && touched?.account_name)}
          errorText={Boolean(errors?.account_name && touched?.account_name) && errors?.account_name}
          readOnly={action === "READ"}
        />
        {action !== "READ" && <div className="d-flex justify-content-end mt-3">
          <SaveButton icon forModal type="submit" loading={isSubmitting} />
        </div>}
      </>
    )
  }

  return (
    <>
      <SelectSearch
        label="Bank"
        placeholder={action !== "READ" && "Select bank"}
        option={dropdownBank}
        // defaultValue={values?.bank_id ? dropdownBank?.find(item => item?.value === values?.bank_id) : []}
        value={values?.bank_id ? dropdownBank?.find(item => item?.value === values?.bank_id) : []}
        onChange={(val) => setValues({ ...values, bank_id: val?.value })}
        error={Boolean(errors?.bank_id && touched?.bank_id)}
        loading={isLoading}
        isDisabled={action === "READ"}
      />
      <Input
        label="Branch"
        type="text"
        placeholder={action !== "READ" && "Input branch"}
        value={values?.branch}
        onChange={(e) => setValues({ ...values, branch: e?.target?.value })}
        error={Boolean(errors?.branch && touched?.branch)}
        errorText={Boolean(errors?.branch && touched?.branch) && errors?.branch}
        readOnly={action === "READ"}
      />
      <Input
        label="Account Bank Number"
        type="text"
        placeholder={action !== "READ" && "Input account bank number"}
        value={values?.account_number}
        onChange={(e) => setValues({ ...values, account_number: e?.target?.value })}
        error={Boolean(errors?.account_number && touched?.account_number)}
        errorText={Boolean(errors?.account_number && touched?.account_number) && errors?.account_number}
        readOnly={action === "READ"}
      />
      <Input
        label="Account Bank Name"
        type="text"
        placeholder={action !== "READ" && "Input account bank name"}
        value={values?.account_name}
        onChange={(e) => setValues({ ...values, account_name: e?.target?.value })}
        error={Boolean(errors?.account_name && touched?.account_name)}
        errorText={Boolean(errors?.account_name && touched?.account_name) && errors?.account_name}
        readOnly={action === "READ"}
      />
      {action !== "READ" && <div className="d-flex justify-content-end mt-3">
        <SaveButton icon forModal type="submit" disabled={isDisabled} />
      </div>}
    </>
  );
};

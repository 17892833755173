import Logo from "../assets/image/LogoHAWK.jpg";
import { IoBasketOutline, IoCashOutline, IoJournalOutline } from "react-icons/io5";
import { BsCardList } from "react-icons/bs";
import { AiOutlineContacts, AiOutlineTransaction } from "react-icons/ai";
import { FaRegMoneyBillAlt } from "react-icons/fa";
import { BiNotepad, BiFileFind } from "react-icons/bi";
import Dashboard from "pages/Dashboard";
import { 
  // SETUP

  //PREFENCES
  LocationList,
  Project,
  TaxCodeList,
  Bank,
  CategoryCustomer,
  CategoryVendor,
  Departement,
  Divisi,
  PaymentTermCustomer,
  PaymentTermVendor,
  PriceLevelCustomer,
  PriceLevelVendor,

  //ACCOUNTS
  COAGroupList, 
  COATypeList,

  //ANALYTICS
  //ACCOUNTS
  ShowAllList,
  CoaRegistration,
  // CARDS
  CreateNewEmployeeList,
  CreateNewEmployeeCreate,
  CreateNewEmployeeUpdate,
  CreateNewEmployeeDetail,

  CreateNewCustomerList,
  CreateNewCustomerCreate,
  CreateNewCustomerUpdate,
  CreateNewCustomerDetail,

  CreateNewVendorList,
  CreateNewVendorCreate,
  CreateNewVendorUpdate,
  CreateNewVendorDetail,

  // FINANCE
  SpendMoneyList,
  SpendMoneyCreate,
  MainCardList,

  //INVENTORY
  ProductGroup,
  ProductCategory,
  ProductUnit,
  ProductMaterial,
  ProductColour,
  ProductSize,
  ProductStyle,
  ProductStatus,
  ProductPricingLevel
} from "pages/Module";

export default {
  LOGO: Logo,
  MODUL: "Inventori",
  MENU_SETUP: [
    // FINANCE
    {
      text: "FINANCE",
      link: "/",
      type: "menu",
      exact: true,
      icon: <FaRegMoneyBillAlt className="text-white" />,
      hak: ["SUPA", "INV"],
    },
    // SALES
    {
      text: "SALES",
      type: "dropdown",
      icon: <IoBasketOutline className="text-white" />,
      hak: ["SUPA", "INV"],
      menu: [
        {
          text: "CHART OF ACCOUNT GROUP",
          link: "/setup/chart-of-account-group",
          hak: ["SUPA", "INV_MAS_BUA"],
        },
        {
          text: "CHART OF ACCOUNT TYPE",
          link: "/setup/chart-of-account-type",
          hak: ["SUPA", "INV_MAS_BUA"],
        },
        {
          text: "LINK ACCOUNT SALES",
          link: "/setup/link-account-sales",
          hak: ["SUPA", "INV_MAS_BUA"],
        },
        {
          text: "LINK ACCOUNT BILLS",
          link: "/setup/link-account-bills",
          hak: ["SUPA", "INV_MAS_BUA"],
        },
        {
          text: "LINK ACCOUNT GENERAL",
          link: "/setup/link-account-general",
          hak: ["SUPA", "INV_MAS_BUA"],
        },
      ],
    },
    // PURCHASE
    {
      text: "PURCHASE",
      type: "dropdown",
      icon: <IoCashOutline />,
      hak: ["SUPA", "INV"],
      menu: [
        {
          text: "Purchase Request",
          link: "/inventory/transaksi/purchase-request",
          hak: ["SUPA", "INV_TRN_PR"],
        }
      ],
    },
    // INVENTORY
    {
      text: "INVENTORY",
      type: "dropdown",
      icon: <BsCardList />,
      hak: ["SUPA", "INV"],
      menu: [
        {
          text: "Kartu Stock Barang",
          link: "/inventory/laporan/kartu-stock-material",
          hak: ["SUPA", "INV_LAP_KST"],
        }
      ],
    },
    // CONTACT
    {
      text: "CONTACT",
      type: "dropdown",
      icon: <AiOutlineContacts className="text-white" />,
      hak: ["SUPA", "INV"],
      menu: [
        {
          text: "Kartu Stock Barang",
          link: "/inventory/laporan/kartu-stock-material",
          hak: ["SUPA", "INV_LAP_KST"],
        }
      ],
    },
  ],
  MENU_ACCOUNTS: [
    {
      text: "REGISTER",
      link: "/accounts/register",
      type: "menu",
      exact: true,
      icon: <BiNotepad className="text-white" />,
      hak: ["SUPA", "INV"],
      // menu: [
      //   {
      //     text: "SHOW ALL",
      //     link: "/accounts/show-all",
      //     hak: ["SUPA", "INV_MAS_BUA"],
      //   },
      //   {
      //     text: "ASSETS",
      //     link: "/accounts/assets",
      //     hak: ["SUPA", "INV_MAS_BUA"],
      //   },
      //   {
      //     text: "LIABILLITY",
      //     link: "/accounts/liabillity",
      //     hak: ["SUPA", "INV_MAS_BUA"],
      //   },
      //   {
      //     text: "EQUITY",
      //     link: "/accounts/equity",
      //     hak: ["SUPA", "INV_MAS_BUA"],
      //   },
      //   {
      //     text: "INCOME",
      //     link: "/accounts/income",
      //     hak: ["SUPA", "INV_MAS_BUA"],
      //   },
      //   {
      //     text: "COST OF GOODS",
      //     link: "/accounts/cost-of-goods",
      //     hak: ["SUPA", "INV_MAS_BUA"],
      //   },
      //   {
      //     text: "EXPENCES",
      //     link: "/accounts/expences",
      //     hak: ["SUPA", "INV_MAS_BUA"],
      //   },
      //   {
      //     text: "OTHER INCOME",
      //     link: "/accounts/other-income",
      //     hak: ["SUPA", "INV_MAS_BUA"],
      //   },
      //   {
      //     text: "OTHER EXPENCES",
      //     link: "/accounts/other-expences",
      //     hak: ["SUPA", "INV_MAS_BUA"],
      //   },
      // ],
    },
    {
      text: "TRANSACTIONS",
      link: "/accounts/transactions",
      type: "menu",
      exact: true,
      icon: <AiOutlineTransaction className="text-white" />,
      hak: ["SUPA", "INV"],
      menu: [
        {
          text: "SHOW ALL",
          link: "/accounts/transactions/show-all",
          hak: ["SUPA", "INV_MAS_BUA"],
        },
      ]
    },
    {
      text: "JOURNAL",
      link: "/accounts/journal",
      type: "menu",
      exact: true,
      icon: <IoJournalOutline className="text-white" />,
      hak: ["SUPA", "INV"],
    },
    {
      text: "FIND",
      link: "/accounts/find",
      type: "menu",
      exact: true,
      icon: <BiFileFind className="text-white" />,
      hak: ["SUPA", "INV"],
    },
  ],
  MENU_CARDS: [
    {
      text: "REGISTER",
      link: "/cards/register",
      type: "menu",
      exact: true,
      icon: <BiNotepad className="text-white" />,
      hak: ["SUPA", "INV"]
    },
    {
      text: "TRANSACTIONS",
      link: "/cards/transactions",
      type: "menu",
      exact: true,
      icon: <AiOutlineTransaction className="text-white" />,
      hak: ["SUPA", "INV"],
      menu: [
        {
          text: "CREATE NEW CUSTOMER",
          link: "/cards/transactions/create-new-customer",
          hak: ["SUPA", "INV_MAS_BUA"],
        },
        {
          text: "CREATE NEW VENDOR",
          link: "/cards/transactions/create-new-vendor",
          hak: ["SUPA", "INV_MAS_BUA"],
        },
        {
          text: "CREATE NEW EMPLOYEE",
          link: "/cards/transactions/create-new-employee",
          hak: ["SUPA", "INV_MAS_BUA"],
        },
        // {
        //   text: "CREATE NEW OTHERS",
        //   link: "/cards/transactions/create-new-others",
        //   hak: ["SUPA", "INV_MAS_BUA"],
        // },
      ]
    },
    {
      text: "JOURNAL",
      link: "/cards/journal",
      type: "menu",
      exact: true,
      icon: <IoJournalOutline className="text-white" />,
      hak: ["SUPA", "INV"],
    },
    {
      text: "FIND",
      link: "/cards/find",
      type: "menu",
      exact: true,
      icon: <BiFileFind className="text-white" />,
      hak: ["SUPA", "INV"],
    },
  ],
  MENU_FINANCE: [
    {
      text: "REGISTER",
      link: "/finance/register",
      type: "menu",
      exact: true,
      icon: <BiNotepad className="text-white" />,
      hak: ["SUPA", "INV"],
      // menu: [
      //   {
      //     text: "SHOW ALL",
      //     link: "/finance/show-all",
      //     hak: ["SUPA", "INV_MAS_BUA"],
      //   },
      // ],
    },
    {
      text: "TRANSACTIONS",
      link: "/finance/transactions",
      type: "menu",
      exact: true,
      icon: <AiOutlineTransaction className="text-white" />,
      hak: ["SUPA", "INV"],
      menu: [
        {
          text: "SPEND MONEY",
          link: "/finance/transactions/spend-money",
          hak: ["SUPA", "INV_MAS_BUA"],
        },
      ]
    },
    {
      text: "JOURNAL",
      link: "/finance/journal",
      type: "menu",
      exact: true,
      icon: <IoJournalOutline className="text-white" />,
      hak: ["SUPA", "INV"],
    },
    {
      text: "FIND",
      link: "/finance/find",
      type: "menu",
      exact: true,
      icon: <BiFileFind className="text-white" />,
      hak: ["SUPA", "INV"],
    },
  ],

  ROUTES: [
    // MODUL SETUP
    {
      exact: true,
      path: "/setup/prefences/location",
      page: LocationList,
      hak: ["SUPA", "INV", "INV_MAS_BSO"],
    },
    {
      exact: true,
      path: "/setup/prefences/bank",
      page: Bank,
      hak: ["SUPA", "INV", "INV_MAS_BSO"],
    },
    {
      exact: true,
      path: "/setup/prefences/category-customer",
      page: CategoryCustomer,
      hak: ["SUPA", "INV", "INV_MAS_BSO"],
    },
    {
      exact: true,
      path: "/setup/prefences/category-vendor",
      page: CategoryVendor,
      hak: ["SUPA", "INV", "INV_MAS_BSO"],
    },
    {
      exact: true,
      path: "/setup/prefences/departement",
      page: Departement,
      hak: ["SUPA", "INV", "INV_MAS_BSO"],
    },
    {
      exact: true,
      path: "/setup/prefences/divisi",
      page: Divisi,
      hak: ["SUPA", "INV", "INV_MAS_BSO"],
    },
    {
      exact: true,
      path: "/setup/prefences/payment-term-customer",
      page: PaymentTermCustomer,
      hak: ["SUPA", "INV", "INV_MAS_BSO"],
    },
    {
      exact: true,
      path: "/setup/prefences/price-level-customer",
      page: PriceLevelCustomer,
      hak: ["SUPA", "INV", "INV_MAS_BSO"],
    },
    {
      exact: true,
      path: "/setup/prefences/price-level-vendor",
      page: PriceLevelVendor,
      hak: ["SUPA", "INV", "INV_MAS_BSO"],
    },
    {
      exact: true,
      path: "/setup/prefences/payment-term-vendor",
      page: PaymentTermVendor,
      hak: ["SUPA", "INV", "INV_MAS_BSO"],
    },
    {
      exact: true,
      path: "/setup/prefences/tax-code",
      page: TaxCodeList,
      hak: ["SUPA", "INV", "INV_MAS_BSO"],
    },
    {
      exact: true,
      path: "/setup/prefences/project-jobs",
      page: Project,
      hak: ["SUPA", "INV", "INV_MAS_BSO"],
    },
    {
      exact: true,
      path: "/setup/accounts/chart-of-account-group",
      page: COAGroupList,
      hak: ["SUPA", "INV", "INV_MAS_BSO"],
    },
    {
      exact: true,
      path: "/setup/accounts/chart-of-account-type",
      page: COATypeList,
      hak: ["SUPA", "INV", "INV_MAS_BSO"],
    },
    {
      exact: true,
      path: "/setup/inventori/product-group",
      page: ProductGroup,
      hak: ["SUPA", "INV", "INV_MAS_BSO"],
    },
    {
      exact: true,
      path: "/setup/inventori/product-category",
      page: ProductCategory,
      hak: ["SUPA", "INV", "INV_MAS_BSO"],
    },
    {
      exact: true,
      path: "/setup/inventori/product-unit",
      page: ProductUnit,
      hak: ["SUPA", "INV", "INV_MAS_BSO"],
    },
    {
      exact: true,
      path: "/setup/inventori/product-material",
      page: ProductMaterial,
      hak: ["SUPA", "INV", "INV_MAS_BSO"],
    },
    {
      exact: true,
      path: "/setup/inventori/product-colour",
      page: ProductColour,
      hak: ["SUPA", "INV", "INV_MAS_BSO"],
    },
    {
      exact: true,
      path: "/setup/inventori/product-size",
      page: ProductSize,
      hak: ["SUPA", "INV", "INV_MAS_BSO"],
    },
    {
      exact: true,
      path: "/setup/inventori/product-style",
      page: ProductStyle,
      hak: ["SUPA", "INV", "INV_MAS_BSO"],
    },
    {
      exact: true,
      path: "/setup/inventori/product-status",
      page: ProductStatus,
      hak: ["SUPA", "INV", "INV_MAS_BSO"],
    },
    {
      exact: true,
      path: "/setup/inventori/product-pricing-level",
      page: ProductPricingLevel,
      hak: ["SUPA", "INV", "INV_MAS_BSO"],
    },
    // MODUL ACCOUNTS
    {
      exact: true,
      path: "/accounts/register/show-all",
      // page: ShowAllList,
      page: CoaRegistration,
      hak: ["SUPA", "INV", "INV_MAS_BSO"],
    },
    // MODUL CARDS
    {
      exact: true,
      path: "/cards",
      page: MainCardList,
      hak: ["SUPA", "INV", "INV_MAS_BSO"],
    },
    {
      exact: true,
      path: "/cards/transactions/create-new-employee",
      page: CreateNewEmployeeList,
      hak: ["SUPA", "INV", "INV_MAS_BSO"],
    },
    {
      exact: true,
      path: "/cards/transactions/create-new-employee/create",
      page: CreateNewEmployeeCreate,
      hak: ["SUPA", "INV", "INV_MAS_BSO"],
    },
    {
      exact: true,
      path: "/cards/transactions/create-new-employee/update",
      page: CreateNewEmployeeUpdate,
      hak: ["SUPA", "INV", "INV_MAS_BSO"],
    },
    {
      exact: true,
      path: "/cards/transactions/create-new-employee/detail",
      page: CreateNewEmployeeDetail,
      hak: ["SUPA", "INV", "INV_MAS_BSO"],
    },
    {
      exact: true,
      path: "/cards/transactions/create-new-customer",
      page: CreateNewCustomerList,
      hak: ["SUPA", "INV", "INV_MAS_BSO"],
    },
    {
      exact: true,
      path: "/cards/transactions/create-new-customer/create",
      page: CreateNewCustomerCreate,
      hak: ["SUPA", "INV", "INV_MAS_BSO"],
    },
    {
      exact: true,
      path: "/cards/transactions/create-new-customer/update",
      page: CreateNewCustomerUpdate,
      hak: ["SUPA", "INV", "INV_MAS_BSO"],
    },
    {
      exact: true,
      path: "/cards/transactions/create-new-customer/detail",
      page: CreateNewCustomerDetail,
      hak: ["SUPA", "INV", "INV_MAS_BSO"],
    },
    {
      exact: true,
      path: "/cards/transactions/create-new-vendor",
      page: CreateNewVendorList,
      hak: ["SUPA", "INV", "INV_MAS_BSO"],
    },
    {
      exact: true,
      path: "/cards/transactions/create-new-vendor/create",
      page: CreateNewVendorCreate,
      hak: ["SUPA", "INV", "INV_MAS_BSO"],
    },
    {
      exact: true,
      path: "/cards/transactions/create-new-vendor/update",
      page: CreateNewVendorUpdate,
      hak: ["SUPA", "INV", "INV_MAS_BSO"],
    },
    {
      exact: true,
      path: "/cards/transactions/create-new-vendor/detail",
      page: CreateNewVendorDetail,
      hak: ["SUPA", "INV", "INV_MAS_BSO"],
    },
    // FINANCE
    {
      exact: true,
      path: "/finance/transactions/spend-money",
      page: SpendMoneyList,
      hak: ["SUPA", "INV", "INV_MAS_BSO"],
    },
    {
      exact: true,
      path: "/finance/transactions/spend-money/create",
      page: SpendMoneyCreate,
      hak: ["SUPA", "INV", "INV_MAS_BSO"],
    },

    // OTHER
    {
      exact: true,
      path: "/inventory/transaksi/purchase-request",
      page: Dashboard,
      hak: ["SUPA", "INV", "INV_TRN_PR"],
    },
    {
      exact: true,
      path: "/inventory/transaksi/purchase-request/tambah",
      page: Dashboard,
      hak: ["SUPA", "INV", "INV_TRN_PR"],
    },
    {
      exact: true,
      path: "/inventory/transaksi/purchase-request/:id",
      page: Dashboard,
      hak: ["SUPA", "INV", "INV_TRN_PR"],
    },
    {
      exact: true,
      path: "/inventory/laporan/kartu-stock-material",
      page: Dashboard,
      hak: ["SUPA", "INV", "INV_LAP_KST"],
    }
  ],
};

import React, { useState, useEffect } from "react";
import {
  CRUDLayout,
  DataStatus,
  UpdateButton,
  DeleteButton,
  THead,
  Tr,
  ThFixed,
  Th,
  TBody,
  TdFixed,
  Td,
  ReadButton,
  TableLayout,
  CRUDModalSection,
  Alert,
  CloseButton
} from "components";
import { Formik, Form } from "formik";
import Axios from "axios";
import { Content } from "./__LocationComps__";
import LocationApi from "./__LocationApi__";
import { formInitialValues, formValidationSchema, formSubmitMapper } from "./__LocationUtillities__";

export const LocationList = () => {
  const menuTitle = "LOCATION"
  const [listLocation, setListLocation] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [modalConfig, setModalConfig] = useState({
    show: false,
    action: "",
    data: {}
  });

  const getData = () => {
    setIsLoading(true);

    Axios.all([ LocationApi.getList() ])
      .then(Axios.spread(res => setListLocation(res?.data?.payload)))
      .catch((err) => alert(err))
      .finally(() => setIsLoading(false));
  };
  useEffect(() => {
    getData();

    return () => setIsLoading(false)
  }, []);

  const CreateModal = () => {
    const submitValidationHandler = (errors) =>
    new Promise((resolve, reject) => {
      const getError = Object.values(errors);

      if (getError.length > 0) {
        reject(getError);
      } else {
        resolve();
      }
    });
    const preSubmitHandler = (formik) => {
      const { values, validateForm, setTouched, setErrors, handleSubmit, setSubmitting } = formik;

      setSubmitting(true);
      validateForm().then(async (err) => {
        setErrors(err);
        setTouched(err);

        await submitValidationHandler(err, values)
          .then(() => handleSubmit())
          .catch((err) => window?.alert(err))
          .finally(() => setSubmitting(false));
      });
    };
    const formSubmitHandler = (values) => {
      setModalConfig({ ...modalConfig, show: false, action: "CREATE"  })

      LocationApi.create(values)
        .then(() => setModalConfig({ ...modalConfig, show: true, action: "CREATE_SUCCESS"  }))
        .catch((err) =>  window?.alert(err))
        .finally(() => {
          setModalConfig({ ...modalConfig, show: false, action: "CREATE"  })
          getData();
        });
    };

    return (
      <Formik 
        enableReinitialize 
        initialValues={formInitialValues({})} 
        validationSchema={formValidationSchema} 
        onSubmit={(values) => formSubmitHandler(values)}
      >
        {(formik) => (
          <Form>
            <CRUDModalSection
              type="create" 
              title={menuTitle}
              show={Boolean(modalConfig?.show && modalConfig?.action === "CREATE")}
              onHide={() => setModalConfig({ ...modalConfig, show: false, action: "CREATE"  })}
            >
              <Content 
                type="CREATE" 
                modalConfig={modalConfig} 
                setModalConfig={setModalConfig} 
                onSubmit={() => preSubmitHandler(formik)} 
              />
            </CRUDModalSection>
          </Form>
        )}
      </Formik>
    );
  };
  const UpdateModal = () => {
    const submitValidationHandler = (errors) =>
    new Promise((resolve, reject) => {
      const getError = Object.values(errors);

      if (getError.length > 0) {
        reject(getError);
      } else {
        resolve();
      }
    });
    const preSubmitHandler = (formik) => {
      const { values, validateForm, setTouched, setErrors, handleSubmit, setSubmitting } = formik;

      setSubmitting(true);
      validateForm().then(async (err) => {
        setErrors(err);
        setTouched(err);

        await submitValidationHandler(err, values)
          .then(() => handleSubmit())
          .catch((err) => window?.alert(err))
          .finally(() => setSubmitting(false));
      });
    };
    const formSubmitHandler = (values) => {
      setModalConfig({ ...modalConfig, show: false, action: "UPDATE"  })

      LocationApi.update(values, modalConfig?.data?.location_id)
        .then(() => setModalConfig({ ...modalConfig, show: true, action: "UPDATE_SUCCESS"  }))
        .catch((err) =>  window?.alert(err))
        .finally(() => {
          setModalConfig({ ...modalConfig, show: false, action: "UPDATE"  })
          getData();
        });
    };

    return (
      <Formik 
        enableReinitialize 
        initialValues={formInitialValues(modalConfig?.data)} 
        validationSchema={formValidationSchema} 
        onSubmit={(values) => formSubmitHandler(values)}
      >
        {(formik) => (
          <Form>
            <CRUDModalSection
              type="update" 
              title={menuTitle}
              show={Boolean(modalConfig?.show && modalConfig?.action === "UPDATE")}
              onHide={() => setModalConfig({ ...modalConfig, show: false, action: "UPDATE"  })}
            >
              <Content 
                type="UPDATE" 
                modalConfig={modalConfig} 
                setModalConfig={setModalConfig} 
                onSubmit={() => preSubmitHandler(formik)} 
              />
            </CRUDModalSection>
          </Form>
        )}
      </Formik>
    );
  };
  const DetailModal = () => {
    return (
      <Formik>
        <CRUDModalSection 
          type={modalConfig?.action}
          title={menuTitle}
          show={Boolean(modalConfig?.show && modalConfig?.action === "READ")}
          onHide={() => setModalConfig({ ...modalConfig, show: false, action: "READ" })}
        >
          <Content action="READ" data={modalConfig?.data} />
          <hr className='my-3'/>
          <div className="mt-3 d-flex">
            <CloseButton icon onClick={() => setModalConfig({ ...modalConfig, show: false, action: "DETAIL"  })} />
          </div>
        </CRUDModalSection>
      </Formik>
    )
  }
  const DeleteModal = () => {
    const deleteDataHandler = () => {
      LocationApi.delete(modalConfig?.data?.location_id)
        .then(() => setModalConfig({ ...modalConfig, show: true, action: "DELETE_SUCCESS"  }))
        .catch((err) => setModalConfig({ ...modalConfig, show: true, action: "DELETE_ERROR", data: `Delete Error! (${err?.response?.data?.message})` }))
        .finally(() => {
          setModalConfig({ ...modalConfig, show: false, action: "DELETE"  })
          getData();
        });
    };

    return (
      <CRUDModalSection
        type="delete"
        title={menuTitle} 
        show={Boolean(modalConfig?.show && modalConfig?.action === "DELETE")}
        onHide={() => setModalConfig({ ...modalConfig, show: false, action: "DELETE"  })}
      >
        <Content action="READ" data={modalConfig?.data} />

        <Alert textCenter text="Data akan dihapus secara permanen dan tidak dapat dikembalikan !" variant="danger" show={true} />

        <hr className='my-3'/>
        <div className="mt-3 d-flex">
          <CloseButton icon onClick={() => setModalConfig({ ...modalConfig, show: false, action: "UPDATE"  })} />
          <DeleteButton icon forModal label="Delete" onClick={deleteDataHandler} />
        </div>
      </CRUDModalSection>
    );
  };

  const Table = () => {
    return (
      <div className="px-3 pt-3">
        <CRUDLayout.Table>
          <THead>
            <Tr className="text-center">
              <ThFixed>No</ThFixed>
              <Th><div style={{ width: 150 }}>NAME</div></Th>
              <Th><div style={{ width: 200 }}>COUNTRY</div></Th>
              <Th><div style={{ width: 200 }}>PROVINCE</div></Th>
              <Th><div style={{ width: 200 }}>REGENCY</div></Th>
              <Th><div style={{ width: 200 }}>SUBDISTRICT</div></Th>
              <Th><div style={{ width: 200 }}>VILLAGE</div></Th>
              <Th><div style={{ width: 300 }}>ADDRESS</div></Th>
              <Th><div style={{ width: 300 }}>NOTE</div></Th>
              <ThFixed>ACTION</ThFixed>
            </Tr>
          </THead>
          <TBody>
            {listLocation.map((val, index) => {
              return (
                <Tr key={val.id}>
                  <TdFixed>{index + 1}</TdFixed>
                  <Td>{val?.location_name ?? "-"}</Td>
                  <Td>{val?.country_name ?? "-"}</Td>
                  <Td>{val?.province_name ?? "-"}</Td>
                  <Td>{val?.regency_name ?? "-"}</Td>
                  <Td>{val?.subdistrict_name ?? "-"}</Td>
                  <Td>{val?.village_name ?? "-"}</Td>
                  <Td>{val?.address ?? "-"}</Td>
                  <Td>{val?.description ?? "-"}</Td>
                  <TdFixed >
                    <div className="d-flex">
                      <ReadButton icon onClick={() => setModalConfig({ ...modalConfig, show: true, action: "READ", data: val  })}/>
                      <UpdateButton icon onClick={() => setModalConfig({ ...modalConfig, show: true, action: "UPDATE", data: val  })} />
                      <DeleteButton icon onClick={() => setModalConfig({ ...modalConfig, show: true, action: "DELETE", data: val  })} />
                    </div>
                  </TdFixed>
                </Tr>
              );
            })}
          </TBody>
        </CRUDLayout.Table>
      </div>
    );
  };

  return (
    <CRUDLayout>
      {isLoading 
        ? <DataStatus loading={isLoading} text="Memuat Data" />
        : !Array.isArray(listLocation)
        ? <DataStatus text="Tidak dapat memuat data" />
        : <TableLayout noMarginTop label={menuTitle} withCreateButton onClick={() => setModalConfig({ ...modalConfig, show: true, action: "CREATE"  })}>
            <Table />
          </TableLayout>
      }

      <CreateModal />
      <UpdateModal />
      <DeleteModal />
      <DetailModal />
    </CRUDLayout>
  );
};

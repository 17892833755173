import Services from "services";

class TaxCodeApi {
  getList(params) {
    return Services.get("/taxcode/", { params });
  }
  dropdownType(params) {
    return Services.get("/typetax/", { params });
  }
  dropdownCOA(params) {
    return Services.get("/coa/", { params });
  }
  create(data) {
    return Services.post("/taxcode/add", data);
  }
  update(data, id) {
    return Services.put(`/taxcode/edit?tax_id=${id}`, data);
  }
  delete(id) {
    return Services.delete(`/taxcode/delete?tax_id=${id}`);
  }
}

export default new TaxCodeApi();

import React from "react";
import { useFormikContext } from "formik";
import { Input, SaveButton } from "components";

export const FormMap = ({ action, isDisabled }) => {
  const { values, errors, touched, setValues, isSubmitting } = useFormikContext() ?? {}

  return (
    <>
      <Input
        label="Link Map"
        type="text"
        placeholder={action !== "READ" && "Input link map"}
        value={values?.link_map}
        onChange={(e) => setValues({ ...values, link_map: e?.target?.value })}
        error={Boolean(errors?.link_map && touched?.link_map)}
        errorText={Boolean(errors?.link_map && touched?.link_map) && errors?.link_map}
        readOnly={action === "READ"}
      />
      {action !== "READ" && <div className="d-flex justify-content-end mt-3">
        <SaveButton icon forModal type="submit" disabled={isDisabled} />
      </div>}
    </>
  );
};

import { Fragment, useState } from "react"
import { Modal } from "react-bootstrap"
import { Alert, CloseButton, DeleteButton, InfoItemHorizontal } from "components"
import { FormProvider, useForm } from "react-hook-form"
import toast from "react-hot-toast"
import Services from "services"
import { Row, Col } from "react-bootstrap"
import { CheckList } from "./CheckList"

export function Delete({ data, onSuccess }) {
  const [isOpen, setOpen] = useState(false)

  return (
    <Fragment>
      <DeleteButton icon onClick={() => setOpen(true)} />
      {isOpen && <ModalSection data={data} onSuccess={onSuccess} toggle={() => setOpen(false)} />}
    </Fragment>
  )
}

function ModalSection({ data, onSuccess, toggle }) {
  const onSubmit = (value) => {
    return new Promise((resolve) => {
      toast.promise(
        Services.delete(`/project/delete?project_id=${data.project_id}`, value),
        {
          loading: "Loading...",
          success: (res) => res.data.message,
          error: (err) => err.response.data.detail
        }
      ).then(() => {
        toggle()
        onSuccess()
      }).catch(() => {}).finally(resolve)
    })
  }
  const { control, handleSubmit, setValue } = useForm({
    defaultValues: data
  })

  return (
    <Modal show onHide={toggle} size="lg">
      <Modal.Header closeButton>
        <Modal.Title
          className='d-flex align-items-center font-bold text-uppercase'
          style={{
            fontSize: "15px",
            textShadow: "0 0.5px 0.5px #000"
          }}
        >
          <b className='text-uppercase' style={{ fontSize: "15px", textShadow: "0 0.5px 0.5px #000" }}>Confirmation</b>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form>
          <h5 className="mb-4">Apakah anda yakin akan menghapus data ini?</h5>

          <FormProvider control={control} setValue={setValue}>
            <Row>
              <Col>
                <InfoItemHorizontal minWidth={200} label="Account Group" text={control?.group_coa_name ?? "-"} />
                <InfoItemHorizontal minWidth={200} label="Account Type" text={control?.account_type_name ?? "-"} />
                <InfoItemHorizontal minWidth={200} label="Level" text={control?.coa_level ?? "-"} />
                <InfoItemHorizontal minWidth={200} label="Account Number" text={`${control?.group_coa_code ?? ""}-${control?.coa_code ?? ""}` ?? "-"} />
                <InfoItemHorizontal minWidth={200} label="Normal Post" text={control?.normal_pos === 1 ? "Debet" : "Kredit" ?? "-"} />
                <InfoItemHorizontal minWidth={200} label="Note" text={control?.note ?? "-"} />
              </Col>
              <Col>
                <InfoItemHorizontal minWidth={200} label="Report Type" text={control?.report_type_name ?? "-"} />
                <InfoItemHorizontal minWidth={200} label="Parent" text={`${control?.group_coa_code ?? ""}-${control?.coa_code ?? ""}` ?? "-"} />
                <InfoItemHorizontal minWidth={200} label="Header Level" text={control?.header_level === 1 ? "Header" : control?.header_level === 2 ? "Sub Header" : "Detail" ?? "-"} />
                <InfoItemHorizontal minWidth={200} label="Account Name" text={control?.coa_name ?? "-"} />
                <InfoItemHorizontal minWidth={200} label="Opening Balance" text={control?.opening_balance ?? "-"} />
                <CheckList label="Cheque Account" checked={control?.cheque_coa} />
                <CheckList label="Link To Bugeting" checked={control?.budget_coa} />
              </Col>
            </Row>
          </FormProvider>

          <Alert show textCenter text="Data akan dihapus secara permanen dan tidak dapat dikembalikan !" variant="danger" />

          <hr className='my-3'/>
          <div className="d-flex">
            <CloseButton icon onClick={toggle} />
            <DeleteButton icon forModal label="Delete" onClick={handleSubmit(onSubmit)} />
          </div>
        </form>
      </Modal.Body>
    </Modal>
  )
}
// React
import { Fragment, useEffect, useRef } from "react"

// Components
import { Input, SelectSearch, TextArea } from "components"

// Form
import { Controller, useFormContext, useWatch } from "react-hook-form"

// Utils
import { useCountry, useProvince, useRegency, useSubdistrict, useVillage } from "./utils"

export function FormSection({ isDisabled }) {
  // Form
  const { control } = useFormContext()

  return (
    <Fragment>
      <Controller
        control={control}
        name="project_name"
        render={({ field, fieldState }) => (
          <Input
            {...field}
            label="PROJECT"
            error={fieldState.error}
            errorText={fieldState.error?.message}
            readOnly={isDisabled}
          />
        )}
      />

      <Country isDisabled={isDisabled} />
      <Province isDisabled={isDisabled} />
      <Regency isDisabled={isDisabled} />
      <Subdistrict isDisabled={isDisabled} />
      <Village isDisabled={isDisabled} />

      <Controller
        control={control}
        name="address"
        render={({ field, fieldState }) => (
          <TextArea
            {...field}
            label="ADDRESS"
            error={fieldState.error}
            errorText={fieldState.error?.message}
            disabled={isDisabled}
          />
        )}
      />

      <Controller
        control={control}
        name="note"
        render={({ field, fieldState }) => (
          <TextArea
            {...field}
            label="NOTE"
            error={fieldState.error}
            errorText={fieldState.error?.message}
            disabled={isDisabled}
          />
        )}
      />
    </Fragment>
  )
}

function Country({ isDisabled }) {
  // Hooks
  const { data, isLoading } = useCountry()

  // Form
  const { control } = useFormContext()

  return (
    <Controller
      control={control}
      name="country_id"
      render={({ field, fieldState }) => (
        <SelectSearch
          label="COUNTRY"
          placeholder="Select Country..."
          defaultValue={data.find(item => item.value === field.value)}
          loading={isLoading}
          error={fieldState.error}
          errorText={fieldState.error?.message}
          isDisabled={isDisabled}
          option={data}
          onChange={item => field.onChange(item.value)}
        />
      )}
    />
  )
}

function Province({ isDisabled }) {
  // Hooks
  const ref = useRef(false)

  // Form
  const { control, setValue } = useFormContext()
  const country_id = useWatch({
    control,
    name: "country_id"
  })
  
  // Hooks
  const { data, isLoading, refetch } = useProvince(country_id)

  useEffect(() => {
    refetch()

    // Prevent run in the first render
    if (!ref.current) {
      ref.current = true
    } else {
      setValue("province_id", "")
    }

    // eslint-disable-next-line
  }, [country_id])
  

  return (
    <Controller
      control={control}
      name="province_id"
      render={({ field, fieldState }) => (
        <SelectSearch
          label="PROVINCE"
          placeholder="Select Province..."
          defaultValue={data.find(item => item.value === field.value)}
          loading={isLoading}
          error={fieldState.error}
          errorText={fieldState.error?.message}
          option={data}
          isDisabled={isDisabled || !country_id}
          onChange={item => field.onChange(item.value)}
        />
      )}
    />
  )
}

function Regency({ isDisabled }) {
  // Hooks
  const ref = useRef(false)

  // Form
  const { control, setValue } = useFormContext()
  const country_id = useWatch({
    control,
    name: "country_id"
  })
  const province_id = useWatch({
    control,
    name: "province_id"
  })
  
  // Hooks
  const { data, isLoading, refetch } = useRegency(province_id)

  useEffect(() => {
    refetch()

    // Prevent run in the first render
    if (!ref.current) {
      ref.current = true
    } else {
      setValue("regency_id", "")
    }

    // eslint-disable-next-line
  }, [country_id, province_id])
  

  return (
    <Controller
      control={control}
      name="regency_id"
      render={({ field, fieldState }) => (
        <SelectSearch
          label="REGENCY"
          placeholder="Select Regency..."
          defaultValue={data.find(item => item.value === field.value)}
          loading={isLoading}
          error={fieldState.error}
          errorText={fieldState.error?.message}
          option={data}
          isDisabled={isDisabled || !country_id || !province_id}
          onChange={item => field.onChange(item.value)}
        />
      )}
    />
  )
}

function Subdistrict({ isDisabled }) {
  // Hooks
  const ref = useRef(false)

  // Form
  const { control, setValue } = useFormContext()
  const country_id = useWatch({
    control,
    name: "country_id"
  })
  const province_id = useWatch({
    control,
    name: "province_id"
  })
  const regency_id = useWatch({
    control,
    name: "regency_id"
  })
  
  // Hooks
  const { data, isLoading, refetch } = useSubdistrict(regency_id)

  useEffect(() => {
    refetch()

    // Prevent run in the first render
    if (!ref.current) {
      ref.current = true
    } else {
      setValue("subdistrict_id", "")
    }

    // eslint-disable-next-line
  }, [country_id, province_id, regency_id])
  

  return (
    <Controller
      control={control}
      name="subdistrict_id"
      render={({ field, fieldState }) => (
        <SelectSearch
          label="SUBDISTRICT"
          placeholder="Select Subdistrict..."
          defaultValue={data.find(item => item.value === field.value)}
          loading={isLoading}
          error={fieldState.error}
          errorText={fieldState.error?.message}
          option={data}
          isDisabled={isDisabled || !country_id || !province_id || !regency_id}
          onChange={item => field.onChange(item.value)}
        />
      )}
    />
  )
}

function Village({ isDisabled }) {
  // Hooks
  const ref = useRef(false)

  // Form
  const { control, setValue } = useFormContext()
  const country_id = useWatch({
    control,
    name: "country_id"
  })
  const province_id = useWatch({
    control,
    name: "province_id"
  })
  const regency_id = useWatch({
    control,
    name: "regency_id"
  })
  const subdistrict_id = useWatch({
    control,
    name: "subdistrict_id"
  })
  
  // Hooks
  const { data, isLoading, refetch } = useVillage(subdistrict_id)

  useEffect(() => {
    refetch()

    // Prevent run in the first render
    if (!ref.current) {
      ref.current = true
    } else {
      setValue("village_id", "")
    }

    // eslint-disable-next-line
  }, [country_id, province_id, regency_id, subdistrict_id])
  

  return (
    <Controller
      control={control}
      name="village_id"
      render={({ field, fieldState }) => (
        <SelectSearch
          label="VILLAGE"
          placeholder="Select Village..."
          defaultValue={data.find(item => item.value === field.value)}
          loading={isLoading}
          error={fieldState.error}
          errorText={fieldState.error?.message}
          option={data}
          isDisabled={isDisabled || !country_id || !province_id || !regency_id || !subdistrict_id}
          onChange={item => field.onChange(item.value)}
        />
      )}
    />
  )
}
import { Fragment, useState } from "react"
import Services from "services"
import { CRUDModalSection, SaveButton, UpdateButton } from "components"
import { FormSection } from "./FormSection"
import { FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import toast from "react-hot-toast"
import { baseData, validationSchema } from "../utils"

export function Update({ data, onSuccess }) {
  const [isOpen, setOpen] = useState(false)

  return (
    <Fragment>
      <UpdateButton icon onClick={() => setOpen(true)} />

      {isOpen && (
        <Modal
          data={data}
          toggle={() => setOpen(false)}
          onSuccess={() => {
            setOpen(false)
            onSuccess()
          }}
        />
      )}
    </Fragment>
  )
}

function Modal({ data, onSuccess, toggle }) {
  const onSubmit = (value) => {
    return new Promise((resolve) => {
      toast.promise(
        Services.put(`/categoryvendor/edit?category_vendor_id=${data.category_vendor_id}`, value),
        {
          loading: "Loading...",
          success: (res) => res.data.message,
          error: (err) => err.response.data.detail
        }
      ).then(onSuccess).catch(() => {}).finally(resolve)
    })
  }
  const { control, formState, handleSubmit, setValue } = useForm({
    defaultValues: data,
    resolver: yupResolver(validationSchema)
  })

  return (
    <CRUDModalSection
      show
      type="update"
      title={baseData.title}
      onHide={toggle}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormProvider control={control} setValue={setValue}>
          <FormSection />
          <hr className='my-3'/>
          <div className="d-flex">
            <SaveButton icon forModal type="submit" loading={formState.isSubmitting} />
          </div>
        </FormProvider>
      </form>
    </CRUDModalSection>
  )
}
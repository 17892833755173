import Services from "services";

class CreateNewCustomerApi {
  getList(params) {
    return Services.get("/customer/", { params });
  }
  generateCode(params) {
    return Services.get("/customer/gencode", { params });
  }
  getProfile(id) {
    return Services.get(`/customer/profile?customer_id=${id}`);
  }
  getAddress(id) {
    return Services.get(`/customer/address?customer_id=${id}`);
  }
  getMap(id) {
    return Services.get(`/customer/linkmap?customer_id=${id}`);
  }

  dropdownPriceLevel(params) {
    return Services.get("/pricelevel/", { params });
  }
  dropdownPaymentTerm(params) {
    return Services.get("/paymentterm/", { params });
  }
  dropdownAddressType(params) {
    return Services.get("/addresstype/", { params });
  }
  dropdownBankName(params) {
    return Services.get("/bank/", { params });
  }
  dropdownCategory(params) {
    return Services.get("/categorycustomer/", { params });
  }
  dropdownGroup(params) {
    return Services.get("/groupcustomer/", { params });
  }
  dropdownMarketing(params) {
    return Services.get("/employee/", { params });
  }
  dropdownCardLabel(params) {
    return Services.get("/cardlabel/", { params });
  }
  dropdownCountry(params) {
    return Services.get("/countries/", { params });
  }
  dropdownProvince(id) {
    return Services.get(`/provinces/?county_id=${id}`);
  }
  dropdownRegencies(id) {
    return Services.get(`/regencies/?province_id=${id}`);
  }
  dropdownSubdistricts(id) {
    return Services.get(`subdistricts/?regency_id=${id}`);
  }
  dropdownVillages(id) {
    return Services.get(`villages/?subdistrict_id=${id}`);
  }

  createPersonalData(data) {
    return Services.post("/customer/add", data);
  }
  updatePersonalData(data, id) {
    return Services.put(`/customer/edit?customer_id=${id}`, data);
  }
  updateProfil(data, id) {
    return Services.put(`/customer/updateprofile?customer_id=${id}`, data);
  }
  updateAdress(data, id) {
    return Services.put(`/customer/updateaddress?customer_id=${id}`, data);
  }
  updateLink(data, id) {
    return Services.put(`/customer/updatealinkmap?customer_id=${id}`, data);
  }
  delete(id) {
    return Services.delete(`/customer/delete?customer_id=${id}`);
  }
}

export default new CreateNewCustomerApi();

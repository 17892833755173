import Services from "services";

class CreateNewVendorApi {
  getList(params) {
    return Services.get("/vendor/", { params });
  }
  generateCode(params) {
    return Services.get("/vendor/genvendorcode", { params });
  }
  getProfile(id) {
    return Services.get(`/vendor/profile?vendor_id=${id}`);
  }
  getAddress(id) {
    return Services.get(`/vendor/address?vendor_id=${id}`);
  }
  getMap(id) {
    return Services.get(`/vendor/linkmap?vendor_id=${id}`);
  }

  dropdownPriceLevel(params) {
    return Services.get("/pricelevel/", { params });
  }
  dropdownPaymentTerm(params) {
    return Services.get("/paymentterm/", { params });
  }
  dropdownMarketing(params) {
    return Services.get("/employee/", { params });
  }
  dropdownAddressType(params) {
    return Services.get("/addresstype/", { params });
  }
  dropdownBankName(params) {
    return Services.get("/bank/", { params });
  }
  dropdownCategory(params) {
    return Services.get("/categoryvendor/", { params });
  }
  dropdownGroup(params) {
    return Services.get("/groupcustomer/", { params });
  }
  dropdownCardLabel(params) {
    return Services.get("/cardlabel/", { params });
  }
  dropdownCountry(params) {
    return Services.get("/countries/", { params });
  }
  dropdownProvince(id) {
    return Services.get(`/provinces/?county_id=${id}`);
  }
  dropdownRegencies(id) {
    return Services.get(`/regencies/?province_id=${id}`);
  }
  dropdownSubdistricts(id) {
    return Services.get(`subdistricts/?regency_id=${id}`);
  }
  dropdownVillages(id) {
    return Services.get(`villages/?subdistrict_id=${id}`);
  }

  createPersonalData(data) {
    return Services.post("/vendor/add", data);
  }
  updatePersonalData(data, id) {
    return Services.put(`/vendor/edit?vendor_id=${id}`, data);
  }
  updateProfil(data, id) {
    return Services.put(`/vendor/updateprofile?vendor_id=${id}`, data);
  }
  updateAdress(data, id) {
    return Services.put(`/vendor/updateaddress?vendor_id=${id}`, data);
  }
  updateMap(data, id) {
    return Services.put(`/vendor/updatealinkmap?vendor_id=${id}`, data);
  }
  delete(id) {
    return Services.delete(`/vendor/delete?vendor_id=${id}`);
  }
}

export default new CreateNewVendorApi();

import React, { useState, useEffect } from "react";
import { CRUDLayout, TableLayout, AlertModal, DataStatus } from "components";
import { Formik, Form } from "formik";
import { useHistory, useLocation } from "react-router-dom";
import { Card, Tab, Nav } from "react-bootstrap";
import "./CreateNewCustomer.css"
import Axios from "axios"
import { FormPersonalData, FormProfile, FormAddress, FormMap } from "./__CreateNewCustomerComps__";
import { 
    formPersonalValues, 
    formValidationPersonal,

    formProfileValues,
    formValidationProfile,

    formAddressValues,
    formValidationAddress,

    formMapValues,
    formValidationMap
} from "./__CreateNewCustomerUtillities__";
import CreateNewCustomerApi from "./__CreateNewCustomerApi__";

export const CreateNewCustomerUpdate = () => {
  const menuTitle = "CUSTOMER REGISTRATION"
  const history = useHistory()
  const location = useLocation()
  const dataInfo = location?.state?.dataInfo?.customer_id
  const detailData = location?.state?.dataInfo

  const [profileList, setProfileList] = useState([])
  const [addressList, setAddressList] = useState([])
  const [mapList, setMapList] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [fileValue, setFileValue] = useState(null);
  const [alertModal, setAlertModal] = useState({ show: false, action: "" })

  const getData = () => {
    setIsLoading(true);

    Axios.all([
      CreateNewCustomerApi.getProfile(dataInfo),
      CreateNewCustomerApi.getAddress(dataInfo),
      CreateNewCustomerApi.getMap(dataInfo)
    ])
      .then(Axios.spread((profile, address, map) => {
        setProfileList(profile?.data?.payload)
        setAddressList(address?.data?.payload)
        setMapList(map?.data?.payload)
      }))
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };
  useEffect(() => {
    getData();

    return () => setIsLoading(false)
  }, []);

  const personalStyle = { fontSize: "15px", fontFamily: 'poppins', paddingTop: "8.9px", paddingBottom: "8.9px", backgroundColor: "#D1D1D1" }
  const tabHeaderStyle = { borderRadius: "0px", paddingLeft: "20px", paddingRight: "20px" }
  const cardHeaderStyle = { paddingTop: "0px", paddingBottom: "0px", backgroundColor: "#D1D1D1" }

  const formSubmitPersonalData = (values) => {

    const formData = new FormData();
    formData.append('Customer_name', String(values?.employee_name));
    formData.append(fileValue ? 'Customer_Foto' : "", fileValue);

    CreateNewCustomerApi.updatePersonalData(formData, dataInfo )
    .then(() => setAlertModal({ ...alertModal, show: true, action: "success" }))
    .catch(() =>  setAlertModal({ ...alertModal, show: true, action: "error" }))
    .finally(() => setAlertModal({ ...alertModal, show: true, action: "success" }));
  };
  const formSubmitProfile = (values) => {
    const nonEmptyValues = Object.fromEntries(Object.entries(values).filter(([key, value]) => value !== ""));

    CreateNewCustomerApi.updateProfil(nonEmptyValues, dataInfo)
    .then(() => setAlertModal({ ...alertModal, show: true, action: "success" }))
    .catch((err) =>  setAlertModal({ ...alertModal, show: true, action: "error" }))
    .finally(() => setAlertModal({ ...alertModal, show: true, action: "success" }));
  };
  const formSubmitAddress = (values) => {
    const nonEmptyValues = Object.fromEntries(Object.entries(values).filter(([key, value]) => value !== ""));

    CreateNewCustomerApi.updateAdress(nonEmptyValues, dataInfo)
    .then(() => setAlertModal({ ...alertModal, show: true, action: "success" }))
    .catch((err) =>  setAlertModal({ ...alertModal, show: true, action: "error" }))
    .finally(() => setAlertModal({ ...alertModal, show: true, action: "success" }));
  };
  const formSubmitMap = (values) => {
    const nonEmptyValues = Object.fromEntries(Object.entries(values).filter(([key, value]) => value !== ""));

    CreateNewCustomerApi.updateLink(nonEmptyValues, dataInfo)
    .then(() => setAlertModal({ ...alertModal, show: true, action: "success" }))
    .catch((err) =>  setAlertModal({ ...alertModal, show: true, action: "error" }))
    .finally(() => setAlertModal({ ...alertModal, show: true, action: "success" }));
  };

  return (
    <CRUDLayout>
        <TableLayout create label={menuTitle} withBackButton onClick={() => history.goBack()}>
            {isLoading
                ? <DataStatus loading={isLoading} text="Loading..." />
                : <div className="d-flex p-1">
                    <Card style={{ width: "30%", borderRadius: "0px" }}>
                        <Card.Header style={personalStyle}>PERSONAL DATA</Card.Header>
                        <Card.Body>
                            <Formik 
                                enableReinitialize 
                                initialValues={formPersonalValues(detailData)} 
                                validationSchema={formValidationPersonal} 
                                onSubmit={(values) => formSubmitPersonalData(values)}
                            >
                                {(formik) => ( <Form><FormPersonalData action="UPDATE" setFileValue={setFileValue} /></Form> )}
                            </Formik>
                        </Card.Body>
                    </Card>
                    <Card style={{ width: "70%", borderRadius: "0px" }}>
                        <Tab.Container id="left-tabs-example" defaultActiveKey="profile">
                            <Card.Header style={cardHeaderStyle}>
                                <Nav variant="pills" className="d-flex justify-content-start">
                                    <Nav.Item>
                                        <Nav.Link style={tabHeaderStyle} eventKey="profile">PROFILE</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link style={tabHeaderStyle} eventKey="address">ADDRESS</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link style={tabHeaderStyle} eventKey="map">MAP</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </Card.Header>
                            <Card.Body>
                                <Tab.Content>
                                    <Tab.Pane eventKey="profile">
                                        <Formik 
                                            enableReinitialize 
                                            initialValues={formProfileValues(profileList[0])} 
                                            // validationSchema={formValidationProfile} 
                                            onSubmit={(values) => formSubmitProfile(values)}
                                        >
                                            {(formik) => ( <Form><FormProfile action="UPDATE" /></Form> )}
                                        </Formik>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="address">
                                        <Formik 
                                            enableReinitialize 
                                            initialValues={formAddressValues(addressList[0])} 
                                            // validationSchema={formValidationAddress} 
                                            onSubmit={(values) => formSubmitAddress(values)}
                                        >
                                            {(formik) => ( <Form><FormAddress action="UPDATE" dataInfo={addressList} loading={isLoading} /></Form> )}
                                        </Formik>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="map">
                                        <Formik 
                                            enableReinitialize 
                                            initialValues={formMapValues(mapList)} 
                                            // validationSchema={formValidationMap} 
                                            onSubmit={(values) => formSubmitMap(values)}
                                        >
                                            {(formik) => ( <Form><FormMap /></Form> )}
                                        </Formik>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Card.Body>
                        </Tab.Container>
                    </Card>
                  </div>
            }
        </TableLayout>
        <AlertModal action={alertModal?.action} show={alertModal?.show} onHide={() => setAlertModal({ ...alertModal, show: false })} />
    </CRUDLayout>
  );
};

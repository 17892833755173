const Checkbox = ({id, label, name, value, error, onChange, checked}) => {
  return (
    <div className="custom-control custom-checkbox">
      <input 
        type="checkbox"
        name={name}
        value={value}
        checked={checked}
        className={`custom-control-input ${error && 'is-invalid'}`} 
        onChange={onChange}
        id={id}
      />
      <label className="custom-control-label pt-1" style={{ fontFamily: "poppins medium", fontSize: "12px" }} htmlFor={id}>{label ? label?.toUpperCase() : ""}</label>
    </div>
  )
}

export default Checkbox

import React, { useState, useEffect } from "react";
import { useFormikContext } from "formik";
import { Input, SaveButton, SelectSearch } from "components";
import CreateNewEmployeeApi from "../__CreateNewEmployeeApi__";
import Axios from "axios";

export const FormProfile = ({ action, isDisabled }) => {
  const { values, errors, touched, setValues, isSubmitting } = useFormikContext() ?? {}

  const [dropdownReligion, setDropdownReligion] = useState([]);
  const dropdownTypeOfNationality = [
    { value: 1, label: "KTP" },
    { value: 2, label: "Passport" },
  ]
  const dropdownMaritalStatus = [
    { value: 1, label: "Single" },
    { value: 2, label: "Married" },
  ]
  const [isLoading, setIsLoading] = useState(true);

  const getData = () => {
    setIsLoading(true);

    Axios.all([ 
        CreateNewEmployeeApi.dropdownReligion() 
    ])
      .then(Axios.spread((religion) => {
        const getReligion = religion?.data?.payload ? religion?.data?.payload?.map(val => ({ label: val?.religion_name, value: val?.religion_id })) : []

        setDropdownReligion(getReligion)
      }))
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };
  useEffect(() => {
    getData();

    return () => setIsLoading(false)
  }, []);

  return (
    <>
      <SelectSearch
        label="Type Of Nationality Id"
        placeholder={action !== "READ" && "Select type of nationalit id"}
        option={dropdownTypeOfNationality}
        value={values?.type_nationality ? dropdownTypeOfNationality?.find(item => item?.value?.toString() === values?.type_nationality?.toString()) : []}
        onChange={(val) => setValues({ ...values, type_nationality: val?.value })}
        error={Boolean(errors?.type_nationality && touched?.type_nationality)}
        loading={action !== "UPDATE" && isLoading}
        isDisabled={action === "READ"}
      />
      <Input
        label="Nomor Of Nationality Id"
        type="text"
        placeholder={action !== "READ" && "Input nomor of nationalit id"}
        value={values?.nomor}
        onChange={(e) => setValues({ ...values, nomor: e?.target?.value })}
        error={Boolean(errors?.nomor && touched?.nomor)}
        errorText={Boolean(errors?.nomor && touched?.nomor) && errors?.nomor}
        readOnly={action === "READ"}
      />
      <Input
        label="Date Of Birth"
        type="date"
        placeholder={action !== "READ" && "Input date of birth"}
        value={values?.birthdate}
        onChange={(e) => setValues({ ...values, birthdate: e?.target?.value })}
        error={Boolean(errors?.birthdate && touched?.birthdate)}
        errorText={Boolean(errors?.birthdate && touched?.birthdate) && errors?.birthdate}
        readOnly={action === "READ"}
      />
      <SelectSearch
        label="Marital Status"
        placeholder={action !== "READ" && "Select marital status"}
        option={dropdownMaritalStatus}
        value={values?.marital_status ? dropdownMaritalStatus?.find(item => item?.value === values?.marital_status) : []}
        onChange={(val) => setValues({ ...values, marital_status: val?.value })}
        error={Boolean(errors?.marital_status && touched?.marital_status)}
        loading={isLoading}
        isDisabled={action === "READ"}
      />
      <SelectSearch
        label="Religion"
        placeholder={action !== "READ" && "Select religion"}
        option={dropdownReligion}
        value={values?.religion_id ? dropdownReligion?.find(item => item?.value === values?.religion_id) : []}
        onChange={(val) => setValues({ ...values, religion_id: val?.value })}
        error={Boolean(errors?.religion_id && touched?.religion_id)}
        loading={isLoading}
        isDisabled={action === "READ"}
      />
      <Input
        label="Handphone"
        type="text"
        placeholder={action !== "READ" && "Input handphone"}
        value={values?.handphone}
        onChange={(e) => setValues({ ...values, handphone: e?.target?.value })}
        error={Boolean(errors?.handphone && touched?.handphone)}
        errorText={Boolean(errors?.handphone && touched?.handphone) && errors?.handphone}
        readOnly={action === "READ"}
      />
      <Input
        label="Email"
        type="text"
        placeholder={action !== "READ" && "Input email"}
        value={values?.email}
        onChange={(e) => setValues({ ...values, email: e?.target?.value })}
        error={Boolean(errors?.email && touched?.email)}
        errorText={Boolean(errors?.email && touched?.email) && errors?.email}
        readOnly={action === "READ"}
      />
      {action !== "READ" && <div className="d-flex justify-content-end mt-3">
        <SaveButton icon forModal type="submit" disabled={isDisabled} />
      </div>}
    </>
  );
};

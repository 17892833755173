import { useEffect, useState } from "react"
import Services from "services"

export function useAccountGroup() {
  const [data, setData] = useState([])
  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    Services.get("/groupcoa/").then(res => {
      setData(res.data.payload.map(item => {
        return {
          label: item?.group_coa_name, 
          value: item?.group_coa_id, 
          code: item?.group_coa_code, 
          type: item?.group_coa_type 
        }
      }))
    }).catch(() => {
      setData([])
    }).finally(() => {
      setLoading(false)
    })
  
    return () => {
      setData([])
      setLoading(true)
    }
  }, [])
  
  return { data, isLoading }
}
import { useState, useContext, cloneElement, Fragment, useEffect } from "react";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { Card, Accordion, useAccordionToggle, AccordionContext, Nav, Modal, Dropdown, Row, Col, Carousel, OverlayTrigger, Tooltip } from "react-bootstrap";
import { IoGitCommitOutline, IoRemoveOutline } from "react-icons/io5";
import { Alert, DropdownButton, NavbarButton, CreateButton } from "components";
import { AuthContext, AuthMethod, NotifContext } from "utilities";
import Config from "config";
import { useIsGuest } from "hooks";
import LogoHitam from "../../assets/image/LogoHitamCropCopy.png";
import CoverHAWK from "../../assets/image/Cover.png";
import CoverTwo from "../../assets/image/CoverTwo.png";
import Cover3 from "../../assets/image/Cover3.png";
import Cover4 from "../../assets/image/Cover4.png";
import { IoIosSettings } from "react-icons/io"
import { MdFavorite } from "react-icons/md"
import { FaHistory } from "react-icons/fa"
import "./Layout.css"
import { MainCardList } from "pages/Module";

const Layout = ({ children }) => {
  const [navbarTitle, setNavbarTitle] = useState("");
  const [menuType, setMenuType] = useState("");
  const [modulType, setModulType] = useState("");
  const [feature, setFeature] = useState("");
  const [inFeature, setInFeature] = useState("");
  const [childrenKey, setChildrenKey] = useState("");

  const [isSidebarCollapse, setIsSidebarCollapse] = useState(true);
  const [infoModal, setInfoModal] = useState(false);

  const { state, dispatch } = useContext(AuthContext);
  const notif = useContext(NotifContext);
  const { username, role } = state;
  const { MENU_ACCOUNTS, MENU_CARDS, MENU_FINANCE } = Config;
  const isGuest = useIsGuest();
  const history = useHistory();
  const location = useLocation();

  const currentPath = location?.pathname
  const pathParts = currentPath?.split("/")
  const section = pathParts[1];
  const lastSection = pathParts[3];
  // console.log("path", pathParts[2])

  const subModulStyle = { backgroundColor: section !== "setup" && "#4A4A4A", transition: '0.3s', height: section !== "setup" && pathParts[1] !== "" && "54px", marginTop: "120px" }

  const checkDropdown = (val) => {
    if (val?.menu && val?.menu?.length > 0) { return false }
    return true;
  };
  // const checkNotif = (hak) => {
  //   let totalNotif = 0;
  //   for (const props in notif) {
  //     if (hak.find((find) => find === props)) {
  //       totalNotif = totalNotif + notif[props];
  //     }
  //   }
  //   return totalNotif > 0 ? totalNotif : null;
  // };

  const SidebarLink = ({ text, link, icon, exact, childrenNumber, onClick, eventKey, center }) => {
    const sidebarLinkStyle = { paddingLeft: childrenNumber === 2 ? "15px" : childrenNumber === 3 ? "30px" : "" }
    const navLinkStyle = { fontSize: childrenNumber === 2 ? "12px" : childrenNumber === 3 ? "12px" : "14px" }
    const navLinkClass = `nav-link sidebar-link d-flex text-white bg-dark mb-1 ${center === true && "justify-content-center"} align-items-center px-2 rounded`

    return (
      <Nav.Link
        exact={exact}
        as={NavLink}
        to={link}
        className={navLinkClass}
        activeClassName="active"
        style={navLinkStyle}
        onClick={onClick}
        eventKey={eventKey}
      >
        <div className="d-flex align-items-center justify-content-center" style={sidebarLinkStyle}>
          <div className="mr-2">
            {icon ? (icon) : childrenNumber === 2 ? (<IoGitCommitOutline />) : childrenNumber === 3 ? (<IoRemoveOutline />) : (<IoGitCommitOutline />)}
          </div>
          <div className="text-uppercase">{text}</div>
        </div>
      </Nav.Link>
    );
  };
  const SidebarLinkDropdown = ({ text, icon, children, childrenNumber, eventKey, link }) => {
    const AccordionTitle = ({ eventKey, callback }) => {
      const currentEventKey = useContext(AccordionContext);
      const decoratedOnClick = useAccordionToggle(eventKey, () => callback && callback(eventKey));
      const isCurrentEventKey = currentEventKey === eventKey;
      const accordionClass = `nav-link sidebar-link-dropdown bg-dark d-flex justify-content-center mb-1 align-items-center px-2 rounded text-white ${isCurrentEventKey ? "active border" : ""}`
      const accordionStyle = {fontSize: childrenNumber === 2 ? "12px" : childrenNumber === 3 ? "12px" : "14px" }
      const accordionChildStyle = {paddingLeft: childrenNumber === 2 ? "15px" : childrenNumber === 3 ? "30px" : ""}

      return (
        <Accordion.Toggle
          as={Nav.Link}
          eventKey={eventKey}
          className={accordionClass}
          onClick={decoratedOnClick}
          style={accordionStyle}
        >
          <div className="d-flex align-items-center" style={accordionChildStyle}>
            <div className="mr-2">
              {icon ? (icon) : childrenNumber === 2 ? (<IoGitCommitOutline />) : childrenNumber === 3 ? (<IoRemoveOutline /> ) : (<IoGitCommitOutline />)}
            </div>
            <div className="text-uppercase">{text}</div>
          </div>
        </Accordion.Toggle>
      );
    };

    return (
      <>
        {/* Header */}
        <AccordionTitle eventKey={eventKey} />
        {/* Body */}
        <Accordion.Collapse eventKey={eventKey}>
          <Card.Body className="p-0 shadow-none">{children}</Card.Body>
        </Accordion.Collapse>
      </>
    );
  };
  const Navbar = () => {
    return (
      <nav className="navbar-wrapper">
        <div className="d-flex align-items-center">
          <b className="mx-3 text-uppercase d-flex align-items-center" style={{ fontSize: 14 }}>
            <OverlayTrigger overlay={<Tooltip>Back</Tooltip>}>
              <button onClick={() => {
                setModulType("dashboard")
                // history.goBack()
                history.push("/")
              }}>
                <img src={LogoHitam} width={30} className="mr-2" />
              </button>
            </OverlayTrigger>
            <b>FLYING HAWK INDONESIA</b>
          </b>
        </div>
        <div className="account-info">
          {/* <Dropdown>
            <Dropdown.Toggle variant="none" className="d-flex align-items-center text-dark">
              <div className="avatar mr-2 rounded-circle bg-success text-white">
                {username.charAt(0).toUpperCase()}
              </div>
              <span className="text-uppercase mr-1">{username}</span>
            </Dropdown.Toggle>
            <Dropdown.Menu className="mt-1" style={{ zIndex: 999999999999999999999999 }}>
              <Dropdown.Item onClick={async () => await dispatch({ type: AuthMethod.LOGOUT })}>
                LOG OUT
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown> */}
          {/* <button onClick={async () => await dispatch({ type: AuthMethod.LOGOUT })}>
            LOG OUT
          </button> */}
          <CreateButton 
            background="black" 
            label={<div className='d-flex align-items-center'>LOG OUT</div>} 
            onClick={async () => await dispatch({ type: AuthMethod.LOGOUT })} 
          />
        </div>
      </nav>
    );
  };

  const Module = () => {
    if(section === "setup") {
      const dataModulLeft = [
        {
          tooltipText: "Setup",
          type: "setup",
          label: <IoIosSettings style={{ fontSize: "25px", color: "black", }} />,
          route: "/setup"
        },
        {
          tooltipText: "Favorit",
          type: "favorit",
          label: <MdFavorite style={{ fontSize: "25px", color: "black" }} />,
          route: "/favorit"
        },
        {
          tooltipText: "History",
          type: "history",
          label: <FaHistory style={{ fontSize: "21px", color: "black" }} />,
          route: "/history"
        },
      ]
      const dataDropdown = [
        {
          type: "prefences",
          label: "Prefences",
          parent: [
            // {
            //   route: "/setup/prefences/company-profile",
            //   menu_type: "prefences",
            //   modul_type: "setup",
            //   parent_label: "Company Profile",
            //   is_active: "company-profile"
            // },
            {
              route: "/setup/prefences/tax-code",
              menu_type: "prefences",
              modul_type: "setup",
              parent_label: "Tax Code",
              is_active: "tax-code"
            },
            {
              route: "/setup/prefences/location",
              menu_type: "prefences",
              modul_type: "setup",
              parent_label: "Location",
              is_active: "location"
            },
            // {
            //   route: "/setup/prefences/market-group",
            //   menu_type: "prefences",
            //   modul_type: "setup",
            //   parent_label: "Market Group",
            //   is_active: "market-group"
            // },
            // {
            //   route: "/setup/prefences/currency",
            //   menu_type: "prefences",
            //   modul_type: "setup",
            //   parent_label: "Currency",
            //   is_active: "currency"
            // },
            // {
            //   route: "/setup/prefences/departement",
            //   menu_type: "prefences",
            //   modul_type: "setup",
            //   parent_label: "Departement",
            //   is_active: "departement"
            // },
            // {
            //   route: "/setup/prefences/position-hierarchy",
            //   menu_type: "prefences",
            //   modul_type: "setup",
            //   parent_label: "Position Hierarchy",
            //   is_active: "position-hierarchy"
            // },
            // {
            //   route: "/setup/prefences/branch-location",
            //   menu_type: "prefences",
            //   modul_type: "setup",
            //   parent_label: "Branch/Location",
            //   is_active: "branch-location"
            // },
            {
              route: "/setup/prefences/project-jobs",
              menu_type: "prefences",
              modul_type: "setup",
              parent_label: "Project/Jobs",
              is_active: "project-jobs"
            },
            // {
            //   route: "/setup/prefences/bank",
            //   menu_type: "prefences",
            //   modul_type: "setup",
            //   parent_label: "Bank",
            //   is_active: "bank"
            // },
            // {
            //   route: "/setup/prefences/category-customer",
            //   menu_type: "prefences",
            //   modul_type: "setup",
            //   parent_label: "Category Customer",
            //   is_active: "category-customer"
            // },
            // {
            //   route: "/setup/prefences/category-vendor",
            //   menu_type: "prefences",
            //   modul_type: "setup",
            //   parent_label: "Category Vendor",
            //   is_active: "category-vendor"
            // },
            // {
            //   route: "/setup/prefences/departement",
            //   menu_type: "prefences",
            //   modul_type: "setup",
            //   parent_label: "Departement",
            //   is_active: "departement"
            // },
            // {
            //   route: "/setup/prefences/divisi",
            //   menu_type: "prefences",
            //   modul_type: "setup",
            //   parent_label: "Divisi",
            //   is_active: "divisi"
            // },
            // {
            //   route: "/setup/prefences/payment-term-customer",
            //   menu_type: "prefences",
            //   modul_type: "setup",
            //   parent_label: "Payment Term Customer",
            //   is_active: "payment-term-customer"
            // },
            // {
            //   route: "/setup/prefences/payment-term-vendor",
            //   menu_type: "prefences",
            //   modul_type: "setup",
            //   parent_label: "Payment Term Vendor",
            //   is_active: "payment-term-vendor"
            // },
            // {
            //   route: "/setup/prefences/price-level-customer",
            //   menu_type: "prefences",
            //   modul_type: "setup",
            //   parent_label: "Price Level Customer",
            //   is_active: "price-level-customer"
            // },
            // {
            //   route: "/setup/prefences/price-level-vendor",
            //   menu_type: "prefences",
            //   modul_type: "setup",
            //   parent_label: "Price Level Vendor",
            //   is_active: "price-level-vendor"
            // },
          ]
        },
        {
          type: "accounts",
          label: "Accounts",
          parent: [
            {
              route: "/setup/accounts/chart-of-account-group",
              menu_type: "accounts",
              modul_type: "setup",
              parent_label: "Chart Of Account Group",
              is_active: "chart-of-account-group"
            },
            {
              route: "/setup/accounts/chart-of-account-type",
              menu_type: "accounts",
              modul_type: "setup",
              parent_label: "Chart Of Account Type",
              is_active: "chart-of-account-type"
            },
            // {
            //   route: "/setup/accounts/link-account-sales",
            //   menu_type: "accounts",
            //   modul_type: "setup",
            //   parent_label: "Link Account Sales",
            //   is_active: "link-account-sales"
            // },
            // {
            //   route: "/setup/accounts/link-account-bills",
            //   menu_type: "accounts",
            //   modul_type: "setup",
            //   parent_label: "Link Account Bills",
            //   is_active: "link-account-bills"
            // },
            // {
            //   route: "/setup/accounts/link-account-general",
            //   menu_type: "accounts",
            //   modul_type: "setup",
            //   parent_label: "Link Account General",
            //   is_active: "link-account-general"
            // },
          ]
        },
        {
          type: "finance",
          label: "Finance",
          // parent: [
          //   {
          //     route: "/setup/chart-of-account-group",
          //     menu_type: "finance",
          //     modul_type: "setup",
          //     parent_label: "Chart Of Account Group"
          //   }
          // ]
        },
        {
          type: "sales",
          label: "Sales",
          // parent: [
          //   {
          //     route: "/setup/chart-of-account-group",
          //     menu_type: "sales",
          //     modul_type: "setup",
          //     parent_label: "Chart Of Account Group"
          //   }
          // ]
        },
        {
          type: "purchases",
          label: "Purchases",
          // parent: [
          //   {
          //     route: "/setup/chart-of-account-group",
          //     menu_type: "purchases",
          //     modul_type: "setup",
          //     parent_label: "Chart Of Account Group"
          //   }
          // ]
        },
        {
          type: "inventori",
          label: "Inventory",
          parent: [
            {
              route: "/setup/inventori/product-unit",
              menu_type: "inventori",
              modul_type: "setup",
              parent_label: "Product Unit",
              is_active: "product-unit"
            },
            {
              route: "/setup/inventori/product-group",
              menu_type: "inventori",
              modul_type: "setup",
              parent_label: "Product Group",
              is_active: "product-group"
            },
            {
              route: "/setup/inventori/product-category",
              menu_type: "inventori",
              modul_type: "setup",
              parent_label: "Product Category",
              is_active: "product-category"
            },
            {
              route: "/setup/inventori/product-material",
              menu_type: "inventori",
              modul_type: "setup",
              parent_label: "Product Material",
              is_active: "product-material"
            },
            {
              route: "/setup/inventori/product-colour",
              menu_type: "inventori",
              modul_type: "setup",
              parent_label: "Product Colour",
              is_active: "product-colour"
            },
            {
              route: "/setup/inventori/product-size",
              menu_type: "inventori",
              modul_type: "setup",
              parent_label: "Product Size",
              is_active: "product-size"
            },
            {
              route: "/setup/inventori/product-style",
              menu_type: "inventori",
              modul_type: "setup",
              parent_label: "Product Style",
              is_active: "product-style"
            },
            {
              route: "/setup/inventori/product-status",
              menu_type: "inventori",
              modul_type: "setup",
              parent_label: "Product Status",
              is_active: "product-status"
            },
            {
              route: "/setup/inventori/product-pricing-level",
              menu_type: "inventori",
              modul_type: "setup",
              parent_label: "Product Pricing Level",
              is_active: "product-pricing-level"
            },
          ]
        },
        {
          type: "cards",
          label: "Cards",
          // parent: [
          //   {
          //     route: "/setup/chart-of-account-group",
          //     menu_type: "cards",
          //     modul_type: "setup",
          //     parent_label: "Chart Of Account Group"
          //   }
          // ]
        },
      ]

      return (
        <div className="text-white d-flex justify-content-between bg-black" style={{ marginTop: "70px" }}>
          <div className="text-white d-flex justify-content-center bg-white">
            {dataModulLeft?.map(val => (
              <NavbarButton 
                bgWhite 
                setup 
                tooltip 
                tooltipText={val?.tooltipText} 
                type={val?.type} 
                menuType={section} 
                label={val?.label}
                onClick={() => {
                  history.push(val?.route)
                  setModulType(val?.type)
                }}
              />
            ))}
          </div>
          {dataDropdown?.map(val => (
            <DropdownButton type={val?.type} menuType={pathParts[2]} label={val?.label}>
              {val?.parent?.map(item => (
                <Dropdown.Item 
                  active={item?.is_active === lastSection}
                  onClick={() => {
                    history.push(item?.route)
                    setMenuType(item?.menu_type)
                    setModulType(item?.modul_type)
                }}>{item?.parent_label}</Dropdown.Item>
              ))}
            </DropdownButton>
          ))}
          <div className="text-white d-flex justify-content-center bg-white">
            <NavbarButton 
              bgWhite
              noClass
              setup
              tooltip 
              tooltipText="Info" 
              type="info" 
              menuType={section} 
              label={<img src={LogoHitam} style={{ width: "30rem", height: "1.9rem" }} />} 
              onClick={() => setInfoModal(true)}
            />
          </div>
        </div>
      )
    }

    const dataModulLeft = [
      {
        tooltipText: "Setup",
        type: "setup",
        label: <IoIosSettings style={{ fontSize: "25px", color: "black", }} />,
        route: "/setup"
      },
      {
        tooltipText: "Favorit",
        type: "favorit",
        label: <MdFavorite style={{ fontSize: "25px", color: "black" }} />,
        route: "/favorit"
      },
      {
        tooltipText: "History",
        type: "history",
        label: <FaHistory style={{ fontSize: "21px", color: "black" }} />,
        route: "/history"
      },
    ]
    const dataModulCenter = [
      {
        type: "analytics",
        label: "ANALYTICS",
        route: "/analytics"
      },
      {
        type: "accounts",
        label: "ACCOUNTS",
        route: "/accounts"
      },
      {
        type: "finance",
        label: "FINANCE",
        route: "/finance"
      },
      {
        type: "sales",
        label: "SALES",
        route: "/sales"
      },
      {
        type: "purchases",
        label: "PURCHASES",
        route: "/purchases"
      },
      {
        type: "inventori",
        label: "INVENTORY",
        route: "/inventori"
      },
      {
        type: "cards",
        label: "CARDS",
        route: "/cards"
      },
    ]

    return (
      <div className="fixed-top text-white d-flex justify-content-between bg-black" style={{ marginTop: "70px" }}>
        <div className="text-white d-flex justify-content-center bg-white">
          {dataModulLeft?.map(val => (
            <NavbarButton 
              bgWhite 
              tooltip 
              tooltipText={val?.tooltipText} 
              type={val?.type} 
              menuType={section} 
              label={val?.label}
              onClick={() => {
                history.push(val?.route)
                setModulType(val?.type)
              }}
            />
          ))}
        </div>
        {dataModulCenter?.map(val => (
          <NavbarButton 
            type={val?.type} 
            menuType={section} 
            label={val?.label}
            onClick={() => {
              history.push(val?.route)
              setMenuType(val?.type)
            }}
          />
        ))}
        <div className="text-white d-flex justify-content-center bg-white">
          <NavbarButton 
            bgWhite
            noClass
            tooltip 
            tooltipText="Info" 
            type="info" 
            menuType={section} 
            label={<img src={LogoHitam} style={{ width: "30rem", height: "1.7rem" }} />} 
            onClick={() => setInfoModal(true)}
          />
        </div>
      </div>
    );
  }
  const Features = () => {
    const dataFeature = [
      {
        type: "show-all",
        label: "SHOW ALL",
        route: "/accounts/register/show-all",
      },
      {
        type: "assets",
        label: "ASSETS",
        route: "/accounts/register/assets",
      },
      {
        type: "liabillity",
        label: "LIABILLITY",
        route: "/accounts/register/liabillity",
      },
      {
        type: "equity",
        label: "EQUITY",
        route: "/accounts/register/equity",
      },
      {
        type: "income",
        label: "INCOME",
        route: "/accounts/register/income",
      },
      {
        type: "cost-of-goods",
        label: "COST OF GOODS",
        route: "/accounts/register/cost-of-goods",
      },
      {
        type: "expences",
        label: "EXPENCES",
        route: "/accounts/register/expences",
      },
      {
        type: "other-incomes",
        label: "OTHER INCOMES",
        route: "/accounts/register/other-incomes",
      },
      {
        type: "other-expences",
        label: "OTEHR EXPENCES",
        route: "/accounts/register/other-expences",
      },
    ]

    return (
      <div className="text-white d-flex justify-content-between bg-feature" style={{ marginTop: "175px" }}>
        {dataFeature?.map(val => (
          <NavbarButton 
            isFeature
            type={val?.type} 
            menuType={lastSection} 
            label={val?.label}
            onClick={() => {
              history.push(val?.route)
              setFeature(val?.type)
              setInFeature(val?.type)
            }}
          />
        ))}
      </div>
    );
  }
  const CheckFeatures = () => {
    if(location?.pathname === `/${section}/register`) {
      return (<Features />)
    }
    if(location?.pathname === `/${section}/register/show-all`) {
      return (<Features />)
    }
    if(location?.pathname === `/${section}/register/assets`) {
      return (<Features />)
    }
    if(location?.pathname === `/${section}/register/liabillity`) {
      return (<Features />)
    }
    if(location?.pathname === `/${section}/register/equity`) {
      return (<Features />)
    }
    if(location?.pathname === `/${section}/register/income`) {
      return (<Features />)
    }
    if(location?.pathname === `/${section}/register/cost-of-goods`) {
      return (<Features />)
    }
    if(location?.pathname === `/${section}/register/expences`) {
      return (<Features />)
    }
    if(location?.pathname === `/${section}/register/other-incomes`) {
      return (<Features />)
    }
    if(location?.pathname === `/${section}/register/other-expences`) {
      return (<Features />)
    }
    return (<></>)
  }
  const SubModule = () => {
    return (
      <div className={`px-3 ${pathParts[1] !== "" && "py-2"} fixed-top`} style={subModulStyle}>
        <Accordion>
          <Row>
            {modulType !== "setup"
              ? section === "accounts" && feature === inFeature 
                // ? MENU_ACCOUNTS?.map((parent, index) => parent?.hak && parent?.hak?.some((hak) => role?.includes(hak)) && (
                ? MENU_ACCOUNTS?.map((parent, index) => (
                    <Col style={{ zIndex: 10000 }}>
                      <Fragment key={index}>
                        {checkDropdown(parent) 
                          ? <SidebarLink 
                              center={true} 
                              exact={parent?.exact} 
                              text={parent?.text} 
                              link={parent?.link} 
                              icon={parent?.icon} 
                              eventKey={index + 1} 
                              // notifCount={checkNotif(parent.hak)}
                            />
                          // : <SidebarLinkDropdown text={parent?.text} icon={parent?.icon} eventKey={index + 1} notifCount={checkNotif(parent.hak)}>
                          : <SidebarLinkDropdown text={parent?.text} icon={parent?.icon} eventKey={index + 1}>
                              <Accordion defaultActiveKey={childrenKey}>
                                {/* {parent?.menu && parent?.menu?.map((child1, indexChild1) => child1?.hak && child1?.hak?.some((hak) => role?.includes(hak)) && ( */}
                                {parent?.menu && parent?.menu?.map((child1, indexChild1) => (
                                  <Fragment key={indexChild1}>
                                    {checkDropdown(child1) 
                                      ? <SidebarLink 
                                          exact={child1?.exact} 
                                          text={child1?.text} 
                                          link={child1?.link} 
                                          icon={child1?.icon} 
                                          childrenNumber={2} 
                                          eventKey={indexChild1 + 100} 
                                          // notifCount={checkNotif(child1.hak)} 
                                          onClick={() => setIsSidebarCollapse(false)} 
                                        />
                                      : <SidebarLinkDropdown 
                                          text={child1?.text} 
                                          icon={child1?.icon} 
                                          eventKey={indexChild1 + 100} 
                                          childrenNumber={2}
                                          // notifCount={checkNotif(child1.hak)}
                                        >
                                          {child1?.menu && child1?.menu?.map((child2, indexChild2) => (
                                            <Fragment key={indexChild2}>
                                              {/* {child2?.hak && child2?.hak.some((hak) => role?.includes(hak)) && ( */}
                                              {child2?.hak && (
                                                <SidebarLink 
                                                  exact={child2?.exact} 
                                                  text={child2?.text} 
                                                  link={child2?.link} 
                                                  icon={child2?.icon} 
                                                  eventKey={indexChild2 + 100} 
                                                  childrenNumber={3} 
                                                  // notifCount={checkNotif(child2.hak)} 
                                                  onClick={() => setChildrenKey(indexChild1 + 100)} 
                                                />
                                              )}
                                            </Fragment>
                                          ))}
                                        </SidebarLinkDropdown>
                                    }
                                  </Fragment>
                                ))}
                              </Accordion>
                            </SidebarLinkDropdown>
                        }
                      </Fragment>
                    </Col>
                  ))
                : section === "cards"
                // ? MENU_CARDS.map((parent, index) => parent?.hak && parent?.hak?.some((hak) => role?.includes(hak)) && (
                ? MENU_CARDS?.map((parent, index) => (
                  <Col style={{ zIndex: 10000 }}>
                    <Fragment key={index}>
                      {checkDropdown(parent) 
                        ? <SidebarLink 
                            center={true} 
                            exact={parent?.exact} 
                            text={parent?.text} 
                            link={parent?.link} 
                            icon={parent?.icon} 
                            eventKey={index + 1} 
                            // notifCount={checkNotif(parent.hak)}
                          />
                        // : <SidebarLinkDropdown text={parent.text} icon={parent.icon} eventKey={index + 1} notifCount={checkNotif(parent.hak)}>
                        : <SidebarLinkDropdown text={parent?.text} icon={parent?.icon} eventKey={index + 1}>
                            <Accordion defaultActiveKey={childrenKey}>
                              {/* {parent?.menu && parent?.menu?.map((child1, indexChild1) => child1?.hak && child1?.hak?.some((hak) => role?.includes(hak)) && ( */}
                              {parent?.menu && parent?.menu?.map((child1, indexChild1) => (
                                <Fragment key={indexChild1}>
                                  {checkDropdown(child1) 
                                    ? <SidebarLink 
                                        exact={child1?.exact} 
                                        text={child1?.text} 
                                        link={child1?.link} 
                                        icon={child1?.icon} 
                                        childrenNumber={2} 
                                        eventKey={indexChild1 + 100} 
                                        // notifCount={checkNotif(child1.hak)} 
                                        onClick={() => setIsSidebarCollapse(false)} 
                                      />
                                    : <SidebarLinkDropdown 
                                        text={child1?.text} 
                                        icon={child1?.icon} 
                                        eventKey={indexChild1 + 100} 
                                        childrenNumber={2}
                                        // notifCount={checkNotif(child1.hak)}
                                      >
                                        {child1?.menu && child1?.menu?.map((child2, indexChild2) => (
                                          <Fragment key={indexChild2}>
                                            {child2?.hak && child2?.hak?.some((hak) => role?.includes(hak)) && (
                                              <SidebarLink 
                                                exact={child2?.exact} 
                                                text={child2?.text} 
                                                link={child2?.link} 
                                                icon={child2?.icon} 
                                                eventKey={indexChild2 + 100} 
                                                childrenNumber={3} 
                                                // notifCount={checkNotif(child2.hak)} 
                                                onClick={() => setChildrenKey(indexChild1 + 100)} 
                                              />
                                            )}
                                          </Fragment>
                                        ))}
                                      </SidebarLinkDropdown>
                                  }
                                </Fragment>
                              ))}
                            </Accordion>
                          </SidebarLinkDropdown>
                      }
                    </Fragment>
                  </Col>
                ))
                : section === "finance"
                // ? MENU_CARDS.map((parent, index) => parent?.hak && parent?.hak?.some((hak) => role?.includes(hak)) && (
                ? MENU_FINANCE?.map((parent, index) => (
                  <Col style={{ zIndex: 10000 }}>
                    <Fragment key={index}>
                      {checkDropdown(parent) 
                        ? <SidebarLink 
                            center={true} 
                            exact={parent?.exact} 
                            text={parent?.text} 
                            link={parent?.link} 
                            icon={parent?.icon} 
                            eventKey={index + 1} 
                            // notifCount={checkNotif(parent.hak)}
                          />
                        // : <SidebarLinkDropdown text={parent.text} icon={parent.icon} eventKey={index + 1} notifCount={checkNotif(parent.hak)}>
                        : <SidebarLinkDropdown link={parent?.link} text={parent?.text} icon={parent?.icon} eventKey={index + 1}>
                            <Accordion defaultActiveKey={childrenKey}>
                              {/* {parent?.menu && parent?.menu?.map((child1, indexChild1) => child1?.hak && child1?.hak?.some((hak) => role?.includes(hak)) && ( */}
                              {parent?.menu && parent?.menu?.map((child1, indexChild1) => (
                                <Fragment key={indexChild1}>
                                  {checkDropdown(child1) 
                                    ? <SidebarLink 
                                        exact={child1?.exact} 
                                        text={child1?.text} 
                                        link={child1?.link} 
                                        icon={child1?.icon} 
                                        childrenNumber={2} 
                                        eventKey={indexChild1 + 100} 
                                        // notifCount={checkNotif(child1.hak)} 
                                        onClick={() => setIsSidebarCollapse(false)} 
                                      />
                                    : <SidebarLinkDropdown 
                                        text={child1?.text} 
                                        icon={child1?.icon} 
                                        eventKey={indexChild1 + 100} 
                                        childrenNumber={2}
                                        // notifCount={checkNotif(child1.hak)}
                                      >
                                        {child1?.menu && child1?.menu?.map((child2, indexChild2) => (
                                          <Fragment key={indexChild2}>
                                            {child2?.hak && child2?.hak?.some((hak) => role?.includes(hak)) && (
                                              <SidebarLink 
                                                exact={child2?.exact} 
                                                text={child2?.text} 
                                                link={child2?.link} 
                                                icon={child2?.icon} 
                                                eventKey={indexChild2 + 100} 
                                                childrenNumber={3} 
                                                // notifCount={checkNotif(child2.hak)} 
                                                onClick={() => setChildrenKey(indexChild1 + 100)} 
                                              />
                                            )}
                                          </Fragment>
                                        ))}
                                      </SidebarLinkDropdown>
                                  }
                                </Fragment>
                              ))}
                            </Accordion>
                          </SidebarLinkDropdown>
                      }
                    </Fragment>
                  </Col>
                ))
                : ""
              : ""
            }
          </Row>
        </Accordion>
      </div>
    )
  }

  const IsVisitor = () => {
    return (
      <div className="pt-4 px-3 responsive">
        {isGuest && (
          <Alert
            show={true}
            variant="danger"
            text="Anda Login sebagai VISITOR. Anda tidak dapat menambah, mengubah, menghapus dan memanipulasi data."
          />
        )}
        {cloneElement(children, { setNavbarTitle })}
      </div>
    )
  }
  const InfoModalSection = (props) => {
    const dataDetail = [
      { cover: <img src={CoverHAWK} style={{ height: "100%", width: "100%" }} /> },
      { cover: <img src={CoverTwo} style={{ height: "100%", width: "100%" }} /> },
      { cover: <img src={Cover3} style={{ height: "100%", width: "100%" }} /> },
      { cover: <img src={Cover4} style={{ height: "100%", width: "100%" }} /> },
    ]
    return (
      <Modal {...props} size="md" aria-labelledby="contained-modal-title-vcenter" centered style={{ zIndex: 9999999 }}>
        <Modal.Body>
          <Carousel controls={false}>
            {dataDetail?.map(val => (<Carousel.Item>{val?.cover}</Carousel.Item>))}
          </Carousel>
        </Modal.Body>
      </Modal>
    );
  }

  // useEffect(() => {
  //   history.push("/")
  // }, [])
  

  return (
    <div className="layout-wrapper bg-layout">
      <section className="content-wrapper">
        <Navbar />
        <Module />
        <SubModule />
        <CheckFeatures />
        <IsVisitor />
      </section>
      <InfoModalSection show={infoModal} onHide={() => setInfoModal(false)} />
    </div>
  );
};

export default Layout;

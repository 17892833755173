// React
import { Fragment, useState } from "react"

// Components
import { CRUDModalSection, CloseButton, ReadButton } from "components"
import { FormSection } from "./FormSection"

// Form
import { FormProvider, useForm } from "react-hook-form"

// Utils
import { baseData } from "../utils"

export function Detail({ data }) {
  // Hooks
  const [isOpen, setOpen] = useState(false)

  return (
    <Fragment>
      <ReadButton icon onClick={() => setOpen(true)} />
      {isOpen && <Modal data={data} toggle={() => setOpen(false)} />}
    </Fragment>
  )
}

function Modal({ data, toggle }) {
  // Form
  const { control, setValue } = useForm({
    defaultValues: data
  })

  return (
    <CRUDModalSection
      show
      type="READ"
      title={baseData.title}
      onHide={toggle}
    >
      <FormProvider control={control} setValue={setValue}>
        <FormSection isDisabled />
        <CloseButton label="Close" onClick={toggle} />
      </FormProvider>
    </CRUDModalSection>
  )
}
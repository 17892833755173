export const formAddressValues = (data = {}) => ({
    country_id: data?.country_id ?? "",
    country_name: data?.country_name ?? "",
    province_id: data?.province_id ?? "",
    province_name: data?.province_name ?? "",
    regency_id: data?.regency_id ?? "",
    regency_name: data?.regency_name ?? "",
    subdistrict_id: data?.subdistrict_id ?? "",
    subdistrict_name: data?.subdistrict_name ?? "",
    village_id: data?.village_id ?? "",
    village_name: data?.village_name ?? "",
    address: data?.address ?? ""
  });
  
import * as Yup from "yup";

export const formValidationSchema = Yup.object().shape({
    tax_code: Yup.string().required("Tax code is required !"),
    tax_name: Yup.string().required("Tax name is required !"),
    // type_tax_id: Yup.string().required("Type is required !"),
    rate: Yup.string().required("Rate is required !"),
    coa_collect_id: Yup.string().required("COA collect is required !"),
    coa_paid_id: Yup.string().required("COA paid is required !")
});

import Services from "services";

class LocationApi {
  getList(params) {
    return Services.get("/location/", { params });
  }
  dropdownCountries(params) {
    return Services.get("/countries/", { params });
  }
  dropdownProvince(id) {
    return Services.get(`/provinces/?county_id=${id}`);
  }
  dropdownRegencies(id) {
    return Services.get(`/regencies/?province_id=${id}`);
  }
  dropdownSubdistricts(id) {
    return Services.get(`subdistricts/?regency_id=${id}`);
  }
  dropdownVillages(id) {
    return Services.get(`villages/?subdistrict_id=${id}`);
  }
  create(data) {
    return Services.post("/location/add", data);
  }
  update(data, id) {
    return Services.put(`/location/edit?location_id=${id}`, data);
  }
  delete(id) {
    return Services.delete(`/location/delete?location_id=${id}`);
  }
}

export default new LocationApi();

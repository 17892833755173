import * as Yup from "yup";

export const formValidationPersonal = Yup.object().shape({
    employee_code: Yup.string().required("Employee code is required !"),
    employee_name:Yup.string().required("Full Name is required !"),
    account: Yup.string().required("Account is required !"),
    passwd: Yup.string().required("Password is required !"),
    confirm_passwd: Yup.string().required("Re-Password is required !"),
    // Customer_Foto: data?.Customer_Foto ?? "",
});

// React
import { useEffect, useState } from "react"

// API
import Services from "services"

export function useList() {
  // Hooks
  const [data, setData] = useState([])
  const [isError, setError] = useState(false)
  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    refetch()
  
    return () => {
      setData([])
      setError(false)
      setLoading(true)
    }
  }, [])

  const refetch = () => {
    setLoading(true)

    Services.get("/paymenttermvendor/").then(res => {
      setData(res.data.payload)
    }).catch(() => {
      setError(true)
    }).finally(() => {
      setLoading(false)
    })
  }
  
  return { data, isError, isLoading, refetch }
}
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom"
import {
  CRUDLayout,
  UpdateButton,
  DeleteButton,
  Alert,
  THead,
  Tr,
  ThFixed,
  Th,
  TBody,
  TdFixed,
  Td,
  ReadButton,
  TableLayout,
  CRUDModalSection,
  SelectSearch,
  CloseButton,
  DataStatus
} from "components";
import Axios from "axios";
import CreateNewEmployeeApi from "./__CreateNewEmployeeApi__";
import { IoIosWarning } from "react-icons/io";
import { Button } from "react-bootstrap";
import { FaSearch } from "react-icons/fa";

export const CreateNewEmployeeList = () => {
  const menuTitle = "EMPLOYEE REGISTRATION"
  const history = useHistory()

  const [listEmployee, setListEmployee] = useState([]);
  const [dropdownDepartement, setDropdownDepartement] = useState([]);
  const [dropdownJobPosition, setDropdownJobPosition] = useState([]);
  const [dropdownMainLocation, setDropdownMainLocation] = useState([]);
  const [generateCode, setGenerateCode] = useState([]);
  const [searchingData, setSearchingData] = useState({
    departement_id: "",
    job_position_id: "",
    main_location_id: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [modalConfig, setModalConfig] = useState({
    show: false,
    action: "",
    data: {}
  });

  const getData = () => {
    setIsLoading(true);

    Axios.all([
      CreateNewEmployeeApi.dropdownDepartement(),
      CreateNewEmployeeApi.dropdownJobPosition(),
      CreateNewEmployeeApi.dropdownMainLocation(),
      CreateNewEmployeeApi.getList(),
      CreateNewEmployeeApi.generateCode(),
    ])
      .then(Axios.spread((departement, job, loct, list, code) => {
        const getDepartement = departement?.data?.payload ? departement?.data?.payload?.map(val => ({ label: val?.department_name, value: val?.department_id })) : []
        const getJobPosition = job?.data?.payload ? job?.data?.payload?.map(val => ({ label: val?.job_position_name, value: val?.job_position_id })) : []
        const getMainLocation = loct?.data?.payload ? loct?.data?.payload?.map(val => ({ label: val?.location_name, value: val?.location_id })) : []

        setDropdownDepartement(getDepartement)
        setDropdownJobPosition(getJobPosition)
        setDropdownMainLocation(getMainLocation)
        setListEmployee(list?.data?.payload)
        setGenerateCode(code?.data?.payload)
      }))
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };
  const filteringList = (dep, job, loc) => {
    setIsLoading(true)
    CreateNewEmployeeApi.getListByFilter(dep, job, loc)
    .then(list => setListEmployee(list?.data?.payload))
    .catch((err) => console.log(err))
    .finally(() => setIsLoading(false));
  }
  useEffect(() => {
    getData();

    return () => setIsLoading(false)
  }, []);

  const DeleteModal = () => {
    const deleteDataHandler = () => {
      CreateNewEmployeeApi.delete(modalConfig?.data?.employee_id)
        .then(() => setModalConfig({ ...modalConfig, show: true, action: "DELETE_SUCCESS"  }))
        .catch((err) => setModalConfig({ ...modalConfig, show: true, action: "DELETE_ERROR", data: `Delete Error! (${err?.response?.data?.message})` }))
        .finally(() => {
          setModalConfig({ ...modalConfig, show: false, action: "DELETE"  })
          getData();
        });
    };

    return (
      <CRUDModalSection
        type="delete"
        deleteCenter
        title={menuTitle} 
        show={Boolean(modalConfig?.show && modalConfig?.action === "DELETE")}
        onHide={() => setModalConfig({ ...modalConfig, show: false, action: "DELETE"  })}
      >

        <div className="d-flex justify-content-center"><IoIosWarning style={{ fontSize: "150px", color: "#FFB534" }} /></div>
        <Alert textCenter text="Data akan dihapus secara permanen dan tidak dapat dikembalikan !" variant="danger" show={true} />

        <hr className='my-3'/>
        <div className="mt-3 d-flex">
          <CloseButton icon onClick={() => setModalConfig({ ...modalConfig, show: false, action: "UPDATE"  })} />
          <DeleteButton icon forModal label="Delete" onClick={deleteDataHandler} />
        </div>
      </CRUDModalSection>
    );
  };

  const Table = () => {
    return (
      <div className="px-3 pt-3">
        <div className="d-flex align-items-end mb-3">
          <div className="mr-2" style={{ width: 250 }}>
            <SelectSearch
              label="Departement"
              placeholder="Select departement"
              defaultValue={searchingData?.departement_id ? dropdownDepartement?.find(item => item?.value === searchingData?.departement_id) : []}
              option={dropdownDepartement}
              onChange={(val) => setSearchingData({ ...searchingData, departement_id: val?.value })}
              loading={isLoading?.departement}
            />
          </div>
          <div className="mr-2" style={{ width: 250 }}>
            <SelectSearch
              label="Job Position"
              placeholder="Select job position"
              defaultValue={searchingData?.job_position_id ? dropdownJobPosition?.find(item => item?.value === searchingData?.job_position_id) : []}
              option={dropdownJobPosition}
              onChange={(val) => setSearchingData({ ...searchingData, job_position_id: val?.value })}
              loading={isLoading?.job_position}
              isDisabled={!searchingData?.departement_id}
            />
          </div>
          <div className="mr-2" style={{ width: 250 }}>
            <SelectSearch
              label="Main Location"
              placeholder="Select main location"
              defaultValue={searchingData?.main_location_id ? dropdownMainLocation?.find(item => item?.value === searchingData?.main_location_id) : []}
              option={dropdownMainLocation}
              onChange={(val) => setSearchingData({ ...searchingData, main_location_id: val?.value })}
              loading={isLoading?.job_position}
              isDisabled={!searchingData?.job_position_id}
            />
          </div>
          <div className="mr-2 mb-2">
            <Button 
              size="sm"
              className='d-flex align-items-center ml-1 p-2 px-3'
              style={{ backgroundColor: "black", border: "none", borderRadius: "6px" }}
              onClick={() => filteringList(searchingData?.departement_id, searchingData?.job_position_id, searchingData?.main_location_id)}
            >
              {<FaSearch style={{ fontSize: '.9rem' }} />}
            </Button>
          </div>
        </div>
        
        <CRUDLayout.Table>
          <THead>
            <Tr className="text-center">
              <ThFixed>No</ThFixed>
              <Th><div style={{ whiteSpace: "nowrap" }}>ID</div></Th>
              <Th><div style={{ width: 300 }}>NAME</div></Th>
              <Th><div style={{ width: 300 }}>HANDPHONE</div></Th>
              <Th>DEPARTEMENT</Th>
              <Th>DIVISI</Th>
              <Th>JOB POSITION</Th>
              <Th>MAIN LOCATION</Th>
              <ThFixed>ACTION</ThFixed>
            </Tr>
          </THead>
          <TBody>
            {listEmployee?.length < 0 
              ? <Tr><Td colSpan={9}><div className="text-center">Data Not Yet</div></Td></Tr>
              : listEmployee?.map((val, index) => {
                  return (
                    <Tr key={val.id}>
                      <TdFixed>{index + 1}</TdFixed>
                      <Td>{val?.employee_code ?? "-"}</Td>
                      <Td>{val?.employee_name ?? "-"}</Td>
                      <Td>{val?.handphone ?? "-"}</Td>
                      <Td>{val?.department_name ?? "-"}</Td>
                      <Td>{val?.divisi_name ?? "-"}</Td>
                      <Td><div style={{ textAlign: "left" }}>{val?.job_position_name ?? "-"}</div></Td>
                      <Td>{val?.location_name ?? "-"}</Td>
                      <TdFixed >
                        <div className="d-flex">
                          <ReadButton icon onClick={() => history.push("/cards/transactions/create-new-employee/detail", { dataInfo: val })}/>
                          <UpdateButton icon onClick={() => history.push("/cards/transactions/create-new-employee/update", { dataInfo: val })} />
                          <DeleteButton icon onClick={() => setModalConfig({ ...modalConfig, show: true, action: "DELETE", data: val  })} />
                        </div>
                      </TdFixed>
                    </Tr>
                  );
                }
            )}
          </TBody>
        </CRUDLayout.Table>
      </div>
    );
  };

  return (
    <CRUDLayout>
      {isLoading 
        ? <DataStatus frontPage loading={isLoading} text="Loading..." />
        : <TableLayout label={menuTitle} withCreateButton onClick={() => history.push("/cards/transactions/create-new-employee/create", { dataInfo: generateCode })}>
            <Table />
          </TableLayout>
      }
      <DeleteModal />
    </CRUDLayout>
  );
};

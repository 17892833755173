import React from 'react'
import Routes from './routes'
import { AuthContextProvider } from './utilities'
import './styles/css/App.css'
import "react-datepicker/dist/react-datepicker.css"
import 'tailwindcss/tailwind.css';
import { Toaster } from "react-hot-toast"

const App = () => {
  return (
    <AuthContextProvider>
      <Routes />
      <Toaster />
    </AuthContextProvider>
  )
}

export default App
import React from 'react'

const Thead = ({children}) => {
  return (
    <thead style={{ backgroundColor: "#D1D1D1" }}>
      {children}
    </thead>
  )
}

export default Thead

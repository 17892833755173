// React
import { useState } from "react"

export function useToggle() {
  // Hooks
  const [isActive, setActive] = useState(false)

  const toggle = () => setActive(prev => !prev)

  return { isActive, toggle }
}
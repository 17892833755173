import React, { useState } from "react";
import { CRUDLayout, TableLayout, AlertModal } from "components";
import { Formik, Form } from "formik";
import { useHistory, useLocation } from "react-router-dom";
import { Card, Tab, Nav } from "react-bootstrap";
import "./CreateNewEmployee.css"
import CreateNewEmployeeApi from "./__CreateNewEmployeeApi__";
import * as Yup from "yup";
import { 
    FormAddress, 
    FormBank, 
    FormEducation, 
    FormPersonalData, 
    FormPrivilage, 
    FormProfile, 
    FormWorkStatus 
} from "./__CreateNewEmployeeComps__";
import { 
    formPersonalValues, 
    formProfileValues, 
    
    formAddressValues,
    formValidationAddress, 

    // formValidationPersonal, 
    formValidationProfile, 

    formWorkStatusValues,
    formValidationWorkStatus,

    formEducationValues,
    formValidationEducation,

    formBankValues,
    formValidationBank,

    formPrivilageValues,
    formValidationPrivilage
} from "./__CreateNewEmployeeUtillities__";

export const CreateNewEmployeeCreate = () => {
  const menuTitle = "EMPLOYEE REGISTRATION"
  const history = useHistory()
  const location = useLocation()
  const generateCode = location?.state?.dataInfo
  const [fileValue, setFileValue] = useState(null);
  const [employeeId, setEmployeeId] = useState({ id: "" });
  const [alertModal, setAlertModal] = useState({ show: false, action: "", loading: false, type: "" })
  const [statusTab, setStatusTab] = useState({ 
    profile: true,
    address: true,
    work_status: true,
    education: true,
    bank: true,
    access: true,
    more: true
  })
  const [isDisabled, setIsDisabled] = useState({
    personal: false,
    profile: false,
    address: false,
    work_status: false,
    education: false,
    bank: false,
  })

  const personalStyle = { fontSize: "15px", fontFamily: 'poppins', paddingTop: "8.9px", paddingBottom: "8.9px", backgroundColor: "#D1D1D1" }
  const tabHeaderStyle = { borderRadius: "0px", paddingLeft: "20px", paddingRight: "20px" }
  const cardHeaderStyle = { paddingTop: "0px", paddingBottom: "0px", backgroundColor: "#D1D1D1" }

  const formValidationPersonal = Yup.object().shape({
    employee_code: Yup.string().required("Employee code is required !"),
    employee_name:Yup.string().required("Full Name is required !"),
    account: Yup.string()
        .required("Account is required !")
        .test("checkDuplicate", "The account is already in use!", async (value) => await CreateNewEmployeeApi.checkAccount(value, 0).then((res) => res?.data?.status === "Error" ? false : true).catch(() => false)),
    passwd: Yup.string().required("Password is required !"),
    // confirm_passwd: Yup.string().required("Re-Password is required !"),
    confirm_passwd: Yup.string().oneOf([Yup.ref('passwd'), null], 'Re-Password is not match !').required('Re-Password is required !'),
    // Customer_Foto: data?.Customer_Foto ?? "",
  }, [['passwd', 'confirm_passwd']]);
  const submitValidationHandler = (errors) =>
    new Promise((resolve, reject) => {
      const getError = Object.values(errors);

      if (getError.length > 0) {
        reject(getError);
      } else {
        resolve();
      }
  });
  const preSubmitHandler = (formik) => {
        const { values, validateForm, setTouched, setErrors, handleSubmit, setSubmitting } = formik;

        setSubmitting(true);
        validateForm().then(async (err) => {
        setErrors(err);
        setTouched(err);

        await submitValidationHandler(err, values)
            .then(() => handleSubmit())
            .catch((err) => window?.alert(err))
            .finally(() => setSubmitting(false));
        });
  };
  const formSubmitPersonalData = (values) => {

    const formData = new FormData();
    formData.append('employee_code', String(values?.employee_code));
    formData.append('employee_name', String(values?.employee_name));
    formData.append('account', String(values?.account));
    formData.append('passwd', String(values?.passwd));
    formData.append('confirm_passwd', String(values?.confirm_passwd));
    formData.append(fileValue ? 'Customer_Foto' : "", fileValue);

    CreateNewEmployeeApi.createPersonalData(formData)
    .then(res => {
        console.log("res", res)
        const employeeResponse = res?.data?.payload?.[0]?.employee_id
        if (employeeResponse) {
            setEmployeeId({ ...employeeId, id: res?.data?.payload?.[0]?.employee_id })
            sessionStorage.setItem('employee_id', res?.data?.payload?.[0]?.employee_id);
        } else {
            window?.alert(`${res?.data?.message} (the account is already in use)`);
        }
        setStatusTab({ ...statusTab, profile: false })
    })
    .catch((err) => window.alert(`${err} ( profil, name, account, password, re-password is required! )`))
    .finally(() => {
        setAlertModal({ ...alertModal, show: true, action: statusTab?.profile === false ? "success" : "error", loading: false, type: "profil" })
        setIsDisabled({ ...isDisabled, personal: true })
    });
  };
  const idEmployee = sessionStorage.getItem('employee_id');
  console.log("idEmployee", idEmployee)
  console.log("state", employeeId)

  const formSubmitProfile = (values) => {
    const nonEmptyValues = Object.fromEntries(Object.entries(values).filter(([key, value]) => value !== ""));

    CreateNewEmployeeApi.updateProfil(nonEmptyValues, employeeId?.id)
    .then(() => setStatusTab({ ...statusTab, address: false }))
    .catch((err) => window.alert(`${err} ( please enter the input data correctly! )`))
    .finally(() => {
        setAlertModal({ ...alertModal, show: true, action: statusTab?.address === false ? "success" : "error", loading: false, type: "address" })
        setIsDisabled({ ...isDisabled, profile: true })
    });
  };
  const formSubmitAddress = (values) => {
    const nonEmptyValues = Object.fromEntries(Object.entries(values).filter(([key, value]) => value !== ""));

    CreateNewEmployeeApi.updateAdress(nonEmptyValues, employeeId?.id)
    .then(() => setStatusTab({ ...statusTab, work_status: false }))
    .catch((err) => window.alert(`${err} ( please enter the input data correctly! )`))
    .finally(() => {
        setAlertModal({ ...alertModal, show: true, action: statusTab?.work_status === false ? "success" : "error", loading: false, type: "work_status" })
        setIsDisabled({ ...isDisabled, address: true })
    });
  };
  const formSubmitWorkStatus = (values) => {
    const nonEmptyValues = Object.fromEntries(Object.entries(values).filter(([key, value]) => value !== ""));

    CreateNewEmployeeApi.updateWorkStatus(nonEmptyValues, employeeId?.id)
    .then(() => setStatusTab({ ...statusTab, education: false }))
    .catch((err) => window.alert(`${err} ( please enter the input data correctly! )`))
    .finally(() => {
        setAlertModal({ ...alertModal, show: true, action: statusTab?.education === false ? "success" : "error", loading: false, type: "education" })
        setIsDisabled({ ...isDisabled, work_status: true })
    });
  };
  const formSubmitEducation = (values) => {
    const nonEmptyValues = Object.fromEntries(Object.entries(values).filter(([key, value]) => value !== ""));

    CreateNewEmployeeApi.updateEducation(nonEmptyValues, employeeId?.id)
    .then(() => setStatusTab({ ...statusTab, bank: false }))
    .catch((err) => window.alert(`${err} ( please enter the input data correctly! )`))
    .finally(() => {
        setAlertModal({ ...alertModal, show: true, action: statusTab?.bank === false ? "success" : "error", loading: false, type: "bank" })
        setIsDisabled({ ...isDisabled, education: true })
    });
  };
  const formSubmitBank = (values) => {
    const nonEmptyValues = Object.fromEntries(Object.entries(values).filter(([key, value]) => value !== ""));

    CreateNewEmployeeApi.updateBank(nonEmptyValues, employeeId?.id)
    .then(() => setStatusTab({ ...statusTab, access: false }))
    .catch((err) => window.alert(`${err} ( please enter the input data correctly! )`))
    .finally(() => {
        setAlertModal({ ...alertModal, show: true, action: statusTab?.access === false ? "success" : "error", loading: false, type: "access" })
        setIsDisabled({ ...isDisabled, bank: true })
    });
  };
  const formSubmitAccess = (values) => {
    const nonEmptyValues = Object.fromEntries(Object.entries(values).filter(([key, value]) => value !== ""));

    CreateNewEmployeeApi.updateAccess(nonEmptyValues, employeeId?.id)
    .then(() => setAlertModal({ ...alertModal, show: true, action: "success" }))
    .catch((err) =>  setAlertModal({ ...alertModal, show: true, action: "error" }))
    .finally(() => setAlertModal({ ...alertModal, show: true, action: "success" }));
  };

  return (
    <CRUDLayout>
        <TableLayout create label={menuTitle} withBackButton onClick={() => history.goBack()}>
            <div className="d-flex p-1">
                <Card style={{ width: "30%", borderRadius: "0px" }}>
                    <Card.Header style={personalStyle}>PERSONAL DATA</Card.Header>
                    <Card.Body>
                        <Formik 
                            enableReinitialize 
                            initialValues={formPersonalValues(generateCode?.empcode)} 
                            validationSchema={formValidationPersonal} 
                            onSubmit={(values) => formSubmitPersonalData(values)}
                        >
                            {(formik) => ( <Form><FormPersonalData setFileValue={setFileValue} loading={alertModal?.loading} isDisabled={isDisabled?.personal}  /></Form> )}
                        </Formik>
                    </Card.Body>
                </Card>
                <Card style={{ width: "70%", borderRadius: "0px" }}>
                    <Tab.Container id="left-tabs-example">
                        <Card.Header style={cardHeaderStyle}>
                            <Nav variant="pills" className="d-flex justify-content-start">
                                <Nav.Item>
                                    <Nav.Link style={tabHeaderStyle} disabled={statusTab?.profile} eventKey="profile">PROFILE</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link style={tabHeaderStyle} disabled={statusTab?.profile} eventKey="address">ADDRESS</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link style={tabHeaderStyle} disabled={statusTab?.profile} eventKey="work_status">WORK STATUS</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link style={tabHeaderStyle} disabled={statusTab?.profile} eventKey="education">EDUCATION</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link style={tabHeaderStyle} disabled={statusTab?.profile} eventKey="bank">BANK</Nav.Link>
                                </Nav.Item>
                                {/* <Nav.Item>
                                    <Nav.Link style={tabHeaderStyle} eventKey="privilege">PRIVILEGE</Nav.Link>
                                </Nav.Item> */}
                            </Nav>
                        </Card.Header>
                        <Card.Body>
                            <Tab.Content>
                                <Tab.Pane eventKey="profile">
                                    <Formik 
                                        enableReinitialize 
                                        initialValues={formProfileValues({})} 
                                        // validationSchema={formValidationProfile} 
                                        onSubmit={(values) => formSubmitProfile(values)}
                                    >
                                        {(formik) => ( <Form><FormProfile onSubmit={() => preSubmitHandler(formik)} isDisabled={isDisabled?.profile} /></Form> )}
                                    </Formik>
                                </Tab.Pane>
                                <Tab.Pane eventKey="address">
                                    <Formik 
                                        enableReinitialize 
                                        initialValues={formAddressValues({})} 
                                        // validationSchema={formValidationAddress} 
                                        onSubmit={(values) => formSubmitAddress(values)}
                                    >
                                        {(formik) => ( <Form><FormAddress action="CREATE" isDisabled={isDisabled?.address} /></Form> )}
                                    </Formik>
                                </Tab.Pane>
                                <Tab.Pane eventKey="work_status">
                                    <Formik 
                                        enableReinitialize 
                                        initialValues={formWorkStatusValues({})} 
                                        // validationSchema={formValidationWorkStatus} 
                                        onSubmit={(values) => formSubmitWorkStatus(values)}
                                    >
                                        {(formik) => ( <Form><FormWorkStatus onSubmit={() => preSubmitHandler(formik)} isDisabled={isDisabled?.work_status} /></Form> )}
                                    </Formik>
                                </Tab.Pane>
                                <Tab.Pane eventKey="education">
                                    <Formik 
                                        enableReinitialize 
                                        initialValues={formEducationValues({})} 
                                        // validationSchema={formValidationEducation} 
                                        onSubmit={(values) => formSubmitEducation(values)}
                                    >
                                        {(formik) => ( <Form><FormEducation onSubmit={() => preSubmitHandler(formik)} isDisabled={isDisabled?.education} /></Form> )}
                                    </Formik>
                                </Tab.Pane>
                                <Tab.Pane eventKey="bank">
                                    <Formik 
                                        enableReinitialize
                                        initialValues={formBankValues({})} 
                                        // validationSchema={formValidationBank} 
                                        onSubmit={(values) => formSubmitBank(values)}
                                    >
                                        {(formik) => ( <Form><FormBank onSubmit={() => preSubmitHandler(formik)} isDisabled={isDisabled?.bank} /></Form> )}
                                    </Formik>
                                </Tab.Pane>
                                <Tab.Pane eventKey="privilege">
                                    <Formik 
                                        enableReinitialize 
                                        initialValues={formPrivilageValues({})} 
                                        // validationSchema={formValidationPrivilage} 
                                        onSubmit={(values) => formSubmitAccess(values)}
                                    >
                                        {(formik) => ( <Form><FormPrivilage onSubmit={() => preSubmitHandler(formik)}  /></Form> )}
                                    </Formik>
                                </Tab.Pane>
                            </Tab.Content>
                        </Card.Body>
                    </Tab.Container>
                </Card>
            </div>
        </TableLayout>
        
        <AlertModal action={statusTab?.profile === false ? "success" : "error"} show={alertModal?.show && alertModal?.type === "profil"} onHide={() => setAlertModal({ ...alertModal, show: false })} />
        <AlertModal action={statusTab?.address === false ? "success" : "error"} show={alertModal?.show && alertModal?.type === "address"} onHide={() => setAlertModal({ ...alertModal, show: false })} />
        <AlertModal action={statusTab?.work_status === false ? "success" : "error"} show={alertModal?.show && alertModal?.type === "work_status"} onHide={() => setAlertModal({ ...alertModal, show: false })} />
        <AlertModal action={statusTab?.education === false ? "success" : "error"} show={alertModal?.show && alertModal?.type === "education"} onHide={() => setAlertModal({ ...alertModal, show: false })} />
        <AlertModal action={statusTab?.bank === false ? "success" : "error"} show={alertModal?.show && alertModal?.type === "bank"} onHide={() => setAlertModal({ ...alertModal, show: false })} />
        <AlertModal action={statusTab?.access === false ? "success" : "error"} show={alertModal?.show && alertModal?.type === "access"} onHide={() => setAlertModal({ ...alertModal, show: false })} />
    </CRUDLayout>
  );
};
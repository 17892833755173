export const formInitialValues = (data = {}) => ({
    location_name: data?.location_name ?? "",
    address: data?.address ?? "",
    description: data?.description ?? "",
    country_id: data?.country_id ?? "",
    province_id: data?.province_id ?? "",
    province_name: data?.province_name ?? "",
    regency_id: data?.regency_id ?? "",
    regency_name: data?.regency_name ?? "",
    subdistrict_id: data?.subdistrict_id ?? "",
    subdistrict_name: data?.subdistrict_name ?? "",
    village_id: data?.village_id ?? "",
    village_name: data?.village_name ?? ""
  });
  
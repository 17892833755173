import React, { useState } from "react";
import { useFormikContext } from "formik";
import { Input, SaveButton } from "components";
import "../../CreateNewEmployee/ImageUpload.css"

export const FormPersonalData = ({ action, isDisabled, setFileValue }) => {
  const { values, errors, touched, setValues, isSubmitting } = useFormikContext() ?? {}
  const [imageViews, setImageView] = useState({ file: null, view: null })
  const { REACT_APP_API_BASE_URL } = process.env

  const ImageProfile = () => (
    <div className="custom-file-upload fas">
      <div className="img-wrap" >
        <img 
          className="uploadFoto" 
          for="photo-upload" 
          src={imageViews?.view 
            ? imageViews?.view 
            : values?.Customer_Foto
            ? `${REACT_APP_API_BASE_URL}/getimage/?imgpath=${values?.Customer_Foto}`
            : "https://www.zedemy.com/wp-content/plugins/buddyboss-platform/bp-core/images/profile-avatar-buddyboss.png"
          }
        />
      </div>
    </div>
  )

  return (
    <>
      <div className="d-flex justify-content-center my-2"><ImageProfile /></div>
      <input 
        type="file" 
        onChange={(e) => {
          setFileValue(e.target.files[0])
          const selectedFile = e.target.files[0];

          if (selectedFile) {
            setImageView({ ...imageViews, file: selectedFile});
            const reader = new FileReader();
            reader.onloadend = () => {setImageView({ ...imageViews, view: reader.result})};
            reader.readAsDataURL(selectedFile);
          } else {
            setImageView({ ...imageViews, file: null, view: null});
          }
        }}
        disabled={action === "READ"}
      />
      <Input
        label="Id Customer"
        type="text"
        placeholder={action !== "READ" && "Input id customer"}
        value={values?.Customer_code}
        onChange={(e) => setValues({ ...values, Customer_code: e?.target?.value })}
        error={Boolean(errors?.Customer_code && touched?.Customer_code)}
        errorText={Boolean(errors?.Customer_code && touched?.Customer_code) && errors?.Customer_code}
        readOnly
      />
      <Input
        label="Full Name *"
        type="text"
        placeholder={action !== "READ" && "Input full name"}
        value={values?.Customer_name}
        onChange={(e) => setValues({ ...values, Customer_name: e?.target?.value })}
        error={Boolean(errors?.Customer_name && touched?.Customer_name)}
        errorText={Boolean(errors?.Customer_name && touched?.Customer_name) && errors?.Customer_name}
        readOnly={action === "READ"}
      />
      {action !== "READ" && <div className="d-flex justify-content-end mt-3">
        <SaveButton icon forModal type="submit" disabled={isDisabled} />
      </div>}
    </>
  );
};

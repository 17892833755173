import { Fragment, useRef, useEffect } from "react"
import { Input, TextArea, SelectSearch } from "components"
import { Controller, useFormContext, useWatch } from "react-hook-form"
import { useAccountGroup, useAccountType } from "./utils"
import { Row, Col } from "react-bootstrap";
import { CheckList } from "../CheckList";

export function FormSection({ isDisabled, action }) {
  const { control } = useFormContext()

  return (
    <Fragment>
      <Row>
        <Col>
          <AccountGroup isDisabled={isDisabled} />
          <AccountType isDisabled={action === "PARENT" ? false : true} />
          <Controller
            control={control}
            name="coa_level"
            render={({ field }) => ( <Input {...field} label="Level" readOnly /> )}
          />
          <small style={{ fontFamily: "poppins medium" }}>Account Number</small>
          <div className="d-flex">
            <div className="mr-2" style={{ width: 150 }}>
              <Controller
                control={control}
                name="coa_number"
                render={({ field }) => ( <Input {...field} readOnly /> )}
              />
            </div>
            <div style={{ width: "100%" }}>
              <Controller
                control={control}
                name="coa_code"
                render={({ field, fieldState }) => (
                  <Input
                    {...field}
                    type="number"
                    placeholder="Input account number"
                    error={fieldState.error}
                    errorText={fieldState.error?.message}
                    readOnly={isDisabled}
                  />
                )}
              />
            </div>
          </div>
          <NormalPost isDisabled={isDisabled} />
          <Controller
            control={control}
            name="note"
            render={({ field, fieldState }) => (
              <TextArea
                {...field}
                label="NOTED"
                error={fieldState.error}
                errorText={fieldState.error?.message}
                disabled={isDisabled}
              />
            )}
          />
        </Col>
        <Col>
          <Controller
            control={control}
            name="report_type"
            render={({ field }) => ( <Input {...field} label="Report Type" readOnly /> )}
          />
          <Controller
            control={control}
            name="parent_id"
            render={({ field }) => ( 
              <Input 
                {...field} 
                label="Parent"
                // value={modalConfig?.action === "CREATE_PARENT" ? `${values?.coa_number}-${values?.coa_code}` : values.parent_id}
                readOnly 
              />
            )}
          />
          <HeaderLevel isDisabled={true} />
          <Controller
            control={control}
            name="coa_name"
            render={({ field, fieldState }) => (
              <Input
                {...field}
                label="Account Name"
                placeholder="Input account name"
                error={fieldState.error}
                errorText={fieldState.error?.message}
                readOnly={isDisabled}
              />
            )}
          />
          <Controller
            control={control}
            name="opening_balance"
            render={({ field, fieldState }) => (
              <Input
                {...field}
                label="Opening Balance"
                error={fieldState.error}
                errorText={fieldState.error?.message}
                readOnly
              />
            )}
          />
          <Controller
            control={control}
            name="cheque_coa"
            render={({ field }) => (
              <CheckList 
                {...field}
                label="Cheque Account"
                onChange={item => field.onChange(item.target.checked)}
                checked={control?.cheque_coa} 
              />
            )}
          />
          <Controller
            control={control}
            name="budget_coa"
            render={({ field }) => (
              <CheckList
                {...field}
                label="Link To Bugeting"
                // checked={values?.budget_coa} 
                // onChange={(e) => setValues({ ...values, budget_coa: e.target.checked })} 
              />
            )}
          />
        </Col>
      </Row>
    </Fragment>
  )
}

function AccountGroup({ isDisabled }) {
  const { data, isLoading } = useAccountGroup()
  const { control } = useFormContext()

  return (
    <Controller
      control={control}
      name="group_coa_id"
      render={({ field, fieldState }) => (
        <SelectSearch
          label="Account Group"
          placeholder="Select account group"
          defaultValue={data.find(item => item.value === field.value)}
          loading={isLoading}
          error={fieldState.error}
          errorText={fieldState.error?.message}
          isDisabled={isDisabled}
          option={data}
          onChange={item => {
            field.onChange({
              group_coa_id: item?.value, 
              coa_number: item?.code,
              report_type: item?.type === 1 ? "BALANCE SHEET" : "PROFIT AND LOST",
              header_level: item?.coa_level
            })
          }}
        />
      )}
    />
  )
}
function AccountType({ isDisabled }) {
  const ref = useRef(false)
  const { control, setValue } = useFormContext()
  const account_group_id = useWatch({ control, name: "group_coa_id" }) 
  const { data, isLoading, refetch } = useAccountType(account_group_id)

  useEffect(() => {
    refetch()

    if (!ref.current) {
      ref.current = true
    } else {
      setValue("account_type_id", "")
    }
  }, [account_group_id])
  

  return (
    <Controller
      control={control}
      name="account_type_id"
      render={({ field, fieldState }) => (
        <SelectSearch
          label="Account Type" 
          placeholder="Select account type"
          defaultValue={data.find(item => item.value === field.value)}
          loading={isLoading}
          error={fieldState.error}
          errorText={fieldState.error?.message}
          option={data}
          isDisabled={isDisabled || !account_group_id}
          onChange={item => field.onChange(item.value)}
        />
      )}
    />
  )
}
function HeaderLevel({ isDisabled }) {
  const data = [
    { label: "Header", value: "1" },
    { label: "Sub Header", value: "2" },
    { label: "Detail", value: "3" },
  ]
  const { control } = useFormContext()

  return (
    <Controller
      control={control}
      name="header_level"
      render={({ field, fieldState }) => (
        <SelectSearch
          label="Header Level"
          placeholder="Select header level"
          defaultValue={data.find(item => item.value === field.value)}
          error={fieldState.error}
          errorText={fieldState.error?.message}
          isDisabled={isDisabled}
          option={data}
          onChange={item => field.onChange(item.value)}
        />
      )}
    />
  )
}
function NormalPost({ isDisabled }) {
  const data = [
    { label: "Debet", value: "1" },
    { label: "Kredit", value: "2" },
  ]
  const { control } = useFormContext()

  return (
    <Controller
      control={control}
      name="normal_pos"
      render={({ field, fieldState }) => (
        <SelectSearch
          label="Normal Post"
          placeholder="Select normal post"
          defaultValue={data.find(item => item.value === field.value)}
          error={fieldState.error}
          errorText={fieldState.error?.message}
          isDisabled={isDisabled}
          option={data}
          onChange={item => field.onChange(item.value)}
        />
      )}
    />
  )
}
import { useEffect, useState } from "react"
import Services from "services"

export function useAccountType(id) {
  const [data, setData] = useState([])
  const [isLoading, setLoading] = useState(false)

  useEffect(() => {
    refetch()
  
    return () => {
      setData([])
      setLoading(false)
    }
  }, [])

  const refetch = () => {
    setLoading(true)

    Services.get(`/acctype/byidgroup?groupid=${id}`).then(res => {
      setData(res.data.payload.map(item => {
        return {
          label: item?.account_type_name, 
          value: item?.account_type_id
        }
      }))
    }).catch(() => {
      setData([])
    }).finally(() => {
      setLoading(false)
    })
  }
  
  return { data, isLoading, refetch }
}
import React from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { BiCommentDetail } from "react-icons/bi";

export const ReadButton = ({ onClick, tooltipText, tooltipPlacement, icon, forModal }) => {
  return (
    <OverlayTrigger
      placement={tooltipPlacement}
      overlay={<Tooltip>{tooltipText ? tooltipText : 'Detail Data'}</Tooltip>}
    >
      <Button 
        size={ !forModal && "sm" }
        className="d-flex align-items-center"
        style={{ backgroundColor: "transparent", borderRight: "100%", borderColor: "black" }}
        onClick={onClick}
      >
        {icon && <BiCommentDetail className="text-black" />}
      </Button>
    </OverlayTrigger>
  );
};

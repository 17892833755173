import React, { useState, useEffect } from "react";
import { CRUDLayout, TableLayout, DataStatus } from "components";
import { Formik } from "formik";
import { useHistory, useLocation } from "react-router-dom";
import { Card, Tab, Nav } from "react-bootstrap";
import "./CreateNewCustomer.css"
import Axios from "axios"
import { FormPersonalData, FormProfile, FormAddress, FormMap } from "./__CreateNewCustomerComps__";
import { formPersonalValues, formProfileValues, formAddressValues, formMapValues } from "./__CreateNewCustomerUtillities__"; 
import CreateNewCustomerApi from "./__CreateNewCustomerApi__";

export const CreateNewCustomerDetail = () => {
  const menuTitle = "CUSTOMER REGISTRATION"
  const history = useHistory()
  const location = useLocation()
  const dataInfo = location?.state?.dataInfo
  console.log("data", dataInfo)

  const [profileList, setProfileList] = useState([])
  const [addressList, setAddressList] = useState([])
  const [mapList, setMapList] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const getData = () => {
    setIsLoading(true);

    Axios.all([
      CreateNewCustomerApi.getProfile(dataInfo?.customer_id),
      CreateNewCustomerApi.getAddress(dataInfo?.customer_id),
      CreateNewCustomerApi.getMap(dataInfo?.customer_id),
    ])
      .then(Axios.spread((profile, address, map) => {
        setProfileList(profile?.data?.payload)
        setAddressList(address?.data?.payload)
        setMapList(map?.data?.payload)
      }))
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };
  useEffect(() => {
    getData();

    return () => setIsLoading(false)
  }, []);

  const personalStyle = { fontSize: "15px", fontFamily: 'poppins', paddingTop: "8.9px", paddingBottom: "8.9px", backgroundColor: "#D1D1D1" }
  const tabHeaderStyle = { borderRadius: "0px", paddingLeft: "20px", paddingRight: "20px" }
  const cardHeaderStyle = { paddingTop: "0px", paddingBottom: "0px", backgroundColor: "#D1D1D1" }

  return (
    <CRUDLayout>
        <TableLayout create label={menuTitle} withBackButton onClick={() => history.goBack()}>
            {isLoading
                ? <DataStatus loading={isLoading} text="Loading..." />
                : <div className="d-flex p-1">
                    <Card style={{ width: "30%", borderRadius: "0px" }}>
                        <Card.Header style={personalStyle}>PERSONAL DATA</Card.Header>
                        <Card.Body>
                            <Formik enableReinitialize initialValues={formPersonalValues(dataInfo)}>
                                <FormPersonalData action="READ"  />
                            </Formik>
                        </Card.Body>
                    </Card>
                    <Card style={{ width: "70%", borderRadius: "0px" }}>
                        <Tab.Container id="left-tabs-example" defaultActiveKey="profile">
                            <Card.Header style={cardHeaderStyle}>
                                <Nav variant="pills" className="d-flex justify-content-start">
                                    <Nav.Item>
                                        <Nav.Link style={tabHeaderStyle} eventKey="profile">PROFILE</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link style={tabHeaderStyle} eventKey="address">ADDRESS</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link style={tabHeaderStyle} eventKey="map">MAP</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </Card.Header>
                            <Card.Body>
                                <Tab.Content>
                                    <Tab.Pane eventKey="profile">
                                        <Formik enableReinitialize initialValues={formProfileValues(profileList[0])}>
                                            <FormProfile action="READ"  />
                                        </Formik>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="address">
                                        <Formik enableReinitialize initialValues={formAddressValues(addressList[0])}>
                                            <FormAddress action="READ"  />
                                        </Formik>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="map">
                                        <Formik enableReinitialize initialValues={formMapValues(mapList)}>
                                            <FormMap action="READ"  />
                                        </Formik>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Card.Body>
                        </Tab.Container>
                    </Card>
                  </div>
            }
        </TableLayout>
    </CRUDLayout>
  );
};

import { useEffect, useState } from "react"
import Services from "services"

export function useList() {
  const [data, setData] = useState([])
  const [isError, setError] = useState(false)
  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    Services.get("/coa/")
      .then(res => { setData(res.data.payload) })
      .catch(() => { setError(true) })
      .finally(() => { setLoading(false) })
  
    return () => {
      setData([])
      setError(false)
      setLoading(true)
    }
  }, [])
  
  return { data, isError, isLoading }
}
// import Services from "../../services";

// class AuthApi {
//   login(value) {
//     return Services.post("/auth/login", value);
//   }

//   notif(params) {
//     return Services.get("/notifikasi/counter_notif", { params });
//   }

//   profile() {
//     return Services.get("/auth/profile");
//   }
// }

// export default new AuthApi();

import Services from "../../services";
// import LoginServices from "services/LoginServices";

class AuthApi {
  login(value) {
    return Services.post("/companies/login", value);
  }

  register(value) {
    return Services.post("/companies/register", value);
  }

  dropdopwnTypeindistry(id) {
    return Services.get(`/typeindustry/?clasification_industry_id=${id}`);
  }

  notif(params) {
    return Services.get("/notifikasi/counter_notif", { params });
  }

  profile() {
    return Services.get("/auth/profile");
  }
}

export default new AuthApi();


import React from 'react'
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { BsTrash } from 'react-icons/bs'

export const DeleteButton = ({ onClick, tooltipText, tooltipPlacement, icon, label, width, noMargin, forModal }) => {
  return (
    <OverlayTrigger
      placement={tooltipPlacement}
      overlay={<Tooltip>{tooltipText ? tooltipText : 'Delete Data'}</Tooltip>}
    >
      <Button 
        size={ !forModal && "sm" }
        className={`d-flex align-items-center ${noMargin ? "" : "mx-1"}`}
        style={{ backgroundColor: forModal ? "black" : "transparent", borderRight: "100%", borderColor: "black", width: width ? width : "" }}
        onClick={onClick}
      >
        {icon && <BsTrash className={`${!forModal && "text-black"} ${label ? "mr-1" : ""}`} />}
        <div className={`${noMargin ? "mx-auto" : ""}`}>{label ? label : ""}</div>
      </Button>
    </OverlayTrigger>
  )
}

import React, { useState, useEffect } from "react";
import {
  CRUDLayout,
  DataStatus,
  Input,
  SaveButton,
  Alert,
  THead,
  Tr,
  DeleteButton,
  Th,
  TBody,
  TdFixed,
  Td,
  SelectSearch,
  TableLayout,
  TextArea,
  Checkbox,
  InputLink,
  UploadProfile
} from "components";
import { Formik } from "formik";
import * as Yup from "yup";
import Axios from "axios";
// import CreateNewEmployeeApi from "./__CreateNewEmployeeApi__";
import { PageNumber } from "utilities";
import User from "assets/image/User.png"
import { useHistory } from "react-router-dom";
import { Card, Tab, Nav, Button, Row, Col } from "react-bootstrap";
import { FaPlus } from "react-icons/fa";
import "./SpendMoney.css"
import { DropdownSelect } from "components/Select";

export const SpendMoneyCreate = ({ setNavbarTitle }) => {
  const menuTitle = "SPEND MONEY"
  const history = useHistory()
  const [searchKey, setSearchKey] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isSearching, setIsSearching] = useState(false);
  const [isDeleteData, setIsDeleteData] = useState(false);

  const [data, setData] = useState([]);
  const [dummy, setDummy] = useState([{ id: 1, name: "test" }])

  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [dataLength, setDataLength] = useState(10);
  const [totalData, setTotalData] = useState("");
  
  const [deleteData, setDeleteData] = useState([]);

  const [showAlert, setShowAlert] = useState(false);
  const [alertConfig, setAlertConfig] = useState({ variant: "primary", text: "" });

  const personalStyle = { fontSize: "15px", fontFamily: 'poppins', paddingTop: "8.9px", paddingBottom: "8.9px", backgroundColor: "#D1D1D1" }
  const tabHeaderStyle = { borderRadius: "0px", paddingLeft: "20px", paddingRight: "20px" }
  const cardHeaderStyle = { paddingTop: "0px", paddingBottom: "0px", backgroundColor: "#D1D1D1" }

//   const getData = () => {
//     setIsLoading(true);
//     setIsSearching(false);

//     // Axios.all([CreateNewEmployeeApi.getPage(page, dataLength, searchKey)])
//     //   .then(
//     //     Axios.spread((res) => {
//     //       setData(res.data.data);
//     //       setTotalPage(res.data.total_page);
//     //       setTotalData(res.data.data_count);
//     //     })
//     //   )
//     //   .catch((err) => alert(err))
//     //   .finally(() => {
//     //     if (searchKey != "") {
//     //       setAlertConfig({
//     //         variant: "primary",
//     //         text: `Hasil Pencarian : ${searchKey}`,
//     //       });
//     //       setShowAlert(true);
//     //     }
//     //     setIsLoading(false);
//     //   });
//   };

  useEffect(() => {
    setNavbarTitle("BUASO");
    // getData();

    return () => {
      setIsLoading(false);
      setIsSearching(false);
    };
  }, [setNavbarTitle, page, dataLength, searchKey]);

  const formValidationSchema = Yup.object().shape({
    kode_buaso: Yup.number().required("Masukkan kode").typeError("Kode hanya dapat berupa angka"),
    nama_buaso: Yup.string().required("Masukkan nama buaso"),
    // keterangan_buaso: Yup.string().required("Masukkan keterangan"),
  });

  const Table = () => {
    return (
      <div className="pt-3">
        <CRUDLayout.Table>
          <THead>
            <Tr className="text-center">
              <Th rowSpan={2}>No</Th>
              <Th rowSpan={2}>MODULE</Th>
              <Th rowSpan={2}>MENU</Th>
              <Th rowSpan={2}>FEATURE</Th>
              <Th colSpan={4}>ROLE</Th>
              <Th rowSpan={2} width={80}>ACTION</Th>
            </Tr>
            <Tr>
              <Th>CREATE</Th>
              <Th>READ</Th>
              <Th>UPDATE</Th>
              <Th>DELETE</Th>
            </Tr>
          </THead>
          <TBody>
            {dummy?.length < 0 
              ? <Tr><Td className="text-center" colSpan={9}>Not data yet</Td></Tr> 
              : dummy?.map((val, index) => {
                  return (
                    <Tr key={val.id}>
                      <TdFixed>{PageNumber(page, dataLength, index)}</TdFixed>
                      <TdFixed>{val.name ?? "01"}</TdFixed>
                      <TdFixed>{val.name ?? "Group"}</TdFixed>
                      <TdFixed>{val.name ?? "Group"}</TdFixed>
                      <Td className="text-center"><Checkbox /></Td>
                      <Td className="text-center"><Checkbox /></Td>
                      <Td className="text-center"><Checkbox /></Td>
                      <Td className="text-center"><Checkbox /></Td>
                      <TdFixed>
                          <DeleteButton
                            width="100%"
                            noMargin
                            label="Delete"
                            onClick={() => {
                                setDeleteData(val);
                                setIsDeleteData(true);
                            }} 
                          />
                      </TdFixed>
                    </Tr>
                  );
            })}
          </TBody>
        </CRUDLayout.Table>
      </div>
    );
  };

  return (
    <CRUDLayout>
      <Alert
        show={showAlert}
        showCloseButton={true}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() => setShowAlert(false)}
      />

      {/* {isLoading 
        ? <DataStatus loading={isLoading} text="Memuat Data" />
        : !Array.isArray(data) 
        ? <DataStatus text="Tidak dapat memuat data" />
        : data.length > 0  */}
        {/* // ? <TableLayout create label={menuTitle} withCreateButton onClick={() => history.goBack()}> */}
        <TableLayout create label={menuTitle} withBackButton onClick={() => history.goBack()}>
            {/* <Formik initialValues={formInitialValues} validationSchema={formValidationSchema} onSubmit={formSubmitHandler}> */}
            <Formik>
                {({ values, handleSubmit, setFieldValue, errors, touched, handleChange }) => (
                    <form onSubmit={handleSubmit}>
                        <div className="d-flex p-1">
                            <Card style={{ width: "30%", borderRadius: "0px" }}>
                                <Card.Header style={personalStyle}>PERSONAL DATA</Card.Header>
                                <Card.Body>
                                    <div className="text-center my-4">
                                        <UploadProfile 
                                            name="avatar"
                                        />
                                    </div>
                                    <Input
                                        label="Id Customer"
                                        type="text"
                                        name="id_employee"
                                        placeholder="Input id employee"
                                        value={values?.id_employee}
                                        onChange={handleChange}
                                        error={errors.id_employee && touched.id_employee && true}
                                        errorText={errors.id_employee}
                                        readOnly
                                    />
                                    <Input
                                        label="Full Name *"
                                        type="text"
                                        name="full_name"
                                        placeholder="Input full name"
                                        value={values?.full_name}
                                        onChange={handleChange}
                                        error={errors.full_name && touched.full_name && true}
                                        errorText={errors.full_name}
                                    />
                                    <div className="d-flex justify-content-end mt-3">
                                        <SaveButton icon label="Save" />
                                    </div>
                                </Card.Body>
                            </Card>
                            <Card style={{ width: "70%", borderRadius: "0px" }}>
                                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                                    <Card.Header style={cardHeaderStyle}>
                                        <Nav variant="pills" className="d-flex justify-content-start">
                                            <Nav.Item>
                                                <Nav.Link style={tabHeaderStyle} eventKey="profile">PROFILE</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link style={tabHeaderStyle} eventKey="address">ADDRESS</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link style={tabHeaderStyle} eventKey="map">MAP</Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                    </Card.Header>
                                    <Card.Body>
                                        <Tab.Content>
                                            <Tab.Pane eventKey="profile">
                                                <Row>
                                                    <Col>
                                                        <Input
                                                            label="Handphone"
                                                            name="handphone"
                                                            type="text"
                                                            placeholder="Input handphone"
                                                            value={values?.handphone}
                                                            onChange={(val) => setFieldValue("handphone", val.value)}
                                                            error={errors.handphone && touched.handphone && true}
                                                            errorText={errors.handphone && touched.handphone && true}
                                                        />
                                                        <Input
                                                            label="Email"
                                                            name="emal"
                                                            type="text"
                                                            placeholder="Input emal"
                                                            value={values?.emal}
                                                            onChange={(val) => setFieldValue("emal", val.value)}
                                                            error={errors.emal && touched.emal && true}
                                                            errorText={errors.emal && touched.emal && true}
                                                        />
                                                        <InputLink
                                                            label="Website"
                                                            placeholder="Input website"
                                                            // defaultValue={values?.website ? values?.website : ""}
                                                            // onChange={(val) => setFieldValue("website", val.value)}
                                                            // error={errors.website && touched.website && true}
                                                            // errorText={errors.website && touched.website && true}
                                                        />
                                                        <SelectSearch
                                                            label="Card Label"
                                                            name="type_of_nasional_ity_id"
                                                            placeholder="Select type of nasional ity id"
                                                            onChange={(val) => setFieldValue("type_of_nasional_ity_id", val.value)}
                                                            error={errors.type_of_nasional_ity_id && touched.type_of_nasional_ity_id && true}
                                                            errorText={errors.type_of_nasional_ity_id && touched.type_of_nasional_ity_id && true}
                                                        />
                                                        {/* <DropdownSelect 
                                                            label="Card Label" 
                                                            placeholder="Select type of nasional ity id" 
                                                            options={[
                                                                { value: 'jack', label: 'Jack' },
                                                                { value: 'lucy', label: 'Lucy' },
                                                                { value: 'tom', label: 'Tom' },
                                                            ]}
                                                        /> */}
                                                        <SelectSearch
                                                            label="Group"
                                                            name="type_of_nasional_ity_id"
                                                            placeholder="Select type of nasional ity id"
                                                            onChange={(val) => setFieldValue("type_of_nasional_ity_id", val.value)}
                                                            error={errors.type_of_nasional_ity_id && touched.type_of_nasional_ity_id && true}
                                                            errorText={errors.type_of_nasional_ity_id && touched.type_of_nasional_ity_id && true}
                                                        />
                                                        <SelectSearch
                                                            label="Category"
                                                            name="type_of_nasional_ity_id"
                                                            placeholder="Select type of nasional ity id"
                                                            onChange={(val) => setFieldValue("type_of_nasional_ity_id", val.value)}
                                                            error={errors.type_of_nasional_ity_id && touched.type_of_nasional_ity_id && true}
                                                            errorText={errors.type_of_nasional_ity_id && touched.type_of_nasional_ity_id && true}
                                                        />
                                                        <Input
                                                            label="Source From"
                                                            name="emal"
                                                            type="text"
                                                            placeholder="Input emal"
                                                            value={values?.emal}
                                                            onChange={(val) => setFieldValue("emal", val.value)}
                                                            error={errors.emal && touched.emal && true}
                                                            errorText={errors.emal && touched.emal && true}
                                                        />
                                                        <SelectSearch
                                                            label="Marketing"
                                                            name="type_of_nasional_ity_id"
                                                            placeholder="Select type of nasional ity id"
                                                            onChange={(val) => setFieldValue("type_of_nasional_ity_id", val.value)}
                                                            error={errors.type_of_nasional_ity_id && touched.type_of_nasional_ity_id && true}
                                                            errorText={errors.type_of_nasional_ity_id && touched.type_of_nasional_ity_id && true}
                                                        />
                                                        <Input
                                                            label="Media"
                                                            name="emal"
                                                            type="text"
                                                            placeholder="Input emal"
                                                            value={values?.emal}
                                                            onChange={(val) => setFieldValue("emal", val.value)}
                                                            error={errors.emal && touched.emal && true}
                                                            errorText={errors.emal && touched.emal && true}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Input
                                                            label="Price Level"
                                                            name="nama_of_nasional_ity_id"
                                                            type="text"
                                                            placeholder="Input nomor of nasional ity id"
                                                            value={values?.nama_of_nasional_ity_id}
                                                            onChange={(val) => setFieldValue("nama_of_nasional_ity_id", val.value)}
                                                            error={errors.nama_of_nasional_ity_id && touched.nama_of_nasional_ity_id && true}
                                                            errorText={errors.nama_of_nasional_ity_id && touched.nama_of_nasional_ity_id && true}
                                                        />
                                                        <Input
                                                            label="Payment Term"
                                                            name="date_of_birth"
                                                            type="text"
                                                            placeholder="Input date of birth"
                                                            value={values?.date_of_birth}
                                                            onChange={(val) => setFieldValue("date_of_birth", val.value)}
                                                            error={errors.date_of_birth && touched.date_of_birth && true}
                                                            errorText={errors.date_of_birth && touched.date_of_birth && true}
                                                        />
                                                        <Input
                                                            label="Max Balance"
                                                            name="date_of_birth"
                                                            type="text"
                                                            placeholder="Input date of birth"
                                                            value={values?.date_of_birth}
                                                            onChange={(val) => setFieldValue("date_of_birth", val.value)}
                                                            error={errors.date_of_birth && touched.date_of_birth && true}
                                                            errorText={errors.date_of_birth && touched.date_of_birth && true}
                                                        />
                                                        <SelectSearch
                                                            label="Bank Name"
                                                            name="marital_status"
                                                            placeholder="Select marital status"
                                                            onChange={(val) => setFieldValue("marital_status", val.value)}
                                                            error={errors.marital_status && touched.marital_status && true}
                                                            errorText={errors.marital_status && touched.marital_status && true}
                                                        />
                                                        <Input
                                                            label="Bank Account"
                                                            name="date_of_birth"
                                                            type="text"
                                                            placeholder="Input date of birth"
                                                            value={values?.date_of_birth}
                                                            onChange={(val) => setFieldValue("date_of_birth", val.value)}
                                                            error={errors.date_of_birth && touched.date_of_birth && true}
                                                            errorText={errors.date_of_birth && touched.date_of_birth && true}
                                                        />
                                                        <Input
                                                            label="Bank Beneficiary"
                                                            name="date_of_birth"
                                                            type="text"
                                                            placeholder="Input date of birth"
                                                            value={values?.date_of_birth}
                                                            onChange={(val) => setFieldValue("date_of_birth", val.value)}
                                                            error={errors.date_of_birth && touched.date_of_birth && true}
                                                            errorText={errors.date_of_birth && touched.date_of_birth && true}
                                                        />
                                                        <Input
                                                            label="Bank Branch"
                                                            name="date_of_birth"
                                                            type="text"
                                                            placeholder="Input date of birth"
                                                            value={values?.date_of_birth}
                                                            onChange={(val) => setFieldValue("date_of_birth", val.value)}
                                                            error={errors.date_of_birth && touched.date_of_birth && true}
                                                            errorText={errors.date_of_birth && touched.date_of_birth && true}
                                                        />
                                                    </Col>
                                                </Row>
                                                <div className="d-flex justify-content-end mt-3">
                                                    <SaveButton icon label="Save" />
                                                </div>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="address">
                                                <Row>
                                                    <Col>
                                                        <div className="mb-3"><b>FIRST ADDRESS:</b></div>
                                                        <SelectSearch
                                                            label="Address Type"
                                                            name="type_of_nasional_ity_id"
                                                            placeholder="Select type of nasional ity id"
                                                            onChange={(val) => setFieldValue("type_of_nasional_ity_id", val.value)}
                                                            error={errors.type_of_nasional_ity_id && touched.type_of_nasional_ity_id && true}
                                                            errorText={errors.type_of_nasional_ity_id && touched.type_of_nasional_ity_id && true}
                                                        />
                                                        <SelectSearch
                                                            label="Country"
                                                            name="type_of_nasional_ity_id"
                                                            placeholder="Select type of nasional ity id"
                                                            onChange={(val) => setFieldValue("type_of_nasional_ity_id", val.value)}
                                                            error={errors.type_of_nasional_ity_id && touched.type_of_nasional_ity_id && true}
                                                            errorText={errors.type_of_nasional_ity_id && touched.type_of_nasional_ity_id && true}
                                                        />
                                                        <SelectSearch
                                                            label="Province"
                                                            name="type_of_nasional_ity_id"
                                                            placeholder="Select type of nasional ity id"
                                                            onChange={(val) => setFieldValue("type_of_nasional_ity_id", val.value)}
                                                            error={errors.type_of_nasional_ity_id && touched.type_of_nasional_ity_id && true}
                                                            errorText={errors.type_of_nasional_ity_id && touched.type_of_nasional_ity_id && true}
                                                        />
                                                        <SelectSearch
                                                            label="Regency"
                                                            name="type_of_nasional_ity_id"
                                                            placeholder="Select type of nasional ity id"
                                                            onChange={(val) => setFieldValue("type_of_nasional_ity_id", val.value)}
                                                            error={errors.type_of_nasional_ity_id && touched.type_of_nasional_ity_id && true}
                                                            errorText={errors.type_of_nasional_ity_id && touched.type_of_nasional_ity_id && true}
                                                        />
                                                        <SelectSearch
                                                            label="Subdistrict"
                                                            name="type_of_nasional_ity_id"
                                                            placeholder="Select type of nasional ity id"
                                                            onChange={(val) => setFieldValue("type_of_nasional_ity_id", val.value)}
                                                            error={errors.type_of_nasional_ity_id && touched.type_of_nasional_ity_id && true}
                                                            errorText={errors.type_of_nasional_ity_id && touched.type_of_nasional_ity_id && true}
                                                        />
                                                        <SelectSearch
                                                            label="Village"
                                                            name="type_of_nasional_ity_id"
                                                            placeholder="Select type of nasional ity id"
                                                            onChange={(val) => setFieldValue("type_of_nasional_ity_id", val.value)}
                                                            error={errors.type_of_nasional_ity_id && touched.type_of_nasional_ity_id && true}
                                                            errorText={errors.type_of_nasional_ity_id && touched.type_of_nasional_ity_id && true}
                                                        />
                                                        <TextArea
                                                            label="Address"
                                                            type="text"
                                                            name="description"
                                                            placeholder="Input description"
                                                            // value={values.description}
                                                            onChange={handleChange}
                                                            rows={2}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <div className="mb-3"><b>SECCOND ADDRESS:</b></div>
                                                        <SelectSearch
                                                            label="Address Type"
                                                            name="type_of_nasional_ity_id"
                                                            placeholder="Select type of nasional ity id"
                                                            onChange={(val) => setFieldValue("type_of_nasional_ity_id", val.value)}
                                                            error={errors.type_of_nasional_ity_id && touched.type_of_nasional_ity_id && true}
                                                            errorText={errors.type_of_nasional_ity_id && touched.type_of_nasional_ity_id && true}
                                                        />
                                                        <SelectSearch
                                                            label="Country"
                                                            name="type_of_nasional_ity_id"
                                                            placeholder="Select type of nasional ity id"
                                                            onChange={(val) => setFieldValue("type_of_nasional_ity_id", val.value)}
                                                            error={errors.type_of_nasional_ity_id && touched.type_of_nasional_ity_id && true}
                                                            errorText={errors.type_of_nasional_ity_id && touched.type_of_nasional_ity_id && true}
                                                        />
                                                        <SelectSearch
                                                            label="Province"
                                                            name="type_of_nasional_ity_id"
                                                            placeholder="Select type of nasional ity id"
                                                            onChange={(val) => setFieldValue("type_of_nasional_ity_id", val.value)}
                                                            error={errors.type_of_nasional_ity_id && touched.type_of_nasional_ity_id && true}
                                                            errorText={errors.type_of_nasional_ity_id && touched.type_of_nasional_ity_id && true}
                                                        />
                                                        <SelectSearch
                                                            label="Regency"
                                                            name="type_of_nasional_ity_id"
                                                            placeholder="Select type of nasional ity id"
                                                            onChange={(val) => setFieldValue("type_of_nasional_ity_id", val.value)}
                                                            error={errors.type_of_nasional_ity_id && touched.type_of_nasional_ity_id && true}
                                                            errorText={errors.type_of_nasional_ity_id && touched.type_of_nasional_ity_id && true}
                                                        />
                                                        <SelectSearch
                                                            label="Subdistrict"
                                                            name="type_of_nasional_ity_id"
                                                            placeholder="Select type of nasional ity id"
                                                            onChange={(val) => setFieldValue("type_of_nasional_ity_id", val.value)}
                                                            error={errors.type_of_nasional_ity_id && touched.type_of_nasional_ity_id && true}
                                                            errorText={errors.type_of_nasional_ity_id && touched.type_of_nasional_ity_id && true}
                                                        />
                                                        <SelectSearch
                                                            label="Village"
                                                            name="type_of_nasional_ity_id"
                                                            placeholder="Select type of nasional ity id"
                                                            onChange={(val) => setFieldValue("type_of_nasional_ity_id", val.value)}
                                                            error={errors.type_of_nasional_ity_id && touched.type_of_nasional_ity_id && true}
                                                            errorText={errors.type_of_nasional_ity_id && touched.type_of_nasional_ity_id && true}
                                                        />
                                                        <TextArea
                                                            label="Address"
                                                            type="text"
                                                            name="description"
                                                            placeholder="Input description"
                                                            // value={values.description}
                                                            onChange={handleChange}
                                                            rows={2}
                                                        />
                                                    </Col>
                                                </Row>
                                                <div className="d-flex justify-content-end mt-3">
                                                    <SaveButton icon label="Save" />
                                                </div>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="map">
                                                <Input
                                                    label="Link Map"
                                                    name="date_start"
                                                    type="text"
                                                    placeholder="Input date start"
                                                    value={values?.date_start}
                                                    onChange={(val) => setFieldValue("date_start", val.value)}
                                                    error={errors.date_start && touched.date_start && true}
                                                    errorText={errors.date_start && touched.date_start && true}
                                                />
                                                <div className="d-flex justify-content-end mt-3">
                                                    <SaveButton icon label="Save" />
                                                </div>
                                            </Tab.Pane>
                                        </Tab.Content>
                                    </Card.Body>
                                </Tab.Container>
                            </Card>
                        </div>
                    </form>
                )}
            </Formik>
          </TableLayout>
    {/* //     : <DataStatus text="Tidak ada data" />
    //   } */}
    </CRUDLayout>
  );
};


// React
import { Fragment } from "react"

// API
import Services from "services"

// Components
import { CRUDLayout, CRUDModalSection, DataStatus, SaveButton, TBody, THead, TableLayout, Td, TdFixed, Th, ThFixed, Tr } from "components"
import { Delete, Detail, FormSection, Update } from "./components"

// Form
import { FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

// Third-Party Libraries
import toast from "react-hot-toast"

// Utils
import { baseData, useList, useToggle, validationSchema } from "./utils"

export function Project() {
  // Hook
  const { data, isError, isLoading, refetch } = useList()
  const { isActive, toggle } = useToggle()

  if (isError || isLoading) {
    return <DataStatus loading={isLoading} text={isLoading ? "Memuat Data" : "Tidak dapat memuat data"} />
  } else {
    return (
      <Fragment>
        <TableLayout noMarginTop label={baseData.title} withCreateButton onClick={toggle}>
          <div className="px-3 pt-3">
            <CRUDLayout.Table>
              <THead>
                <Tr className="text-center">
                  <ThFixed>No</ThFixed>
                  <Th>PROJECT</Th>
                  <Th>COUNTRY</Th>
                  <Th>PROVINCE</Th>
                  <Th>REGENCY</Th>
                  <Th>SUBDISTRICT</Th>
                  <Th>VILLAGE</Th>
                  <Th>ADDRESS</Th>
                  <Th>NOTE</Th>
                  <ThFixed>ACTION</ThFixed>
                </Tr>
              </THead>
              <TBody>
                {data.map((val, index) => {
                  return (
                    <Tr key={val.id}>
                      <TdFixed>{index + 1}</TdFixed>
                      <Td>{val.project_name}</Td>
                      <Td>{val.country_name}</Td>
                      <Td>{val.province_name}</Td>
                      <Td>{val.regency_name}</Td>
                      <Td>{val.subdistrict_name}</Td>
                      <Td>{val.village_name}</Td>
                      <Td>{val.address}</Td>
                      <Td>{val.note}</Td>
                      <TdFixed>
                        <div className="d-flex">
                          <Detail data={val} />
                          <Update data={val} onSuccess={refetch} />
                          <Delete data={val} onSuccess={refetch} />
                        </div>
                      </TdFixed>
                    </Tr>
                  );
                })}
              </TBody>
            </CRUDLayout.Table>
          </div>
        </TableLayout>

        {isActive && (
          <Create
            toggle={toggle}
            onSuccess={() => {
              toggle()
              refetch()
            }}
          />
        )}
      </Fragment>
    )
  }
}

function Create({ onSuccess, toggle }) {
  // Form
  const defaultValues = {
    project_name: "",
    country_id: "",
    province_id: "",
    regency_id: "",
    subdistrict_id: "",
    village_id: "",
    address: "",
    note: ""
  }
  const onSubmit = (value) => {
    return new Promise((resolve) => {
      toast.promise(
        Services.post("/project/add", value),
        {
          loading: "Loading...",
          success: (res) => res.data.message,
          error: (err) => err.response.data.detail
        }
      ).then(onSuccess).catch(() => {}).finally(resolve)
    })
  }
  const { control, formState, handleSubmit, setValue } = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema)
  })

  return (
    <CRUDModalSection
      show
      type="create" 
      title={baseData.title}
      onHide={toggle}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormProvider control={control} setValue={setValue}>
          <FormSection />
          <SaveButton icon forModal type="submit" loading={formState.isSubmitting} />
        </FormProvider>
      </form>
    </CRUDModalSection>
  )
}
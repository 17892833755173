import React from 'react'
import { Modal } from "react-bootstrap"
import { FaCheckCircle } from "react-icons/fa"
import { IoIosCloseCircle } from "react-icons/io";
import { CloseButton } from 'components';

export const AlertModal = ({ show, onHide, action }) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div className='d-flex justify-content-center my-4'>
            {action === "success"
                ? <FaCheckCircle style={{ fontSize: "120px" }} />
                : action === "error" && <IoIosCloseCircle style={{ fontSize: "120px" }} />
            }
        </div>
        <div className='d-flex justify-content-center'>
            <CloseButton label="Close" onClick={onHide} />
        </div>
      </Modal.Body>
    </Modal>
  )
}
// React
import { useEffect, useState } from "react"

// API
import Services from "services"

export function useSubdistrict(id) {
  // Hooks
  const [data, setData] = useState([])
  const [isLoading, setLoading] = useState(false)

  useEffect(() => {
    refetch()
  
    return () => {
      setData([])
      setLoading(false)
    }

    // eslint-disable-next-line
  }, [])

  const refetch = () => {
    setLoading(true)

    Services.get(`/subdistricts/?regency_id=${id}`).then(res => {
      setData(res.data.payload.map(item => {
        return {
          label: item.subdistrict_name,
          value: item.subdistrict_id
        }
      }))
    }).catch(() => {
      setData([])
    }).finally(() => {
      setLoading(false)
    })
  }
  
  return { data, isLoading, refetch }
}
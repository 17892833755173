// React
import { useEffect, useState } from "react"

// API
import Services from "services"

export function useCountry() {
  // Hooks
  const [data, setData] = useState([])
  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    Services.get("/countries/").then(res => {
      setData(res.data.payload.map(item => {
        return {
          label: item.country_name,
          value: item.country_id
        }
      }))
    }).catch(() => {
      setData([])
    }).finally(() => {
      setLoading(false)
    })
  
    return () => {
      setData([])
      setLoading(true)
    }
  }, [])
  
  return { data, isLoading }
}
import React, { useState, useEffect } from "react";
import { CRUDLayout, TableLayout, DataStatus } from "components";
import { Formik } from "formik";
import { useHistory, useLocation } from "react-router-dom";
import { Card, Tab, Nav } from "react-bootstrap";
import Axios from "axios";
import "./CreateNewEmployee.css"
import CreateNewEmployeeApi from "./__CreateNewEmployeeApi__";
import { 
    FormAddress, 
    FormBank, 
    FormEducation, 
    FormPersonalData, 
    FormPrivilage, 
    FormProfile, 
    FormWorkStatus 
} from "./__CreateNewEmployeeComps__";
import { 
    formPersonalValues, 
    formProfileValues, 
    formAddressValues,
    formPrivilageValues,
    formWorkStatusValues,
    formEducationValues,
    formBankValues,
} from "./__CreateNewEmployeeUtillities__";

export const CreateNewEmployeeDetail = () => {
  const menuTitle = "EMPLOYEE REGISTRATION"
  const history = useHistory()
  const location = useLocation()
  const dataInfo = location?.state?.dataInfo

  const [profileList, setProfileList] = useState([])
  const [addressList, setAddressList] = useState([])
  const [workStatusList, setWorkStatusList] = useState([])
  const [educationList, setEducationList] = useState([])
  const [bankList, setBankList] = useState([])
  const [accessList, setAccessList] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const getData = () => {
    setIsLoading(true);

    Axios.all([
      CreateNewEmployeeApi.getProfile(dataInfo?.employee_id),
      CreateNewEmployeeApi.getAddress(dataInfo?.employee_id),
      CreateNewEmployeeApi.getWorkStatus(dataInfo?.employee_id),
      CreateNewEmployeeApi.getEducation(dataInfo?.employee_id),
      CreateNewEmployeeApi.getBank(dataInfo?.employee_id),
      CreateNewEmployeeApi.getAccess(dataInfo?.employee_id),
    ])
      .then(Axios.spread((profile, address, status, education, bank, access) => {
        setProfileList(profile?.data?.payload)
        setAddressList(address?.data?.payload)
        setWorkStatusList(status?.data?.payload)
        setEducationList(education?.data?.payload)
        setBankList(bank?.data?.payload)
        setAccessList(access?.data?.payload)
      }))
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };
  useEffect(() => {
    getData();

    return () => setIsLoading(false)
  }, []);

  const personalStyle = { fontSize: "15px", fontFamily: 'poppins', paddingTop: "8.9px", paddingBottom: "8.9px", backgroundColor: "#D1D1D1" }
  const tabHeaderStyle = { borderRadius: "0px", paddingLeft: "20px", paddingRight: "20px" }
  const cardHeaderStyle = { paddingTop: "0px", paddingBottom: "0px", backgroundColor: "#D1D1D1" }

  return (
    <CRUDLayout>
        <TableLayout create label={menuTitle} withBackButton onClick={() => history.goBack()}>
            {isLoading
                ? <DataStatus loading={isLoading} text="Loading..." />
                : <div className="d-flex p-1">
                    <Card style={{ width: "30%", borderRadius: "0px" }}>
                        <Card.Header style={personalStyle}>PERSONAL DATA</Card.Header>
                        <Card.Body>
                            <Formik enableReinitialize initialValues={formPersonalValues(dataInfo)}>
                                <FormPersonalData action="READ" />
                            </Formik>
                        </Card.Body>
                    </Card>
                    <Card style={{ width: "70%", borderRadius: "0px" }}>
                        <Tab.Container id="left-tabs-example" defaultActiveKey="profile">
                            <Card.Header style={cardHeaderStyle}>
                                <Nav variant="pills" className="d-flex justify-content-start">
                                    <Nav.Item>
                                        <Nav.Link style={tabHeaderStyle} eventKey="profile">PROFILE</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link style={tabHeaderStyle} eventKey="address">ADDRESS</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link style={tabHeaderStyle} eventKey="work_status">WORK STATUS</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link style={tabHeaderStyle} eventKey="education">EDUCATION</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link style={tabHeaderStyle} eventKey="bank">BANK</Nav.Link>
                                    </Nav.Item>
                                    {/* <Nav.Item>
                                        <Nav.Link style={tabHeaderStyle} eventKey="privilege">PRIVILEGE</Nav.Link>
                                    </Nav.Item> */}
                                </Nav>
                            </Card.Header>
                            <Card.Body>
                                <Tab.Content>
                                    <Tab.Pane eventKey="profile">
                                        <Formik enableReinitialize initialValues={formProfileValues(profileList)}>
                                            <FormProfile action="READ"  />
                                        </Formik>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="address">
                                        <Formik enableReinitialize initialValues={formAddressValues(addressList[0])}>
                                            <FormAddress action="READ"  />
                                        </Formik>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="work_status">
                                        <Formik enableReinitialize initialValues={formWorkStatusValues(workStatusList[0])}>
                                            <FormWorkStatus action="READ"  />
                                        </Formik>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="education">
                                        <Formik enableReinitialize initialValues={formEducationValues(educationList[0])}>
                                            <FormEducation action="READ"  />
                                        </Formik>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="bank">
                                        <Formik enableReinitialize initialValues={formBankValues(bankList[0])}>
                                            <FormBank action="READ"  />
                                        </Formik>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="privilege">
                                        <Formik enableReinitialize initialValues={formPrivilageValues(accessList)}>
                                            <FormPrivilage action="READ" accessList={accessList} />
                                        </Formik>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Card.Body>
                        </Tab.Container>
                    </Card>
                  </div>
            }
        </TableLayout>
    </CRUDLayout>
  );
};

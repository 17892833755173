import React, { useState, useEffect } from "react";
import { useFormikContext } from "formik";
import { InfoItemHorizontal, SelectSearch, Input, TextArea, CloseButton, SaveButton } from "components";
import LocationApi from "../__LocationApi__";

export const Content = ({ action, data, modalConfig, setModalConfig, onSubmit }) => {
  const { values, errors, touched, setValues, isSubmitting } = useFormikContext() ?? {}
  console.log("values", values)

  const [dropdownCountry, setDropdownCountry] = useState([]);
  const [dropdownProvince, setDropdownProvince] = useState([]);
  const [dropdownRegency, setDropdownRegency] = useState([]);
  const [dropdownSubdistrict, setDropdownSubdistrict] = useState([]);
  const [dropdownVillage, setDropdownVillage] = useState([]);
  const [isLoading, setIsLoading] = useState({
    country: false,
    province: false,
    regency: false,
    subdistrict: false,
    village: false,
  });

  const getCountry = () => {
    setIsLoading({ ...isLoading, country: true });

    LocationApi.dropdownCountries()
      .then(country => {
        const getCountry = country?.data?.payload ? country?.data?.payload?.map(val => ({ label: val?.country_name, value: val?.country_id })) : []
        setDropdownCountry(getCountry)
      })
      .catch((err) => alert(err))
      .finally(() => setIsLoading({ ...isLoading, country: false }));
  };
  const getProvince = (id) => {
    setIsLoading({ ...isLoading, province: true });

    LocationApi.dropdownProvince(id)
      .then(province => {
        const getProvince = province?.data?.payload ? province?.data?.payload?.map(val => ({ label: val?.province_name, value: val?.province_id })) : []
        setDropdownProvince(getProvince)
      })
      .catch((err) => alert(err))
      .finally(() => setIsLoading({ ...isLoading, province: false }));
  };
  const getRegency = (id) => {
    setIsLoading({ ...isLoading, regency: true });

    LocationApi.dropdownRegencies(id)
      .then(regency => {
        const getRegency = regency?.data?.payload ? regency?.data?.payload?.map(val => ({ label: val?.regency_name, value: val?.regency_id })) : []
        setDropdownRegency(getRegency)
      })
      .catch((err) => alert(err))
      .finally(() => setIsLoading({ ...isLoading, regency: false }));
  };
  const getSubdistrict = (id) => {
    setIsLoading({ ...isLoading, subdistrict: true });

    LocationApi.dropdownSubdistricts(id)
      .then(subdistrict => {
        const getSubdistrict = subdistrict?.data?.payload ? subdistrict?.data?.payload?.map(val => ({ label: val?.subdistrict_name, value: val?.subdistrict_id })) : []
        setDropdownSubdistrict(getSubdistrict)
      })
      .catch((err) => alert(err))
      .finally(() => setIsLoading({ ...isLoading, subdistrict: false }));
  };
  const getVillage = (id) => {
    setIsLoading({ ...isLoading, village: true });

    LocationApi.dropdownVillages(id)
      .then(village => {
        const getVillage = village?.data?.payload ? village?.data?.payload?.map(val => ({ label: val?.village_name, value: val?.village_id })) : []
        setDropdownVillage(getVillage)
      })
      .catch((err) => alert(err))
      .finally(() => setIsLoading({ ...isLoading, village: false }));
  };

  useEffect(() => {
    getCountry();

    return () => setIsLoading(false)
  }, []);

  if (action === "READ") {
    return (
      <>
        <InfoItemHorizontal label="Name" text={data?.location_name ?? "-"} />
        <InfoItemHorizontal label="Country" text={data?.country_name ?? "-"} />
        <InfoItemHorizontal label="Province" text={data?.province_name ?? "-"} />
        <InfoItemHorizontal label="Regency" text={data?.regency_name ?? "-"} />
        <InfoItemHorizontal label="Subdistrict" text={data?.subdistrict_name ?? "-"} />
        <InfoItemHorizontal label="Village" text={data?.village_name ?? "-"} />
        <InfoItemHorizontal label="Address" text={data?.address ?? "-"} />
        <InfoItemHorizontal label="Note" text={data?.description ?? "-"} />
      </>
    );
  }

  return (
    <>
      <Input
        label="Name"
        type="text"
        placeholder="Input name"
        value={values?.location_name}
        onChange={(e) => setValues({ ...values, location_name: e?.target?.value })}
        error={Boolean(errors?.location_name && touched?.location_name)}
        errorText={Boolean(errors?.location_name && touched?.location_name) && errors?.location_name}
      />
      <SelectSearch
        label="Country"
        placeholder="Select country"
        defaultValue={values?.country_id ? dropdownCountry?.find(item => item?.value === values?.country_id) : []}
        option={dropdownCountry}
        onChange={(val) => {
          setValues({ ...values, country_id: val?.value })
          getProvince(val.value);
        }}
        error={Boolean(errors?.country_id && touched?.country_id)}
        loading={isLoading?.country}
      />
      <SelectSearch
        label="Province"
        placeholder="Select province"
        defaultValue={ values.province_id ? { value: values?.province_id, label: values?.province_name } : false }
        option={dropdownProvince}
        onChange={(val) => {
          setValues({ ...values, province_id: val?.value })
          getRegency(val.value);
        }}
        error={Boolean(errors?.province_id && touched?.province_id)}
        loading={isLoading?.province}
        isDisabled={!values?.country_id}
      />
      <SelectSearch
        label="Regency"
        placeholder="Select regency"
        defaultValue={ values.regency_id ? { value: values?.regency_id, label: values?.regency_name } : false }
        option={dropdownRegency}
        onChange={(val) => {
          setValues({ ...values, regency_id: val?.value })
          getSubdistrict(val.value);
        }}
        error={Boolean(errors?.regency_id && touched?.regency_id)}
        loading={isLoading?.regency}
        isDisabled={!values?.province_id}
      />
      <SelectSearch
        label="Subdistrict"
        placeholder="Select subdistrict"
        defaultValue={ values.subdistrict_id ? { value: values?.subdistrict_id, label: values?.subdistrict_name } : false }
        option={dropdownSubdistrict}
        onChange={(val) => {
          setValues({ ...values, subdistrict_id: val?.value })
          getVillage(val.value);
        }}
        error={Boolean(errors?.subdistrict_id && touched?.subdistrict_id)}
        loading={isLoading?.subdistrict}
        isDisabled={!values?.regency_id}
      />
      <SelectSearch
        label="Village"
        placeholder="Select village"
        defaultValue={ values.village_id ? { value: values?.village_id, label: values?.village_name } : false }
        option={dropdownVillage}
        onChange={(val) => setValues({ ...values, village_id: val?.value })}
        error={Boolean(errors?.village_id && touched?.village_id)}
        loading={isLoading?.village}
        isDisabled={!values?.subdistrict_id}
      />
      <TextArea
        label="Address"
        type="text"
        placeholder="Input address"
        value={values?.address}
        onChange={(e) => setValues({ ...values, address: e?.target?.value })}
        error={Boolean(errors?.address && touched?.address)}
        errorText={Boolean(errors?.address && touched?.address) && errors?.address}
        rows={2}
      />
      <TextArea
        label="Noted"
        type="text"
        placeholder="Input noted"
        value={values?.description}
        onChange={(e) => setValues({ ...values, description: e?.target?.value })}
        error={Boolean(errors?.description && touched?.description)}
        errorText={Boolean(errors?.description && touched?.description) && errors?.description}
        rows={2}
      />
      <hr className='my-3'/>
      <div className="mt-3 d-flex">
        <CloseButton icon onClick={() => setModalConfig({ ...modalConfig, show: false, action: "CREATE"  })} />
        <SaveButton icon forModal type="submit" loading={isSubmitting} onClick={onSubmit} />
      </div>
    </>
  );
};

import Services from "services";

class COATypeApi {
  getList(params) {
    return Services.get("/acctype/", { params });
  }
  dropdownCOAGroup(params) {
    return Services.get("/groupcoa/", { params });
  }
  create(data) {
    return Services.post("/acctype/add", data);
  }
  update(data, id) {
    return Services.put(`/acctype/edit?account_type_id=${id}`, data);
  }
  delete(id) {
    return Services.delete(`/acctype/delete?account_type_id=${id}`);
  }
}

export default new COATypeApi();

export const AuthMethod = {
  CHECK: 'CHECK',
  LOGIN: 'LOGIN',
  LOGOUT: 'LOGOUT',
  REGISTER: 'REGISTER'
}
export const AuthReducer = (prevState, action) => {
  switch (action.type) {
    case AuthMethod.LOGIN: sessionStorage.setItem('token', action.payload.token)
      return {
        isAuthenticated: true,
        token: action.payload.token,
        username: action.payload.username,
        role: action.payload.role
      }

    case AuthMethod.LOGOUT: sessionStorage.removeItem('token')
      return {
        isAuthenticated: false,
        token: '',
        username: '',
        role: ''
      }
    case AuthMethod.REGISTER: 
      return { 
        isAuthenticated: true 
      }

    default: {
      return prevState
    }
  }
}


import { Fragment } from "react"
import { CRUDLayout, TBody, THead, TableLayout, Td, TdFixed, Th, ThFixed, Tr, DataStatus, SaveButton, CRUDModalSection, AddButton } from "components"
import { baseData, useList, validationSchema, useToggle, useToggleParent } from "./utils"
import { FaCheck } from "react-icons/fa";
import { MdClose } from "react-icons/md";
import toast from "react-hot-toast"
import Services from "services"
import { FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { Delete, Detail, FormSection, Update } from "./components"

export function CoaRegistration() {
  return (
    <CRUDLayout>
      <Table />
    </CRUDLayout>
  )
}

function Table() {
  const { data, isError, isLoading, refetch } = useList()
  const { isActive, toggle } = useToggle()
  const { isActiveParent, toggleParent } = useToggleParent()

  if (isError || isLoading) {
    return <DataStatus loading={isLoading} text={isLoading ? "Memuat Data" : "Tidak dapat memuat data"} />
  } else {
    return (
      <Fragment>
        <TableLayout noMarginTop label={baseData.title} withCreateButton onClick={toggle}>
          <div className="px-3 pt-3">
            <CRUDLayout.Table>
              <THead>
                <Tr className="text-center">
                  <ThFixed>No</ThFixed>
                  <Th><div style={{ minWidth: 50 }}>CHQ</div></Th>
                  <Th><div style={{ minWidth: 100 }}>ACCOUNT</div></Th>
                  <Th><div style={{ minWidth: 250 }}>NAME</div></Th>
                  <Th>LINKED</Th>
                  <Th><div style={{ minWidth: 60 }}>LEVEL</div></Th>
                  <Th>ACC TYPE</Th>
                  <Th><div style={{ minWidth: 100 }}>BALANCE</div></Th>
                  <ThFixed>ACTION</ThFixed>
                </Tr>
              </THead>
              <TBody>
                {data.map((val, index) => {
                  return (
                    <Tr key={val.id}>
                      <TdFixed>
                        <div className="d-flex align-items-center">
                          <div>{index + 1}</div>
                          {/* <button onClick={() => setExpanded(!expanded)}>{!expanded
                            ? <IoIosArrowDropright style={{ height: "16px", width: "16px", marginLeft: "10px" }} />
                            : <IoIosArrowDropdown style={{ height: "16px", width: "16px", marginLeft: "10px" }} />}
                          </button> */}
                        </div>
                      </TdFixed>
                      <Td><div className="d-flex justify-content-center">{val?.cheque_coa ? <FaCheck /> : <MdClose />}</div></Td>
                      <Td>{`${val?.group_coa_code}-${val?.coa_code}` ?? "-"}</Td>
                      <Td>{val?.coa_name ?? "-"}</Td>
                      <Td><div className="d-flex justify-content-center">{val?.budget_coa ? <FaCheck /> : <MdClose />}</div></Td>
                      <Td className="text-center">{val?.coa_level ?? "-"}</Td>
                      <Td>{val?.coa_level === 1 ? "Header" : val?.coa_level === 2 ? "Sub Header" : "Detail"}</Td>
                      <Td className="text-right">{val?.opening_balance ?? "-"}</Td>
                      <TdFixed>
                        <div className="d-flex">
                          {/* <AddButton icon onClick={() => setModalConfig({ ...modalConfig, show: true, action: "CREATE_PARENT", data: val  })} />
                          <ReadButton icon onClick={() => setModalConfig({ ...modalConfig, show: true, action: "READ", data: val  })}/>
                          <UpdateButton icon onClick={() => setModalConfig({ ...modalConfig, show: true, action: "UPDATE", data: val  })} />
                          <DeleteButton icon onClick={() => setModalConfig({ ...modalConfig, show: true, action: "DELETE", data: val  })} /> */}
                          <AddButton icon onClick={toggleParent} />
                          <Detail data={val} />
                          <Update data={val} onSuccess={refetch} />
                          <Delete data={val} onSuccess={refetch} />
                        </div>
                      </TdFixed>
                    </Tr>
                  );
                })}
              </TBody>
            </CRUDLayout.Table>
          </div>
        </TableLayout>

        {isActive && (
          <Create
            toggle={toggle}
            onSuccess={() => {
              toggle()
              refetch()
            }}
          />
        )}
        {isActiveParent && (
          <CreateParent
            toggle={toggleParent}
            onSuccess={() => {
              toggleParent()
              refetch()
            }}
          />
        )}
      </Fragment>
    )
  }
}

function Create({ onSuccess, toggle }) {
  const defaultValues = {
    coa_code: "",
    coa_name: "",
    group_coa_id: "",
    account_type_id: "",
    coa_level: "",
    coa_number: "1",
    parent_id: "",
    header_level: "",
    normal_pos: "",
    opening_balance: "",
    cheque_coa: false,
    budget_coa: false,
    note: "",
    report_type: ""
  }
  const onSubmit = (value) => {
    return new Promise((resolve) => {
      toast.promise(
        Services.post("/coa/add", value),
        {
          loading: "Loading...",
          success: (res) => res.data.message,
          error: (err) => err.response.data.detail
        }
      ).then(onSuccess).catch(() => {}).finally(resolve)
    })
  }
  const { control, formState, handleSubmit, setValue } = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema)
  })

  return (
    <CRUDModalSection
      show
      size="lg"
      type="create" 
      title={baseData.title}
      onHide={toggle}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormProvider control={control} setValue={setValue}>
          <FormSection />
          <hr className='my-3'/>
          <div className="d-flex">
            <SaveButton icon forModal type="submit" loading={formState.isSubmitting} />
          </div>
        </FormProvider>
      </form>
    </CRUDModalSection>
  )
}
function CreateParent({ onSuccess, toggle }) {
  const defaultValues = {
    coa_code: "",
    coa_name: "",
    group_coa_id: "",
    account_type_id: "",
    coa_level: "",
    coa_number: "1",
    parent_id: "",
    header_level: "",
    normal_pos: "",
    opening_balance: "",
    cheque_coa: false,
    budget_coa: false,
    note: "",
    report_type: ""
  }
  const onSubmit = (value) => {
    return new Promise((resolve) => {
      toast.promise(
        Services.post("/coa/add", value),
        {
          loading: "Loading...",
          success: (res) => res.data.message,
          error: (err) => err.response.data.detail
        }
      ).then(onSuccess).catch(() => {}).finally(resolve)
    })
  }
  const { control, formState, handleSubmit, setValue } = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema)
  })

  return (
    <CRUDModalSection
      show
      size="lg"
      type="create" 
      title={baseData.title}
      onHide={toggle}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormProvider control={control} setValue={setValue}>
          <FormSection action="PARENT" />
          <hr className='my-3'/>
          <div className="d-flex">
            <SaveButton icon forModal type="submit" loading={formState.isSubmitting} />
          </div>
        </FormProvider>
      </form>
    </CRUDModalSection>
  )
}
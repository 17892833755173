import React from "react";
import { useFormikContext } from "formik";
import { InfoItemHorizontal, SelectSearch, Input, TextArea, SaveButton, CloseButton } from "components";

export const Content = ({ action, data, dropdownGroup, modalConfig, setModalConfig, onSubmit }) => {
  const { values, errors, touched, isSubmitting, setValues } = useFormikContext() ?? {}

  if (action === "READ") {
    return (
      <>
        <InfoItemHorizontal label="Code" text={data?.account_type_code ?? "-"} />
        <InfoItemHorizontal label="Coa Group" text={data?.group_coa_name ?? "-"} />
        <InfoItemHorizontal label="Report Type" text={data?.group_coa_type ? data?.group_coa_type === 1 ? "BALANCE SHEET" : "PROFIT AND LOST" : "-"} />
        <InfoItemHorizontal label="Description" text={data?.description ?? "-"} />
      </>
    );
  }

  return (
    <>
      <Input
        label="Code"
        type="text"
        placeholder="Input code"
        value={values?.account_type_code}
        onChange={(e) => setValues({ ...values, account_type_code: e?.target?.value })}
        error={Boolean(errors?.account_type_code && touched?.account_type_code)}
        errorText={Boolean(errors?.account_type_code && touched?.account_type_code) && errors?.account_type_code}
      />
      <SelectSearch
        label="COA Group"
        name="group_coa_id"
        placeholder="Select item"
        defaultValue={values?.group_coa_id ? dropdownGroup?.find(item => item?.value === values?.group_coa_id) : []}
        option={dropdownGroup}
        onChange={(val) => setValues({ ...values, group_coa_id: val?.value })}
        error={Boolean(errors?.group_coa_id && touched?.group_coa_id)}
      />
      <Input
        label="Account Type"
        type="text"
        name="account_type_name"
        placeholder="Input account type"
        value={values?.account_type_name}
        onChange={(e) => setValues({ ...values, account_type_name: e?.target?.value })}
        error={Boolean(errors?.account_type_name && touched?.account_type_name)}
        errorText={Boolean(errors?.account_type_name && touched?.account_type_name) && errors?.account_type_name}
      />
      <TextArea
        label="Description"
        type="text"
        name="description"
        placeholder="Input description"
        value={values?.description}
        onChange={(e) => setValues({ ...values, description: e?.target?.value })}
        rows={2}
      />
      <hr className='my-3'/>
      <div className="mt-3 d-flex">
        <CloseButton icon onClick={() => setModalConfig({ ...modalConfig, show: false, action: "CREATE"  })} />
        <SaveButton icon forModal type="submit" loading={isSubmitting} onClick={onSubmit} />
      </div>
    </>
  );
};

import React from "react";
import { useFormikContext } from "formik";
import { InfoItemHorizontal, SelectSearch, Input, TextArea, Checkbox } from "components";
import { Col, Row } from "react-bootstrap";

export const Content = ({ action }) => {
  const { values, errors, touched, handleChange, setFieldValue } = useFormikContext();

  if (action === "detail") {
    return (
      <>
        <InfoItemHorizontal label="Code" text="01" />
        <InfoItemHorizontal label="Coa Group" text="01" />
        <InfoItemHorizontal label="Report Type" text="01" />
        <InfoItemHorizontal label="Description" text="01" />
      </>
    );
  }

  return (
    <Row>
        <Col>
            <SelectSearch
                label="Account Group"
                name="account_group"
                placeholder="Select account group"
                onChange={(val) => setFieldValue("account_group", val.value)}
                error={errors.account_group && touched.account_group && true}
                errorText={errors.account_group && touched.account_group && true}
            />
            <Input
                label="Account Type"
                type="text"
                name="account_type"
                placeholder="Input account type"
                value={values.account_type}
                onChange={handleChange}
                error={errors.account_type && touched.account_type && true}
                errorText={errors.account_type}
                readOnly
            />
            <Input
                label="Level"
                type="text"
                name="level"
                placeholder="Input level"
                value={values.level}
                onChange={handleChange}
                error={errors.level && touched.level && true}
                errorText={errors.level}
                readOnly
            />
            <small style={{ fontFamily: "poppins medium" }}>Account Number</small>
            <div className="d-flex">
                <div className="mr-2" style={{ width: 150 }}>
                    <Input
                        type="text"
                        name="header_level"
                        value={values.header_level}
                        onChange={handleChange}
                        error={errors.header_level && touched.header_level && true}
                        errorText={errors.header_level}
                        readOnly
                    />
                </div>
                <div style={{ width: "100%" }}>
                    <Input
                        type="text"
                        name="header_level"
                        value={values.header_level}
                        onChange={handleChange}
                        error={errors.header_level && touched.header_level && true}
                        errorText={errors.header_level}
                    />
                </div>
            </div>
            <SelectSearch
                label="Normal Post"
                name="normal_post"
                placeholder="Select normal post"
                onChange={(val) => setFieldValue("normal_post", val.value)}
                error={errors.normal_post && touched.normal_post && true}
                errorText={errors.normal_post && touched.normal_post && true}
            />
            <TextArea
                label="Noted"
                type="text"
                name="noted"
                placeholder="Input noted"
                value={values.noted}
                onChange={handleChange}
                error={errors.noted && touched.noted && true}
                errorText={errors.noted}
                rows={2}
            />
        </Col>
        <Col>
            <Input
                label="Report Type"
                type="text"
                name="report_type"
                placeholder="Input report type"
                value={values.report_type}
                onChange={handleChange}
                error={errors.report_type && touched.report_type && true}
                errorText={errors.report_type}
                readOnly
            />
            <Input
                label="Parent"
                type="text"
                name="parent"
                placeholder="Input parent"
                value={values.parent}
                onChange={handleChange}
                error={errors.parent && touched.parent && true}
                errorText={errors.parent}
                readOnly
            />
            <Input
                label="Header Level"
                type="text"
                name="header_level"
                placeholder="Input header level"
                value={values.header_level}
                onChange={handleChange}
                error={errors.header_level && touched.header_level && true}
                errorText={errors.header_level}
                readOnly
            />
            <Input
                label="Account Name"
                type="text"
                name="account_name"
                placeholder="Input account name"
                value={values.account_name}
                onChange={handleChange}
                error={errors.account_name && touched.account_name && true}
                errorText={errors.account_name}
                readOnly
            />
            <Input
                label="Opening Balance"
                type="text"
                name="opening_balance"
                placeholder="Input opening balance"
                value={values.opening_balance}
                onChange={handleChange}
                error={errors.opening_balance && touched.opening_balance && true}
                errorText={errors.opening_balance}
            />
            <Checkbox label="Cheque Account" />
            <Checkbox label="Link To Bugeting" />
        </Col>
    </Row>
  );
};

import React from 'react'
import { ActionButton } from './ActionButton'

export const NavbarButton = ({ type, menuType, label, onClick, bgWhite, tooltip, tooltipText, noClass, className, isFeature }) => {
  const navButtonStyle = { 
    border: "none", 
    borderRadius: "0px", 
    borderLeft: '1px solid #767676',
    width: "100%",
    transition: '0.3s', 
    paddingLeft: noClass ? "39px" : "", 
    paddingRight: noClass ? "39px" : "",
    paddingTop: isFeature ? "10px" : "14px",
    paddingBottom: isFeature ? "10px" : "14px",
    backgroundColor: menuType === type && `${bgWhite ? "#E5E5E5" : noClass ? "#8B7646" : "#9A834E"}`,
  }
  const navButtonStyleWhite = { 
    border: "none", 
    borderRadius: "0px", 
    borderLeft: '1px solid #767676',
    backgroundColor: menuType === type && `${bgWhite ? "#E5E5E5" : noClass ? "#8B7646" : "#9A834E"}`,
  }

  return (
    <ActionButton 
        text={label}
        tooltip={tooltip}
        tooltipText={tooltipText}
        variant={bgWhite ? "light" : "dark-gold"}
        className={className}
        style={bgWhite ? navButtonStyleWhite : navButtonStyle}
        onClick={onClick}
    />
  )
}
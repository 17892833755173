import React from 'react'
import { Button } from 'react-bootstrap'
import { AiOutlineCloseCircle } from 'react-icons/ai'

export const CloseButton = ({ onClick, label }) => {
  return (
      <Button 
        className='d-flex align-items-center ml-1'
        style={{ backgroundColor: "transparent", borderRight: "100%", borderColor: "#61677A" }}
        onClick={onClick}
      >
        <AiOutlineCloseCircle size={16} className='mr-1 text-black' />
        <span className="text-uppercase text-black">
            {label ? label : "Close"}
        </span>
      </Button>
  )
}
import React from "react";
import { MdInput } from "react-icons/md"

const Input = ({
  label,
  type,
  name,
  placeholder,
  value,
  readOnly,
  error,
  errorText,
  onChange,
  onBlur,
  onFocus,
  autoComplete,
  maxLength,
  className,
  style,
}) => {
  return (
    <div className={`mb-2 ${className}`} style={style}>
      <small style={{ fontFamily: "poppins medium" }}>{label ? label?.toUpperCase() : ""}</small>
      <div className="input-group input-group-sm">
        <div className="input-group-prepend">
          <span className={`input-group-text bg-light`} style={{ borderTopLeftRadius: "9px", borderBottomLeftRadius: "9px" }}>
            <MdInput />
          </span>
        </div>
        <input
          type={type}
          name={name}
          value={value}
          placeholder={placeholder}
          readOnly={readOnly}
          className={`form-control form-control-sm ${error && "is-invalid"}`}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          autoComplete={autoComplete}
          maxLength={maxLength}
          style={{ borderTopRightRadius: "9px", borderBottomRightRadius: "9px" }}
        />
      </div>
      <small className="text-danger">{errorText}</small>
    </div>
  );
};

export default Input;

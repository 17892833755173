import React, { useState } from "react";
import { CRUDLayout, TableLayout, AlertModal } from "components";
import { Formik, Form } from "formik";
import { useHistory, useLocation } from "react-router-dom";
import { Card, Tab, Nav } from "react-bootstrap";
import "./CreateNewCustomer.css"
import { FormPersonalData, FormProfile, FormAddress, FormMap } from "./__CreateNewCustomerComps__";
import { 
    formPersonalValues, 
    formValidationPersonal,

    formProfileValues,
    formValidationProfile,

    formAddressValues,
    formValidationAddress,

    formMapValues,
    formValidationMap
} from "./__CreateNewCustomerUtillities__";
import CreateNewCustomerApi from "./__CreateNewCustomerApi__";

export const CreateNewCustomerCreate = () => {
  const menuTitle = "CUSTOMER REGISTRATION"
  const history = useHistory()
  const [fileValue, setFileValue] = useState(null);
  const [customerId, setCustomerId] = useState({ id: "" });
  const [alertModal, setAlertModal] = useState({ show: false, action: "" })
  const location = useLocation()
  const generateCode = location?.state?.dataInfo
  const [statusTab, setStatusTab] = useState({ 
    profile: true,
    address: true,
    map: true,
    more: true
  })
  const [isDisabled, setIsDisabled] = useState({
    personal: false,
    profile: false,
    address: false,
    map: false
  })

  const personalStyle = { fontSize: "15px", fontFamily: 'poppins', paddingTop: "8.9px", paddingBottom: "8.9px", backgroundColor: "#D1D1D1" }
  const tabHeaderStyle = { borderRadius: "0px", paddingLeft: "20px", paddingRight: "20px" }
  const cardHeaderStyle = { paddingTop: "0px", paddingBottom: "0px", backgroundColor: "#D1D1D1" }

  const formSubmitPersonalData = (values) => {

    const formData = new FormData();
    formData.append('Customer_code', String(values?.Customer_code));
    formData.append('Customer_name', String(values?.Customer_name));
    formData.append(fileValue ? 'Customer_Foto' : "", fileValue);

    CreateNewCustomerApi.createPersonalData(formData)
    .then(res => {
        const employeeResponse = res?.data?.payload?.[0]?.customer_id
        if (employeeResponse) {
            setCustomerId({ ...customerId, id: res?.data?.payload?.[0]?.customer_id })
        } else {
            window?.alert('Customer ID not found in the response.');
        }
        setStatusTab({ ...statusTab, profile: false })
    })
    .catch((err) => window.alert(`${err} ( profil, name is required! )`))
    .finally(() => {
        setAlertModal({ ...alertModal, show: true, action: statusTab?.profile === false ? "success" : "error", loading: false, type: "profil" })
        setIsDisabled({ ...isDisabled, personal: true })
    });
  };
  const formSubmitProfile = (values) => {
    const nonEmptyValues = Object.fromEntries(Object.entries(values).filter(([key, value]) => value !== ""));

    CreateNewCustomerApi.updateProfil(nonEmptyValues, customerId?.id)
    .then(() => setStatusTab({ ...statusTab, address: false }))
    .catch((err) => window.alert(`${err} ( please enter the input data correctly! )`))
    .finally(() => {
        setAlertModal({ ...alertModal, show: true, action: statusTab?.address === false ? "success" : "error", loading: false, type: "address" })
        setIsDisabled({ ...isDisabled, profile: true })
    });
  };
  const formSubmitAddress = (values) => {
    const nonEmptyValues = Object.fromEntries(Object.entries(values).filter(([key, value]) => value !== ""));

    CreateNewCustomerApi.updateAdress(nonEmptyValues, customerId?.id)
    .then(() => setStatusTab({ ...statusTab, map: false }))
    .catch((err) => window.alert(`${err} ( please enter the input data correctly! )`))
    .finally(() => {
        setAlertModal({ ...alertModal, show: true, action: statusTab?.map === false ? "success" : "error", loading: false, type: "map" })
        setIsDisabled({ ...isDisabled, address: true })
    });
  };
  const formSubmitMap = (values) => {
    const nonEmptyValues = Object.fromEntries(Object.entries(values).filter(([key, value]) => value !== ""));

    CreateNewCustomerApi.updateLink(nonEmptyValues, customerId?.id)
    .then(() => setStatusTab({ ...statusTab, more: false }))
    .catch((err) => window.alert(`${err} ( please enter the input data correctly! )`))
    .finally(() => {
        setAlertModal({ ...alertModal, show: true, action: statusTab?.more === false ? "success" : "error", loading: false, type: "more" })
        setIsDisabled({ ...isDisabled, map: true })
    });
  };

  return (
    <CRUDLayout>
        <TableLayout create label={menuTitle} withBackButton onClick={() => history.goBack()}> 
            <div className="d-flex p-1">
                <Card style={{ width: "30%", borderRadius: "0px" }}>
                    <Card.Header style={personalStyle}>PERSONAL DATA</Card.Header>
                    <Card.Body>
                        <Formik 
                            enableReinitialize 
                            initialValues={formPersonalValues(generateCode?.customer_code)} 
                            validationSchema={formValidationPersonal} 
                            onSubmit={(values) => formSubmitPersonalData(values)}
                        >
                            {(formik) => ( <Form><FormPersonalData setFileValue={setFileValue} isDisabled={isDisabled?.personal} /></Form> )}
                        </Formik>
                    </Card.Body>
                </Card>
                <Card style={{ width: "70%", borderRadius: "0px" }}>
                    <Tab.Container id="left-tabs-example">
                        <Card.Header style={cardHeaderStyle}>
                            <Nav variant="pills" className="d-flex justify-content-start">
                                <Nav.Item>
                                    <Nav.Link style={tabHeaderStyle} disabled={statusTab?.profile} eventKey="profile">PROFILE</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link style={tabHeaderStyle} disabled={statusTab?.profile} eventKey="address">ADDRESS</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link style={tabHeaderStyle} disabled={statusTab?.profile} eventKey="map">MAP</Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </Card.Header>
                        <Card.Body>
                            <Tab.Content>
                                <Tab.Pane eventKey="profile">
                                    <Formik 
                                        enableReinitialize 
                                        initialValues={formProfileValues({})} 
                                        // validationSchema={formValidationProfile} 
                                        onSubmit={(values) => formSubmitProfile(values)}
                                    >
                                        {(formik) => ( <Form><FormProfile isDisabled={isDisabled?.profile} /></Form> )}
                                    </Formik>
                                </Tab.Pane>
                                <Tab.Pane eventKey="address">
                                    <Formik 
                                        enableReinitialize 
                                        initialValues={formAddressValues({})} 
                                        // validationSchema={formValidationAddress} 
                                        onSubmit={(values) => formSubmitAddress(values)}
                                    >
                                        {(formik) => ( <Form><FormAddress action="CREATE" isDisabled={isDisabled?.address} /></Form> )}
                                    </Formik>
                                </Tab.Pane>
                                <Tab.Pane eventKey="map">
                                    <Formik 
                                        enableReinitialize 
                                        initialValues={formMapValues({})} 
                                        // validationSchema={formValidationMap} 
                                        onSubmit={(values) => formSubmitMap(values)}
                                    >
                                        {(formik) => ( <Form><FormMap isDisabled={isDisabled?.map} /></Form> )}
                                    </Formik>
                                </Tab.Pane>
                            </Tab.Content>
                        </Card.Body>
                    </Tab.Container>
                </Card>
            </div>
        </TableLayout>
        
        <AlertModal action={statusTab?.profile === false ? "success" : "error"} show={alertModal?.show && alertModal?.type === "profil"} onHide={() => setAlertModal({ ...alertModal, show: false })} />
        <AlertModal action={statusTab?.address === false ? "success" : "error"} show={alertModal?.show && alertModal?.type === "address"} onHide={() => setAlertModal({ ...alertModal, show: false })} />
        <AlertModal action={statusTab?.map === false ? "success" : "error"} show={alertModal?.show && alertModal?.type === "map"} onHide={() => setAlertModal({ ...alertModal, show: false })} />
        <AlertModal action={statusTab?.more === false ? "success" : "error"} show={alertModal?.show && alertModal?.type === "more"} onHide={() => setAlertModal({ ...alertModal, show: false })} />
    </CRUDLayout>
  );
};

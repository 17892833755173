import React, { useState, useContext, useEffect } from "react"
import { Col } from "react-bootstrap"
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { IoLockClosedOutline, IoEyeOutline, IoEyeOffOutline } from "react-icons/io5"
import { HiOutlineOfficeBuilding } from "react-icons/hi"
import { AiOutlineMail } from "react-icons/ai"
import { RiLockPasswordLine } from "react-icons/ri"
import { Formik } from "formik"
import * as Yup from "yup"
import JWTDecode from "jwt-decode"
import { ActionButton } from "../../components"
import { AuthMethod, AuthContext } from "../../utilities"
import { AuthApi } from "../../api"
import Config from "../../config"
import "./SignUp.css"
import { AsyncPaginate } from "react-select-async-paginate";
import Axios from "axios"


const SignUp = () => {
  const { dispatch } = useContext(AuthContext)
  const searchParams = new URLSearchParams(window?.location?.search);
  const dataReceived = searchParams?.get("data");
  const checkLink = dataReceived ? dataReceived === "basic" ? 1 : dataReceived === "standard" ? 2 : dataReceived === "enterprise" ? 3 : "" : ""
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(true)
  const [dropdownIndustry, setDropdownindustry] = useState()
  const dropdownPackage = [
    { label: "Basic", value: 1 },
    { label: "Standard", value: 2 },
    { label: "Enterprise", value: 3 },
  ]

  const getData = () => {
    setIsLoading(true)

    Axios.all([AuthApi.dropdopwnTypeindistry(checkLink)])
      .then(Axios.spread(data => {
        const industry = data?.data?.payload ? data?.data?.payload?.map(val => ({ label: val?.type_industry_name, value: val?.type_industry_id })) : []

        setDropdownindustry(industry)
      }))
      // .catch((err) => window.alert(`Error: ${err?.message}`))
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    getData()

    return () => {
      setIsLoading(false)
    }
  }, [])

  const Input = props => (
    <div className="mb-3">
      <div className="input-group input-group-sm">
        <div className="input-group-prepend">
          <span className={`input-group-text bg-white ${props?.error ? 'border border-danger' : ''}`}>
            {props?.icon}
          </span>
        </div>
        <input 
          {...props}
          className={`form-control ${props.error ? 'is-invalid' : ''}`}
          style={{borderLeft: 'none'}}
        />
      </div>
      {props?.error && (
        <div class="text-danger">
          <small>{props?.errorText}</small>
        </div>
      )}
    </div>
  )
  const InputPassword = props => {
    const [showPass, setShowPass] = useState(false)

    return (
      <div className="mb-3">
        <div className="input-group input-group-sm">
          <div className="input-group-prepend">
            <span className={`input-group-text bg-white ${props?.error ? 'border border-danger' : ''}`}>
              {props?.icon}
            </span>
          </div>
          <input 
            {...props}
            type={showPass ? 'text' : 'password'}
            className={`form-control ${props?.error ? 'is-invalid' : ''}`}
            style={{
              borderLeft: 'none',
              borderRight: 'none',
            }}
          />
          <div className="input-group-append" 
            style={{cursor: 'pointer'}}
            onClick={() => setShowPass(!showPass)}
          >
            <span className={`input-group-text bg-white ${props?.error ? 'border border-danger' : ''}`}>
              {showPass ? <IoEyeOutline /> : <IoEyeOffOutline />}
            </span>
          </div>
        </div>
        {props?.error && (
          <div class="text-danger">
            <small>{props?.errorText}</small>
          </div>
        )}
      </div>
    )
  }
  const SelectSearch = ({
    label,
    name,
    placeholder,
    isDisabled,
    defaultValue,
    loading,
    option,
    error,
    errorText,
    wrapperClassName,
    wrapperStyle,
    noMargin,
    onChange,
    styles,
    ...rest
  }) => {
    const options = option;
  
    const loadOptions = async (search, prevOptions) => {
      let filteredOptions;
      if (!search) {
        filteredOptions = options;
      } else {
        const searchLower = search?.toLowerCase();
  
        filteredOptions = options?.filter(({ label }) => label?.toLowerCase()?.includes(searchLower));
      }
  
      const hasMore = filteredOptions?.length > prevOptions?.length + 25;
      const slicedOptions = filteredOptions?.slice(prevOptions?.length, prevOptions?.length + 25);
  
      return {
        options: slicedOptions,
        hasMore,
      };
    };
  
    return (
      <div className={`${noMargin ? "m-0" : "mb-3"} ${wrapperClassName}`} style={wrapperStyle}>
        <small style={{ fontFamily: "poppins medium" }}>{label ? label?.toUpperCase() : ""}</small>
        {loading ? (
          <AsyncPaginate
            {...rest}
            placeholder="Memuat data . . ."
            isDisabled={true}
            styles={{
              ...styles,
              control: (base) => ({
                ...base,
                minHeight: 28,
                maxHeight: 31,
                fontSize: 14,
              }),
              valueContainer: (base) => ({
                ...base,
                paddingTop: 1,
                paddingLeft: 5,
                margin: 0,
              }),
              dropdownIndicator: (base) => ({
                ...base,
                padding: 0,
                paddingLeft: 5,
                paddingRight: 5,
              }),
              menu: (base) => ({
                ...base,
                fontSize: 13,
              }),
            }}
          />
        ) : (
          <>
            <AsyncPaginate
              {...rest}
              name={name}
              loadOptions={loadOptions}
              placeholder={placeholder}
              onChange={onChange}
              classNamePrefix={error ? "react-select-invalid" : "react-select"}
              defaultValue={defaultValue}
              noOptionsMessage={() => "Tidak ada data"}
              loadingMessage={() => "Memuat data . . ."}
              isDisabled={isDisabled}
              styles={{
                ...styles,
                control: (base) => ({
                  ...base,
                  minHeight: 28,
                  maxHeight: 31,
                  fontSize: 14,
                }),
                valueContainer: (base) => ({
                  ...base,
                  paddingTop: 1,
                  paddingLeft: 5,
                  margin: 0,
                }),
                dropdownIndicator: (base) => ({
                  ...base,
                  padding: 0,
                  paddingLeft: 5,
                  paddingRight: 5,
                }),
                menu: (base) => ({
                  ...base,
                  fontSize: 13,
                  zIndex: 10,
                }),
              }}
            />
            <small className="text-danger">{errorText}</small>
          </>
        )}
      </div>
    );
  };
  const FormSection = () =>  {
    const formInitialValues = {
      company_name: "",
      company_account: "",
      package: "",
      type_industry_id: "",
      passwd: "",
      confirmPasswd: ""
    }
    const formValidationSchema = Yup.object().shape({
      company_name: Yup.string().required('Company name is required !'),
      company_account: Yup.string().email('Invalid email address !').required('Company account is required !'),
      package: Yup.string().required('Package name is required !'),
      type_industry_id: Yup.string().required('Type industry name is required !'),
      passwd: Yup.string().required("Password is required!"),
      confirmPasswd: Yup.string().oneOf([Yup.ref('passwd'), null], 'Password must be the same !').required('Password confirmation in required !'),
    })
    const formSubmitHandler = (value, { resetForm }) => {
      AuthApi.register(value)
        // .then((res) => window?.location?.reload())
        .then((res) => window.location.href = '/login')
        .catch((err) => Window?.alert(`Error: ${err?.message}`))
        // .finally(() => window.location.reload())
    }

    return(
      <Formik
        enableReinitialize
        initialValues={formInitialValues}
        validationSchema={formValidationSchema}
        onSubmit={formSubmitHandler}
      >
        {({ values, errors, touched, isSubmitting, handleChange, handleSubmit, setFieldValue }) => (
          <form onSubmit={handleSubmit}>
            <div className="label text-white text-center mb-3" style={{ fontSize: "30px" }}><b>{`PAKET ${dataReceived?.toUpperCase() ?? "-"}`}</b></div>
            <Input
              icon={<HiOutlineOfficeBuilding />}
              type="text"
              name="company_name"
              placeholder="company name"
              value={values.company_name}
              onChange={handleChange}
              error={Boolean(errors?.company_name && touched?.company_name)}
              errorText={Boolean(errors?.company_name && touched?.company_name) && errors?.company_name}
            />
            <Input
              icon={<AiOutlineMail />}
              type="email"
              name="company_account" 
              placeholder="Email"
              value={values.company_account}
              onChange={handleChange}
              error={Boolean(errors?.company_account && touched?.company_account)}
              errorText={Boolean(errors?.company_account && touched?.company_account) && errors?.company_account}
            />
            <SelectSearch
              name="package"
              placeholder="Select package"
              defaultValue={values?.package ? dropdownPackage?.find(item => item?.value === values?.package ?? checkLink) : []}
              option={dropdownPackage}
              onChange={(val) => setFieldValue("package", val.value)}
              error={Boolean(errors?.package && touched?.package)}
              errorText={Boolean(errors?.package && touched?.package) && errors?.package}
            />
            <SelectSearch
              name="type_industry_id"
              placeholder="Select industry"
              defaultValue={values?.type_industry_id ? dropdownIndustry?.find(item => item?.value === values?.type_industry_id) : []}
              option={dropdownIndustry ?? []}
              onChange={(val) => setFieldValue("type_industry_id", val.value)}
              error={Boolean(errors?.type_industry_id && touched?.type_industry_id)}
              errorText={Boolean(errors?.type_industry_id && touched?.type_industry_id) && errors?.type_industry_id}
            />
            <InputPassword
              icon={<IoLockClosedOutline />}
              type="text"
              name="passwd"
              placeholder="Password"
              value={values.passwd}
              onChange={handleChange}
              error={Boolean(errors?.passwd && touched?.passwd)}
              errorText={Boolean(errors?.passwd && touched?.passwd) && errors?.passwd}
            />
            <InputPassword
              icon={<RiLockPasswordLine />}
              type="text"
              name="confirmPasswd"
              placeholder="Konfirmasi password"
              value={values.confirmPasswd}
              onChange={handleChange}
              error={Boolean(errors?.confirmPasswd && touched?.confirmPasswd)}
              errorText={Boolean(errors?.confirmPasswd && touched?.confirmPasswd) && errors?.confirmPasswd}
            />
            <ActionButton 
              type="submit" 
              text="Create Account" 
              className="col mt-2"
              style={{ backgroundColor: "#B9A16A", border: "none" }} 
              loading={isSubmitting}
            />
            <div className="text-white text-center mt-3" style={{ fontSize: "10px" }}>
              <span style={{ color: "#7B8499" }}>By continuing you agree to the name</span> terms
              of service <br /> <span style={{ color: "#7B8499" }}>and</span> privacy policy.
            </div>
          </form>
        )}
      </Formik>
    )
  }
  

  return (
    <div
      className="d-flex justify-content-center align-items-center p-3"
      style={{height: '100vh', width: '100vw', backgroundColor: "#000000"}}
    >
      <Col xs={12} sm={6} md={4} lg={3} className="py-4 rounded shadow" style={{ backgroundColor: "#000000" }}>
        <img className="logo_login_true" src={Config?.LOGO} width={320} />
        <FormSection />
      </Col>
      <div className="logo_login ml-5" style={{ border: "0.5px solid", height: "250px", color: "#E8E8E8" }}></div>
      <img className="logo_login" src={Config?.LOGO} width={320} />
    </div>
  )
}
export default SignUp

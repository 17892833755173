// Form
import * as yup from "yup"

export const validationSchema = yup.object().shape({
  coa_code: yup.string().label("COUNTRY").required(),
  coa_name: yup.string().label("COUNTRY").required(),
  group_coa_id: yup.string().label("COUNTRY").required(),
  account_type_id: yup.string().label("COUNTRY").required(),
  coa_level: yup.string().label("COUNTRY").required(),
  parent_id: yup.string().label("COUNTRY").required(),
  header_level: yup.string().label("COUNTRY").required(),
  normal_pos: yup.string().label("COUNTRY").required(),
  opening_balance: yup.string().label("COUNTRY").required(),
  note: yup.string().label("COUNTRY").required(),
  report_type: yup.string().label("COUNTRY").required()
})
import { Fragment } from "react"
import { Input, TextArea } from "components"
import { Controller, useFormContext } from "react-hook-form"

export function FormSection({ isDisabled }) {
  const { control } = useFormContext()

  return (
    <Fragment>
      <Controller
        control={control}
        name="divisi_code"
        render={({ field, fieldState }) => (
          <Input
            {...field}
            label="CODE"
            error={fieldState.error}
            errorText={fieldState.error?.message}
            readOnly={isDisabled}
          />
        )}
      />

      <Controller
        control={control}
        name="divisi_name"
        render={({ field, fieldState }) => (
          <Input
            {...field}
            label="DIVISI"
            error={fieldState.error}
            errorText={fieldState.error?.message}
            readOnly={isDisabled}
          />
        )}
      />

      <Controller
        control={control}
        name="description"
        render={({ field, fieldState }) => (
          <TextArea
            {...field}
            label="DESCRIPTION"
            error={fieldState.error}
            errorText={fieldState.error?.message}
            disabled={isDisabled}
          />
        )}
      />
    </Fragment>
  )
}
import React, { useState, useEffect } from "react";
import { AlertModal, CRUDLayout, TableLayout, DataStatus } from "components";
import { Formik, Form } from "formik";
import { useHistory, useLocation } from "react-router-dom";
import { Card, Tab, Nav } from "react-bootstrap";
import "./CreateNewEmployee.css"
import Axios from "axios"
import CreateNewEmployeeApi from "./__CreateNewEmployeeApi__";
import { 
    FormAddress, 
    FormBank, 
    FormEducation, 
    FormPersonalData, 
    FormPrivilage, 
    FormProfile, 
    FormWorkStatus 
} from "./__CreateNewEmployeeComps__";
import { 
    formPersonalValues, 
    formProfileValues, 
    
    formAddressValues,
    formValidationAddress, 

    formValidationPersonal, 
    formValidationProfile, 

    formWorkStatusValues,
    formValidationWorkStatus,

    formEducationValues,
    formValidationEducation,

    formBankValues,
    formValidationBank,
    formPrivilageValues,
    formValidationPrivilage
} from "./__CreateNewEmployeeUtillities__";

export const CreateNewEmployeeUpdate = () => {
  const menuTitle = "EMPLOYEE REGISTRATION"
  const history = useHistory()
  const location = useLocation()
  const dataInfo = location?.state?.dataInfo?.employee_id
  const detailData = location?.state?.dataInfo

  const [profileList, setProfileList] = useState([])
  const [addressList, setAddressList] = useState([])
  const [workStatusList, setWorkStatusList] = useState([])
  const [educationList, setEducationList] = useState([])
  const [bankList, setBankList] = useState([])
  const [accessList, setAccessList] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [fileValue, setFileValue] = useState(null);
  const [employeeId, setEmployeeId] = useState({ id: "" });
  const [alertModal, setAlertModal] = useState({ show: false, action: "" })

  const getData = () => {
    setIsLoading(true);

    Axios.all([
      CreateNewEmployeeApi.getProfile(dataInfo),
      CreateNewEmployeeApi.getAddress(dataInfo),
      CreateNewEmployeeApi.getWorkStatus(dataInfo),
      CreateNewEmployeeApi.getEducation(dataInfo),
      CreateNewEmployeeApi.getBank(dataInfo),
      CreateNewEmployeeApi.getAccess(dataInfo),
    ])
      .then(Axios.spread((profile, address, status, education, bank, access) => {
        setProfileList(profile?.data?.payload)
        setAddressList(address?.data?.payload)
        setWorkStatusList(status?.data?.payload)
        setEducationList(education?.data?.payload)
        setBankList(bank?.data?.payload)
        setAccessList(access?.data?.payload)
      }))
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };
  useEffect(() => {
    getData();

    return () => setIsLoading(false)
  }, []);

  const personalStyle = { fontSize: "15px", fontFamily: 'poppins', paddingTop: "8.9px", paddingBottom: "8.9px", backgroundColor: "#D1D1D1" }
  const tabHeaderStyle = { borderRadius: "0px", paddingLeft: "20px", paddingRight: "20px" }
  const cardHeaderStyle = { paddingTop: "0px", paddingBottom: "0px", backgroundColor: "#D1D1D1" }

  const submitValidationHandler = (errors) =>
    new Promise((resolve, reject) => {
      const getError = Object.values(errors);

      if (getError.length > 0) {
        reject(getError);
      } else {
        resolve();
      }
  });
  const preSubmitHandler = (formik) => {
        const { values, validateForm, setTouched, setErrors, handleSubmit, setSubmitting } = formik;

        setSubmitting(true);
        validateForm().then(async (err) => {
        setErrors(err);
        setTouched(err);

        await submitValidationHandler(err, values)
            .then(() => handleSubmit())
            .catch((err) => window?.alert(err))
            .finally(() => setSubmitting(false));
        });
  };
  const formSubmitPersonalData = (values) => {

    const formData = new FormData();
    formData.append('employee_code', String(values?.employee_code));
    // formData.append('employee_name', String(values?.employee_name));
    formData.append('empoyee_name', String(values?.employee_name));
    formData.append('account', String(values?.account));
    formData.append('passwd', String(values?.passwd));
    // formData.append('confirm_passwd', String(values?.confirm_passwd));
    formData.append('Confirm_Passwd', String(values?.confirm_passwd));
    // formData.append('Customer_Foto', fileValue);
    formData.append(fileValue ? 'Employee_Foto' : '', fileValue);

    CreateNewEmployeeApi.updatePersonalData(formData, dataInfo )
    .then(res => {
        const employeeResponse = res?.data?.payload?.employee_id
        if (employeeResponse) {
            setEmployeeId({ ...employeeId, id: res?.data?.payload?.employee_id })
        }
    })
    .catch((err) =>  setAlertModal({ ...alertModal, show: true, action: "error" }))
    .finally(() => setAlertModal({ ...alertModal, show: true, action: "success" }));
  };
  const formSubmitProfile = (values) => {
    const nonEmptyValues = Object.fromEntries(Object.entries(values).filter(([key, value]) => value !== ""));

    CreateNewEmployeeApi.updateProfil(nonEmptyValues, dataInfo)
    .then(() => setAlertModal({ ...alertModal, show: true, action: "success" }))
    .catch((err) =>  setAlertModal({ ...alertModal, show: true, action: "error" }))
    .finally(() => setAlertModal({ ...alertModal, show: true, action: "success" }));
  };
  const formSubmitAddress = (values) => {
    const nonEmptyValues = Object.fromEntries(Object.entries(values).filter(([key, value]) => value !== ""));

    CreateNewEmployeeApi.updateAdress(nonEmptyValues, dataInfo)
    .then(() => setAlertModal({ ...alertModal, show: true, action: "success" }))
    .catch((err) =>  setAlertModal({ ...alertModal, show: true, action: "error" }))
    .finally(() => setAlertModal({ ...alertModal, show: true, action: "success" }));
  };
  const formSubmitWorkStatus = (values) => {
    const nonEmptyValues = Object.fromEntries(Object.entries(values).filter(([key, value]) => value !== ""));

    CreateNewEmployeeApi.updateWorkStatus(nonEmptyValues, dataInfo)
    .then(() => setAlertModal({ ...alertModal, show: true, action: "success" }))
    .catch((err) =>  setAlertModal({ ...alertModal, show: true, action: "error" }))
    .finally(() => setAlertModal({ ...alertModal, show: true, action: "success" }));
  };
  const formSubmitEducation = (values) => {
    const nonEmptyValues = Object.fromEntries(Object.entries(values).filter(([key, value]) => value !== ""));

    CreateNewEmployeeApi.updateEducation(nonEmptyValues, dataInfo)
    .then(() => setAlertModal({ ...alertModal, show: true, action: "success" }))
    .catch((err) =>  setAlertModal({ ...alertModal, show: true, action: "error" }))
    .finally(() => setAlertModal({ ...alertModal, show: true, action: "success" }));
  };
  const formSubmitBank = (values) => {
    const nonEmptyValues = Object.fromEntries(Object.entries(values).filter(([key, value]) => value !== ""));

    CreateNewEmployeeApi.updateBank(nonEmptyValues, dataInfo)
    .then(() => setAlertModal({ ...alertModal, show: true, action: "success" }))
    .catch((err) =>  setAlertModal({ ...alertModal, show: true, action: "error" }))
    .finally(() => setAlertModal({ ...alertModal, show: true, action: "success" }));
  };
  const formSubmitAccess = (values) => {
    const nonEmptyValues = Object.fromEntries(Object.entries(values).filter(([key, value]) => value !== ""));

    CreateNewEmployeeApi.updateAccess(nonEmptyValues, dataInfo)
    .then(() => setAlertModal({ ...alertModal, show: true, action: "success" }))
    .catch((err) =>  setAlertModal({ ...alertModal, show: true, action: "error" }))
    .finally(() => setAlertModal({ ...alertModal, show: true, action: "success" }));
  };

  return (
    <CRUDLayout>
        <TableLayout create label={menuTitle} withBackButton onClick={() => history.goBack()}>
            {isLoading
                ? <DataStatus loading={isLoading} text="Loading..." />
                : <div className="d-flex p-1">
                    <Card style={{ width: "30%", borderRadius: "0px" }}>
                        <Card.Header style={personalStyle}>PERSONAL DATA</Card.Header>
                        <Card.Body>
                            <Formik 
                                enableReinitialize 
                                initialValues={formPersonalValues(detailData)} 
                                validationSchema={formValidationPersonal} 
                                onSubmit={(values) => formSubmitPersonalData(values)}
                            >
                                {(formik) => ( <Form><FormPersonalData action="UPDATE" setFileValue={setFileValue} /></Form> )}
                            </Formik>
                        </Card.Body>
                    </Card>
                    <Card style={{ width: "70%", borderRadius: "0px" }}>
                        <Tab.Container id="left-tabs-example" defaultActiveKey="profile">
                            <Card.Header style={cardHeaderStyle}>
                                <Nav variant="pills" className="d-flex justify-content-start">
                                    <Nav.Item>
                                        <Nav.Link style={tabHeaderStyle} eventKey="profile">PROFILE</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link style={tabHeaderStyle} eventKey="address">ADDRESS</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link style={tabHeaderStyle} eventKey="work_status">WORK STATUS</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link style={tabHeaderStyle} eventKey="education">EDUCATION</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link style={tabHeaderStyle} eventKey="bank">BANK</Nav.Link>
                                    </Nav.Item>
                                    {/* <Nav.Item>
                                        <Nav.Link style={tabHeaderStyle} eventKey="privilege">PRIVILEGE</Nav.Link>
                                    </Nav.Item> */}
                                </Nav>
                            </Card.Header>
                            <Card.Body>
                                <Tab.Content>
                                    <Tab.Pane eventKey="profile">
                                        <Formik 
                                            enableReinitialize 
                                            initialValues={formProfileValues(profileList)} 
                                            // validationSchema={formValidationProfile} 
                                            onSubmit={(values) => formSubmitProfile(values)}
                                        >
                                            {(formik) => ( <Form><FormProfile action="UPDATE" onSubmit={() => preSubmitHandler(formik)} /></Form> )}
                                        </Formik>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="address">
                                        <Formik 
                                            enableReinitialize 
                                            initialValues={formAddressValues(addressList[0])} 
                                            // validationSchema={formValidationAddress} 
                                            onSubmit={(values) => formSubmitAddress(values)}
                                        >
                                            {(formik) => ( <Form><FormAddress action="UPDATE" dataInfo={addressList[0]} loading={isLoading} /></Form> )}
                                        </Formik>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="work_status">
                                        <Formik 
                                            enableReinitialize 
                                            initialValues={formWorkStatusValues(workStatusList[0])} 
                                            // validationSchema={formValidationWorkStatus} 
                                            onSubmit={(values) => formSubmitWorkStatus(values)}
                                        >
                                            {(formik) => ( <Form><FormWorkStatus onSubmit={() => preSubmitHandler(formik)}  /></Form> )}
                                        </Formik>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="education">
                                        <Formik 
                                            enableReinitialize 
                                            initialValues={formEducationValues(educationList[0])} 
                                            // validationSchema={formValidationEducation} 
                                            onSubmit={(values) => formSubmitEducation(values)}
                                        >
                                            {(formik) => ( <Form><FormEducation onSubmit={() => preSubmitHandler(formik)}  /></Form> )}
                                        </Formik>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="bank">
                                        <Formik 
                                            enableReinitialize 
                                            initialValues={formBankValues(bankList[0])} 
                                            // validationSchema={formValidationBank} 
                                            onSubmit={(values) => formSubmitBank(values)}
                                        >
                                            {(formik) => ( <Form><FormBank onSubmit={() => preSubmitHandler(formik)}  /></Form> )}
                                        </Formik>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="privilege">
                                        <Formik 
                                            enableReinitialize 
                                            initialValues={formPrivilageValues(accessList)} 
                                            // validationSchema={formValidationPrivilage} 
                                            onSubmit={(values) => formSubmitAccess(values)}
                                        >
                                            {(formik) => ( <Form><FormPrivilage action="UPDATE" accessList={accessList} /></Form> )}
                                        </Formik>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Card.Body>
                        </Tab.Container>
                    </Card>
                  </div>
            }
        </TableLayout>
        <AlertModal action={alertModal?.action} show={alertModal?.show} onHide={() => setAlertModal({ ...alertModal, show: false })} />
    </CRUDLayout>
  );
};

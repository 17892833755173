import React, { useState, useEffect } from "react";
import { useFormikContext } from "formik";
import { SaveButton, SelectSearch, Input } from "components";
import CreateNewEmployeeApi from "../__CreateNewEmployeeApi__";
import Axios from "axios";

export const FormWorkStatus = ({ action, isDisabled }) => {
  const { values, errors, touched, setValues, isSubmitting } = useFormikContext() ?? {}

  const [dropdownEmployeeStatus, setDropdownEmployeeStatus] = useState([]);
  const [dropdownGroupPosition, setDropdownGroupPosition] = useState([]);
  const [dropdownJobPosition, setDropdownJobPosition] = useState([]);
  const [dropdownMainLocation, setDropdownMainLocation] = useState([]);
  const [dropdownDepartement, setDropdownDepartement] = useState([]);
  const [dropdownDivisi, setDropdownDivisi] = useState([]);
  const dropdownGender = [
    { value: 1, label: "Man" },
    { value: 2, label: "Woman" },
  ]
  const [dropdownSalaryType, setDropdownSalaryType] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getData = () => {
    setIsLoading(true);

    Axios.all([ 
        CreateNewEmployeeApi.dropdownEmployeeStatus(),
        CreateNewEmployeeApi.dropdownGroupPosition(),
        CreateNewEmployeeApi.dropdownJobPosition(),
        CreateNewEmployeeApi.dropdownMainLocation(),
        CreateNewEmployeeApi.dropdownDepartement(),
        CreateNewEmployeeApi.dropdownDivisi(),
        CreateNewEmployeeApi.dropdownSalaryType(),
    ])
      .then(Axios.spread((employee, group, job, loct, departement, divisi, salary) => {
        const getEmployee = employee?.data?.payload ? employee?.data?.payload?.map(val => ({ label: val?.employee_status_name, value: val?.employee_status_id })) : []
        const getGroupPosition = group?.data?.payload ? group?.data?.payload?.map(val => ({ label: val?.group_position_name, value: val?.group_position_id })) : []
        const getJobPosition = job?.data?.payload ? job?.data?.payload?.map(val => ({ label: val?.job_position_name, value: val?.job_position_id })) : []
        const getMainLocation = loct?.data?.payload ? loct?.data?.payload?.map(val => ({ label: val?.location_name, value: val?.location_id })) : []
        const getDepartement = departement?.data?.payload ? departement?.data?.payload?.map(val => ({ label: val?.department_name, value: val?.department_id })) : []
        const getDivisi = divisi?.data?.payload ? divisi?.data?.payload?.map(val => ({ label: val?.divisi_name, value: val?.divisi_id })) : []
        const getSalaryType = salary?.data?.payload ? salary?.data?.payload?.map(val => ({ label: val?.salary_type_name, value: val?.salary_type_id })) : []

        setDropdownEmployeeStatus(getEmployee)
        setDropdownGroupPosition(getGroupPosition)
        setDropdownJobPosition(getJobPosition)
        setDropdownMainLocation(getMainLocation)
        setDropdownDepartement(getDepartement)
        setDropdownDivisi(getDivisi)
        setDropdownSalaryType(getSalaryType)
      }))
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };
  useEffect(() => {
    getData();

    return () => setIsLoading(false)
  }, []);

  if(action === "READ") {
    return (
        <>
        <SelectSearch
            label="Employee Status"
            placeholder={action !== "READ" && "Select employee status"}
            option={dropdownEmployeeStatus}
            value={values?.employee_status_id ? dropdownEmployeeStatus?.find(item => item?.value === values?.employee_status_id) : []}
            onChange={(val) => setValues({ ...values, employee_status_id: val?.value })}
            error={Boolean(errors?.employee_status_id && touched?.employee_status_id)}
            loading={isLoading}
            isDisabled={action === "READ"}
        />
        <Input
            label="Date Start"
            type="date"
            placeholder={action !== "READ" && "Input date start"}
            value={values?.date_start}
            onChange={(e) => setValues({ ...values, date_start: e?.target?.value })}
            error={Boolean(errors?.date_start && touched?.date_start)}
            errorText={Boolean(errors?.date_start && touched?.date_start) && errors?.date_start}
            readOnly={action === "READ"}
        />
        <Input
            label="Date End"
            type="date"
            placeholder={action !== "READ" && "Input date end"}
            value={values?.date_end}
            onChange={(e) => setValues({ ...values, date_end: e?.target?.value })}
            error={Boolean(errors?.date_end && touched?.date_end)}
            errorText={Boolean(errors?.date_end && touched?.date_end) && errors?.date_end}
            readOnly={action === "READ"}
        />
        <SelectSearch
            label="Group Position"
            placeholder={action !== "READ" && "Select group position"}
            option={dropdownGroupPosition}
            value={values?.group_position_id ? dropdownGroupPosition?.find(item => item?.value === values?.group_position_id) : []}
            onChange={(val) => setValues({ ...values, group_position_id: val?.value })}
            error={Boolean(errors?.group_position_id && touched?.group_position_id)}
            loading={isLoading}
            isDisabled={action === "READ"}
            readOnly={action === "READ"}
        />
        <SelectSearch
            label="Job Position"
            placeholder={action !== "READ" && "Select job position"}
            option={dropdownJobPosition}
            value={values?.job_position_id ? dropdownJobPosition?.find(item => item?.value === values?.job_position_id) : []}
            onChange={(val) => setValues({ ...values, job_position_id: val?.value })}
            error={Boolean(errors?.job_position_id && touched?.job_position_id)}
            loading={isLoading}
            isDisabled={action === "READ"}
        />
        <SelectSearch
            label="Main Location"
            placeholder={action !== "READ" && "Select main location"}
            option={dropdownMainLocation}
            value={values?.location_id ? dropdownMainLocation?.find(item => item?.value === values?.location_id) : []}
            onChange={(val) => setValues({ ...values, location_id: val?.value })}
            error={Boolean(errors?.location_id && touched?.location_id)}
            loading={isLoading}
            isDisabled={action === "READ"}
        />
        <SelectSearch
            label="Departement"
            placeholder={action !== "READ" && "Select departement"}
            option={dropdownDepartement}
            value={values?.department_id ? dropdownDepartement?.find(item => item?.value === values?.department_id) : []}
            onChange={(val) => setValues({ ...values, department_id: val?.value })}
            error={Boolean(errors?.department_id && touched?.department_id)}
            loading={isLoading}
            isDisabled={action === "READ"}
        />
        <SelectSearch
            label="Divisi"
            placeholder={action !== "READ" && "Select divisi"}
            option={dropdownDivisi}
            value={values?.divisi_id ? dropdownDivisi?.find(item => item?.value === values?.divisi_id) : []}
            onChange={(val) => setValues({ ...values, divisi_id: val?.value })}
            error={Boolean(errors?.divisi_id && touched?.divisi_id)}
            loading={isLoading}
            isDisabled={action === "READ"}
        />
        <SelectSearch
            label="Gender"
            placeholder={action !== "READ" && "Select gender"}
            option={dropdownGender}
            value={values?.gender ? dropdownGender?.find(item => item?.value === values?.gender) : []}
            onChange={(val) => setValues({ ...values, gender: val?.value })}
            error={Boolean(errors?.gender && touched?.gender)}
            loading={isLoading}
            isDisabled={action === "READ"}
        />
        <SelectSearch
            label="Salary Type"
            placeholder={action !== "READ" && "Select salary type"}
            option={dropdownSalaryType}
            value={values?.salary_type_id ? dropdownSalaryType?.find(item => item?.value === values?.salary_type_id) : []}
            onChange={(val) => setValues({ ...values, salary_type_id: val?.value })}
            error={Boolean(errors?.salary_type_id && touched?.salary_type_id)}
            loading={isLoading}
            isDisabled={action === "READ"}
        />
        {action !== "READ" && <div className="d-flex justify-content-end mt-3">
            <SaveButton icon forModal type="submit" loading={isSubmitting} />
        </div>}
    </>
    )
  }

  return (
    <>
        <SelectSearch
            label="Employee Status"
            placeholder={action !== "READ" && "Select employee status"}
            option={dropdownEmployeeStatus}
            // defaultValue={values?.employee_status_id ? dropdownEmployeeStatus?.find(item => item?.value === values?.employee_status_id) : []}
            value={values?.employee_status_id ? dropdownEmployeeStatus?.find(item => item?.value === values?.employee_status_id) : []}
            onChange={(val) => setValues({ ...values, employee_status_id: val?.value })}
            error={Boolean(errors?.employee_status_id && touched?.employee_status_id)}
            loading={isLoading}
            isDisabled={action === "READ"}
        />
        <Input
            label="Date Start"
            type="date"
            placeholder={action !== "READ" && "Input date start"}
            value={values?.date_start}
            onChange={(e) => setValues({ ...values, date_start: e?.target?.value })}
            error={Boolean(errors?.date_start && touched?.date_start)}
            errorText={Boolean(errors?.date_start && touched?.date_start) && errors?.date_start}
            readOnly={action === "READ"}
        />
        <Input
            label="Date End"
            type="date"
            placeholder={action !== "READ" && "Input date end"}
            value={values?.date_end}
            onChange={(e) => setValues({ ...values, date_end: e?.target?.value })}
            error={Boolean(errors?.date_end && touched?.date_end)}
            errorText={Boolean(errors?.date_end && touched?.date_end) && errors?.date_end}
            readOnly={action === "READ"}
        />
        <SelectSearch
            label="Group Position"
            placeholder={action !== "READ" && "Select group position"}
            option={dropdownGroupPosition}
            // defaultValue={values?.group_position_id ? dropdownGroupPosition?.find(item => item?.value === values?.group_position_id) : []}
            value={values?.group_position_id ? dropdownGroupPosition?.find(item => item?.value === values?.group_position_id) : []}
            onChange={(val) => setValues({ ...values, group_position_id: val?.value })}
            error={Boolean(errors?.group_position_id && touched?.group_position_id)}
            loading={isLoading}
            isDisabled={action === "READ"}
            readOnly={action === "READ"}
        />
        <SelectSearch
            label="Job Position"
            placeholder={action !== "READ" && "Select job position"}
            option={dropdownJobPosition}
            // defaultValue={values?.job_position_id ? dropdownJobPosition?.find(item => item?.value === values?.job_position_id) : []}
            value={values?.job_position_id ? dropdownJobPosition?.find(item => item?.value === values?.job_position_id) : []}
            onChange={(val) => setValues({ ...values, job_position_id: val?.value })}
            error={Boolean(errors?.job_position_id && touched?.job_position_id)}
            loading={isLoading}
            isDisabled={action === "READ"}
        />
        <SelectSearch
            label="Main Location"
            placeholder={action !== "READ" && "Select main location"}
            option={dropdownMainLocation}
            // defaultValue={values?.location_id ? dropdownMainLocation?.find(item => item?.value === values?.location_id) : []}
            value={values?.location_id ? dropdownMainLocation?.find(item => item?.value === values?.location_id) : []}
            onChange={(val) => setValues({ ...values, location_id: val?.value })}
            error={Boolean(errors?.location_id && touched?.location_id)}
            loading={isLoading}
            isDisabled={action === "READ"}
        />
        <SelectSearch
            label="Departement"
            placeholder={action !== "READ" && "Select departement"}
            option={dropdownDepartement}
            // defaultValue={values?.department_id ? dropdownDepartement?.find(item => item?.value === values?.department_id) : []}
            value={values?.department_id ? dropdownDepartement?.find(item => item?.value === values?.department_id) : []}
            onChange={(val) => setValues({ ...values, department_id: val?.value })}
            error={Boolean(errors?.department_id && touched?.department_id)}
            loading={isLoading}
            isDisabled={action === "READ"}
        />
        <SelectSearch
            label="Divisi"
            placeholder={action !== "READ" && "Select divisi"}
            option={dropdownDivisi}
            // defaultValue={values?.divisi_id ? dropdownDivisi?.find(item => item?.value === values?.divisi_id) : []}
            value={values?.divisi_id ? dropdownDivisi?.find(item => item?.value === values?.divisi_id) : []}
            onChange={(val) => setValues({ ...values, divisi_id: val?.value })}
            error={Boolean(errors?.divisi_id && touched?.divisi_id)}
            loading={isLoading}
            isDisabled={action === "READ"}
        />
        <SelectSearch
            label="Gender"
            placeholder={action !== "READ" && "Select gender"}
            option={dropdownGender}
            // defaultValue={values?.gender ? dropdownGender?.find(item => item?.value === values?.gender) : []}
            value={values?.gender ? dropdownGender?.find(item => item?.value === values?.gender) : []}
            onChange={(val) => setValues({ ...values, gender: val?.value })}
            error={Boolean(errors?.gender && touched?.gender)}
            loading={isLoading}
            isDisabled={action === "READ"}
        />
        <SelectSearch
            label="Salary Type"
            placeholder={action !== "READ" && "Select salary type"}
            option={dropdownSalaryType}
            // defaultValue={values?.salary_type_id ? dropdownSalaryType?.find(item => item?.value === values?.salary_type_id) : []}
            value={values?.salary_type_id ? dropdownSalaryType?.find(item => item?.value === values?.salary_type_id) : []}
            onChange={(val) => setValues({ ...values, salary_type_id: val?.value })}
            error={Boolean(errors?.salary_type_id && touched?.salary_type_id)}
            loading={isLoading}
            isDisabled={action === "READ"}
        />
        {action !== "READ" && <div className="d-flex justify-content-end mt-3">
            <SaveButton icon forModal type="submit" disabled={isDisabled} />
        </div>}
    </>
  );
};

import React, { useState, useEffect } from "react";
import { useFormikContext } from "formik";
import { SaveButton, SelectSearch, TextArea } from "components";
import Axios from "axios";
import { Row, Col } from "react-bootstrap"
import CreateNewCustomerApi from "../__CreateNewCustomerApi__";

export const FormAddress = ({ action, isDisabled }) => {
  const { values, errors, touched, setValues, isSubmitting } = useFormikContext() ?? {}

  const [dropdownAddressType, setDropdownAddressType] = useState([]);
  const [dropdownCountry, setDropdownCountry] = useState([]);
  const [dropdownProvince, setDropdownProvince] = useState([]);
  const [dropdownRegency, setDropdownRegency] = useState([]);
  const [dropdownSubdistrict, setDropdownSubdistrict] = useState([]);
  const [dropdownVillage, setDropdownVillage] = useState([]);

  const [dropdownProvinceSecond, setDropdownProvinceSecond] = useState([]);
  const [dropdownRegencySecond, setDropdownRegencySecond] = useState([]);
  const [dropdownSubdistrictSecond, setDropdownSubdistrictSecond] = useState([]);
  const [dropdownVillageSecond, setDropdownVillageSecond] = useState([]);
  const [isLoading, setIsLoading] = useState({
    country: false,
    province: false,
    regency: false,
    subdistrict: false,
    village: false,

    province_2: false,
    regency_2: false,
    subdistrict_2: false,
    village_2: false,
  });

  const getCountry = () => {
    setIsLoading({ ...isLoading, country: true });

    Axios.all([ 
        CreateNewCustomerApi.dropdownCountry(),
        CreateNewCustomerApi.dropdownAddressType()
    ])
      .then(Axios.spread((country, address) => {
        const getCountry = country?.data?.payload ? country?.data?.payload?.map(val => ({ label: val?.country_name, value: val?.country_id })) : []
        const getAddress = address?.data?.payload ? address?.data?.payload?.map(val => ({ label: val?.address_type_name, value: val?.address_type_id })) : []

        setDropdownCountry(getCountry)
        setDropdownAddressType(getAddress)
      }))
      .catch((err) => console.log(err))
      .finally(() => setIsLoading({ ...isLoading, country: false }));
  };
  const getProvince = (id) => {
    setIsLoading({ ...isLoading, province: true });

    CreateNewCustomerApi.dropdownProvince(id ?? values?.province_id)
      .then(province => {
        const getProvince = province?.data?.payload ? province?.data?.payload?.map(val => ({ label: val?.province_name, value: val?.province_id })) : []
        setDropdownProvince(getProvince)
      })
      .catch((err) => alert(err))
      .finally(() => setIsLoading({ ...isLoading, province: false }));
  };
  const getRegency = (id) => {
    setIsLoading({ ...isLoading, regency: true });

    CreateNewCustomerApi.dropdownRegencies(id ?? values?.regency_id)
      .then(regency => {
        const getRegency = regency?.data?.payload ? regency?.data?.payload?.map(val => ({ label: val?.regency_name, value: val?.regency_id })) : []
        setDropdownRegency(getRegency)
      })
      .catch((err) => alert(err))
      .finally(() => setIsLoading({ ...isLoading, regency: false }));
  };
  const getSubdistrict = (id) => {
    setIsLoading({ ...isLoading, subdistrict: true });

    CreateNewCustomerApi.dropdownSubdistricts(id ?? values?.subdistrict_id)
      .then(subdistrict => {
        const getSubdistrict = subdistrict?.data?.payload ? subdistrict?.data?.payload?.map(val => ({ label: val?.subdistrict_name, value: val?.subdistrict_id })) : []
        setDropdownSubdistrict(getSubdistrict)
      })
      .catch((err) => alert(err))
      .finally(() => setIsLoading({ ...isLoading, subdistrict: false }));
  };
  const getVillage = (id) => {
    setIsLoading({ ...isLoading, village: true });

    CreateNewCustomerApi.dropdownVillages(id ?? values?.village_id)
      .then(village => {
        const getVillage = village?.data?.payload ? village?.data?.payload?.map(val => ({ label: val?.village_name, value: val?.village_id })) : []
        setDropdownVillage(getVillage)
      })
      .catch((err) => alert(err))
      .finally(() => setIsLoading({ ...isLoading, village: false }));
  };

  const getProvinceSecond = (id) => {
    setIsLoading({ ...isLoading, province_2: true });

    CreateNewCustomerApi.dropdownProvince(id ?? values?.province_id_2)
      .then(province => {
        const getProvince = province?.data?.payload ? province?.data?.payload?.map(val => ({ label: val?.province_name, value: val?.province_id })) : []
        setDropdownProvinceSecond(getProvince)
      })
      .catch((err) => alert(err))
      .finally(() => setIsLoading({ ...isLoading, province_2: false }));
  };
  const getRegencySecond = (id) => {
    setIsLoading({ ...isLoading, regency_2: true });

    CreateNewCustomerApi.dropdownRegencies(id ?? values?.regency_id_2)
      .then(regency => {
        const getRegency = regency?.data?.payload ? regency?.data?.payload?.map(val => ({ label: val?.regency_name, value: val?.regency_id })) : []
        setDropdownRegencySecond(getRegency)
      })
      .catch((err) => alert(err))
      .finally(() => setIsLoading({ ...isLoading, regency_2: false }));
  };
  const getSubdistrictSecond = (id) => {
    setIsLoading({ ...isLoading, subdistrict_2: true });

    CreateNewCustomerApi.dropdownSubdistricts(id ?? values?.subdistrict_id_2)
      .then(subdistrict => {
        const getSubdistrict = subdistrict?.data?.payload ? subdistrict?.data?.payload?.map(val => ({ label: val?.subdistrict_name, value: val?.subdistrict_id })) : []
        setDropdownSubdistrictSecond(getSubdistrict)
      })
      .catch((err) => alert(err))
      .finally(() => setIsLoading({ ...isLoading, subdistrict_2: false }));
  };
  const getVillageSecond = (id) => {
    setIsLoading({ ...isLoading, village_2: true });

    CreateNewCustomerApi.dropdownVillages(id ?? values?.village_id_2)
      .then(village => {
        const getVillage = village?.data?.payload ? village?.data?.payload?.map(val => ({ label: val?.village_name, value: val?.village_id })) : []
        setDropdownVillageSecond(getVillage)
      })
      .catch((err) => alert(err))
      .finally(() => setIsLoading({ ...isLoading, village_2: false }));
  };
  useEffect(() => {
    getCountry();

    return () => setIsLoading(false)
  }, []);

  if(action === "READ") {
    return (
      <>
        <Row>
          <Col>
            <div className="mb-3"><b>FIRST ADDRESS:</b></div>
            <SelectSearch
              label="Address Type"
              placeholder={action !== "READ" && "Select address type"}
              value={values?.address_type_id ? dropdownAddressType?.find(item => item?.value === values?.address_type_id) : []}
              option={dropdownAddressType}
              onChange={(val) => setValues({ ...values, address_type_id: val?.value })}
              error={Boolean(errors?.address_type_id && touched?.address_type_id)}
              loading={isLoading?.address_type_id}
              isDisabled={action === "READ"}
            />
            <SelectSearch
              label="Country"
              placeholder={action !== "READ" && "Select country"}
              value={values?.country_id ? dropdownCountry?.find(item => item?.value === values?.country_id) : []}
              option={dropdownCountry}
              onChange={(val) => {
                  setValues({ ...values, country_id: val?.value })
                  getProvince (val?.value)
              }}
              error={Boolean(errors?.country_id && touched?.country_id)}
              loading={isLoading?.country}
              isDisabled={action === "READ"}
            />
            <SelectSearch
              label="Province"
              placeholder={action !== "READ" && "Select province"}
              option={dropdownProvince}
              value={values.province_id ? { value: values?.province_id, label: values?.province_name } : false }
              onChange={(val) => {
                setValues({ ...values, province_id: val?.value, province_name: val?.label })
                getRegency(val?.value)
              }}
              error={Boolean(errors?.province_id && touched?.province_id)}
              loading={isLoading?.province}
              isDisabled={!values?.country_id || action === "READ"}
            />
            <SelectSearch
              label="Regency"
              placeholder={action !== "READ" && "Select regency"}
              option={dropdownRegency}
              value={values.regency_id ? { value: values?.regency_id, label: values?.regency_name } : false }
              onChange={(val) => {
                setValues({ ...values, regency_id: val?.value, regency_name: val?.label })
                getSubdistrict(val?.value)
              }}
              error={Boolean(errors?.regency_id && touched?.regency_id)}
              loading={isLoading?.regency}
              isDisabled={!values?.province_id || action === "READ"}
            />
            <SelectSearch
              label="Subdistrict"
              placeholder={action !== "READ" && "Select subdistrict"}
              option={dropdownSubdistrict}
              value={ values.subdistrict_id ? { value: values?.subdistrict_id, label: values?.subdistrict_name } : false }
              onChange={(val) => {
                setValues({ ...values, subdistrict_id: val?.value, subdistrict_name: val?.label })
                getVillage(val?.value)
              }}
              error={Boolean(errors?.subdistrict_id && touched?.subdistrict_id)}
              loading={isLoading?.subdistrict}
              isDisabled={!values?.regency_id || action === "READ"}
            />
            <SelectSearch
              label="Village"
              placeholder={action !== "READ" && "Select village"}
              option={dropdownVillage}
              value={ values.village_id ? { value: values?.village_id, label: values?.village_name } : false }
              onChange={(val) => setValues({ ...values, village_id: val?.value, village_name: val?.label })}
              error={Boolean(errors?.village_id && touched?.village_id)}
              loading={isLoading?.village}
              isDisabled={!values?.subdistrict_id || action === "READ"}
            />
            <TextArea
              label="Address"
              type="text"
              placeholder={action !== "READ" && "Input address"}
              value={values?.address}
              onChange={(e) => setValues({ ...values, address: e?.target?.value })}
              error={Boolean(errors?.address && touched?.address)}
              errorText={Boolean(errors?.address && touched?.address) && errors?.address}
              rows={2}
              disabled={action === "READ"}
            />
          </Col>
          <Col>
            <div className="mb-3"><b>SECCOND ADDRESS:</b></div>
            <SelectSearch
              label="Address Type"
              placeholder={action !== "READ" && "Select address type"}
              value={values?.address_type_id_2 ? dropdownAddressType?.find(item => item?.value === values?.address_type_id_2) : []}
              option={dropdownAddressType}
              onChange={(val) => setValues({ ...values, address_type_id_2: val?.value })}
              error={Boolean(errors?.address_type_id_2 && touched?.address_type_id_2)}
              loading={isLoading?.address_type_id_2}
              isDisabled={action === "READ"}
            />
            <SelectSearch
              label="Country"
              placeholder={action !== "READ" && "Select country"}
              value={values?.country_id_2 ? dropdownCountry?.find(item => item?.value === values?.country_id_2) : []}
              option={dropdownCountry}
              onChange={(val) => {
                setValues({ ...values, country_id_2: val?.value })
                getProvinceSecond(val?.value)
              }}
              error={Boolean(errors?.country_id_2 && touched?.country_id_2)}
              loading={isLoading?.country}
              isDisabled={action === "READ"}
            />
            <SelectSearch
              label="Province"
              placeholder={action !== "READ" && "Select province"}
              option={dropdownProvinceSecond}
              value={values.province_id_2 ? { value: values?.province_id_2, label: values?.province_name_2 } : false }
              onChange={(val) => {
                setValues({ ...values, province_id_2: val?.value, province_name_2: val?.label })
                getRegencySecond(val?.value)
              }}
              error={Boolean(errors?.province_id_2 && touched?.province_id_2)}
              loading={isLoading?.province_2}
              isDisabled={!values?.country_id_2 || action === "READ"}
            />
            <SelectSearch
              label="Regency"
              placeholder={action !== "READ" && "Select regency"}
              option={dropdownRegencySecond}
              value={values.regency_id_2 ? { value: values?.regency_id_2, label: values?.regency_name_2 } : false }
              onChange={(val) => {
                  setValues({ ...values, regency_id_2: val?.value, regency_name_2: val?.label })
                  getSubdistrictSecond(val?.value)
              }}
              error={Boolean(errors?.regency_id_2 && touched?.regency_id_2)}
              loading={isLoading?.regency_2}
              isDisabled={!values?.province_id_2 || action === "READ"}
            />
            <SelectSearch
              label="Subdistrict"
              placeholder={action !== "READ" && "Select subdistrict"}
              option={dropdownSubdistrictSecond}
              value={ values.subdistrict_id_2 ? { value: values?.subdistrict_id_2, label: values?.subdistrict_name_2 } : false }
              onChange={(val) => {
                  setValues({ ...values, subdistrict_id_2: val?.value, subdistrict_name_2: val?.label })
                  getVillageSecond(val?.value)
              }}
              error={Boolean(errors?.subdistrict_id_2 && touched?.subdistrict_id_2)}
              loading={isLoading?.subdistrict_2}
              isDisabled={!values?.regency_id_2 || action === "READ"}
            />
            <SelectSearch
              label="Village"
              placeholder={action !== "READ" && "Select village"}
              option={dropdownVillageSecond}
              value={ values.village_id_2 ? { value: values?.village_id_2, label: values?.village_name_2 } : false }
              onChange={(val) => setValues({ ...values, village_id_2: val?.value, village_name_2: val?.label })}
              error={Boolean(errors?.village_id_2 && touched?.village_id_2)}
              loading={isLoading?.village_2}
              isDisabled={!values?.subdistrict_id_2 || action === "READ"}
            />
            <TextArea
              label="Address"
              type="text"
              placeholder={action !== "READ" && "Input address"}
              value={values?.address_2}
              onChange={(e) => setValues({ ...values, address_2: e?.target?.value })}
              error={Boolean(errors?.address_2 && touched?.address_2)}
              errorText={Boolean(errors?.address_2 && touched?.address_2) && errors?.address_2}
              rows={2}
              disabled={action === "READ"}
            />
          </Col>
        </Row>
      </>
    )
  }
  if(action === "UPDATE") {
    return (
      <>
        <Row>
          <Col>
            <div className="mb-3"><b>FIRST ADDRESS:</b></div>
            <SelectSearch
              label="Address Type"
              placeholder={action !== "READ" && "Select address type"}
              value={values?.address_type_id ? dropdownAddressType?.find(item => item?.value === values?.address_type_id) : []}
              option={dropdownAddressType}
              onChange={(val) => setValues({ ...values, address_type_id: val?.value })}
              error={Boolean(errors?.address_type_id && touched?.address_type_id)}
              loading={isLoading?.address_type_id}
              isDisabled={action === "READ"}
            />
            <SelectSearch
              label="Country"
              placeholder={action !== "READ" && "Select country"}
              value={values?.country_id ? dropdownCountry?.find(item => item?.value === values?.country_id) : []}
              option={dropdownCountry}
              onChange={(val) => {
                  setValues({ ...values, country_id: val?.value })
                  getProvince (val?.value)
              }}
              error={Boolean(errors?.country_id && touched?.country_id)}
              loading={isLoading?.country}
              isDisabled={action === "READ"}
            />
            <SelectSearch
              label="Province"
              placeholder={action !== "READ" && "Select province"}
              option={dropdownProvince}
              value={values.province_id ? { value: values?.province_id, label: values?.province_name } : false }
              onChange={(val) => {
                setValues({ ...values, province_id: val?.value, province_name: val?.label })
                getRegency(val?.value)
              }}
              error={Boolean(errors?.province_id && touched?.province_id)}
              loading={isLoading?.province}
              isDisabled={!values?.country_id || action === "READ"}
            />
            <SelectSearch
              label="Regency"
              placeholder={action !== "READ" && "Select regency"}
              option={dropdownRegency}
              value={values.regency_id ? { value: values?.regency_id, label: values?.regency_name } : false }
              onChange={(val) => {
                setValues({ ...values, regency_id: val?.value, regency_name: val?.label })
                getSubdistrict(val?.value)
              }}
              error={Boolean(errors?.regency_id && touched?.regency_id)}
              loading={isLoading?.regency}
              isDisabled={!values?.province_id || action === "READ"}
            />
            <SelectSearch
              label="Subdistrict"
              placeholder={action !== "READ" && "Select subdistrict"}
              option={dropdownSubdistrict}
              value={ values.subdistrict_id ? { value: values?.subdistrict_id, label: values?.subdistrict_name } : false }
              onChange={(val) => {
                setValues({ ...values, subdistrict_id: val?.value, subdistrict_name: val?.label })
                getVillage(val?.value)
              }}
              error={Boolean(errors?.subdistrict_id && touched?.subdistrict_id)}
              loading={isLoading?.subdistrict}
              isDisabled={!values?.regency_id || action === "READ"}
            />
            <SelectSearch
              label="Village"
              placeholder={action !== "READ" && "Select village"}
              option={dropdownVillage}
              value={ values.village_id ? { value: values?.village_id, label: values?.village_name } : false }
              onChange={(val) => setValues({ ...values, village_id: val?.value, village_name: val?.label })}
              error={Boolean(errors?.village_id && touched?.village_id)}
              loading={isLoading?.village}
              isDisabled={!values?.subdistrict_id || action === "READ"}
            />
            <TextArea
              label="Address"
              type="text"
              placeholder={action !== "READ" && "Input address"}
              value={values?.address}
              onChange={(e) => setValues({ ...values, address: e?.target?.value })}
              error={Boolean(errors?.address && touched?.address)}
              errorText={Boolean(errors?.address && touched?.address) && errors?.address}
              rows={2}
              disabled={action === "READ"}
            />
          </Col>
          <Col>
            <div className="mb-3"><b>SECCOND ADDRESS:</b></div>
            <SelectSearch
              label="Address Type"
              placeholder={action !== "READ" && "Select address type"}
              value={values?.address_type_id_2 ? dropdownAddressType?.find(item => item?.value === values?.address_type_id_2) : []}
              option={dropdownAddressType}
              onChange={(val) => setValues({ ...values, address_type_id_2: val?.value })}
              error={Boolean(errors?.address_type_id_2 && touched?.address_type_id_2)}
              loading={isLoading?.address_type_id_2}
              isDisabled={action === "READ"}
            />
            <SelectSearch
              label="Country"
              placeholder={action !== "READ" && "Select country"}
              value={values?.country_id_2 ? dropdownCountry?.find(item => item?.value === values?.country_id_2) : []}
              option={dropdownCountry}
              onChange={(val) => {
                setValues({ ...values, country_id_2: val?.value })
                getProvinceSecond(val?.value)
              }}
              error={Boolean(errors?.country_id_2 && touched?.country_id_2)}
              loading={isLoading?.country}
              isDisabled={action === "READ"}
            />
            <SelectSearch
              label="Province"
              placeholder={action !== "READ" && "Select province"}
              option={dropdownProvinceSecond}
              value={values.province_id_2 ? { value: values?.province_id_2, label: values?.province_name_2 } : false }
              onChange={(val) => {
                setValues({ ...values, province_id_2: val?.value, province_name_2: val?.label })
                getRegencySecond(val?.value)
              }}
              error={Boolean(errors?.province_id_2 && touched?.province_id_2)}
              loading={isLoading?.province_2}
              isDisabled={!values?.country_id_2 || action === "READ"}
            />
            <SelectSearch
              label="Regency"
              placeholder={action !== "READ" && "Select regency"}
              option={dropdownRegencySecond}
              value={values.regency_id_2 ? { value: values?.regency_id_2, label: values?.regency_name_2 } : false }
              onChange={(val) => {
                  setValues({ ...values, regency_id_2: val?.value, regency_name_2: val?.label })
                  getSubdistrictSecond(val?.value)
              }}
              error={Boolean(errors?.regency_id_2 && touched?.regency_id_2)}
              loading={isLoading?.regency_2}
              isDisabled={!values?.province_id_2 || action === "READ"}
            />
            <SelectSearch
              label="Subdistrict"
              placeholder={action !== "READ" && "Select subdistrict"}
              option={dropdownSubdistrictSecond}
              value={ values.subdistrict_id_2 ? { value: values?.subdistrict_id_2, label: values?.subdistrict_name_2 } : false }
              onChange={(val) => {
                  setValues({ ...values, subdistrict_id_2: val?.value, subdistrict_name_2: val?.label })
                  getVillageSecond(val?.value)
              }}
              error={Boolean(errors?.subdistrict_id_2 && touched?.subdistrict_id_2)}
              loading={isLoading?.subdistrict_2}
              isDisabled={!values?.regency_id_2 || action === "READ"}
            />
            <SelectSearch
              label="Village"
              placeholder={action !== "READ" && "Select village"}
              option={dropdownVillageSecond}
              value={ values.village_id_2 ? { value: values?.village_id_2, label: values?.village_name_2 } : false }
              onChange={(val) => setValues({ ...values, village_id_2: val?.value, village_name_2: val?.label })}
              error={Boolean(errors?.village_id_2 && touched?.village_id_2)}
              loading={isLoading?.village_2}
              isDisabled={!values?.subdistrict_id_2 || action === "READ"}
            />
            <TextArea
              label="Address"
              type="text"
              placeholder={action !== "READ" && "Input address"}
              value={values?.address_2}
              onChange={(e) => setValues({ ...values, address_2: e?.target?.value })}
              error={Boolean(errors?.address_2 && touched?.address_2)}
              errorText={Boolean(errors?.address_2 && touched?.address_2) && errors?.address_2}
              rows={2}
              disabled={action === "READ"}
            />
          </Col>
        </Row>
        {action !== "READ" && <div className="d-flex justify-content-end mt-3">
          <SaveButton icon forModal type="submit" />
        </div>}
      </>
    )
  }

  return (
    <>
      <Row>
        <Col>
          <div className="mb-3"><b>FIRST ADDRESS:</b></div>
          <SelectSearch
            label="Address Type"
            placeholder={action !== "READ" && "Select address type"}
            value={values?.address_type_id ? dropdownAddressType?.find(item => item?.value === values?.address_type_id) : []}
            option={dropdownAddressType}
            onChange={(val) => setValues({ ...values, address_type_id: val?.value })}
            error={Boolean(errors?.address_type_id && touched?.address_type_id)}
            loading={isLoading?.address_type_id}
            isDisabled={action === "READ"}
          />
          <SelectSearch
            label="Country"
            placeholder={action !== "READ" && "Select country"}
            value={values?.country_id ? dropdownCountry?.find(item => item?.value === values?.country_id) : []}
            option={dropdownCountry}
            onChange={(val) => {
                setValues({ ...values, country_id: val?.value })
                getProvince (val?.value)
            }}
            error={Boolean(errors?.country_id && touched?.country_id)}
            loading={isLoading?.country}
            isDisabled={action === "READ"}
          />
          <SelectSearch
            label="Province"
            placeholder={action !== "READ" && "Select province"}
            option={dropdownProvince}
            value={values.province_id ? { value: values?.province_id, label: values?.province_name } : false }
            onChange={(val) => {
              setValues({ ...values, province_id: val?.value, province_name: val?.label })
              getRegency(val?.value)
            }}
            error={Boolean(errors?.province_id && touched?.province_id)}
            loading={isLoading?.province}
            isDisabled={!values?.country_id || action === "READ"}
          />
          <SelectSearch
            label="Regency"
            placeholder={action !== "READ" && "Select regency"}
            option={dropdownRegency}
            value={values.regency_id ? { value: values?.regency_id, label: values?.regency_name } : false }
            onChange={(val) => {
              setValues({ ...values, regency_id: val?.value, regency_name: val?.label })
              getSubdistrict(val?.value)
            }}
            error={Boolean(errors?.regency_id && touched?.regency_id)}
            loading={isLoading?.regency}
            isDisabled={!values?.province_id || action === "READ"}
          />
          <SelectSearch
            label="Subdistrict"
            placeholder={action !== "READ" && "Select subdistrict"}
            option={dropdownSubdistrict}
            value={ values.subdistrict_id ? { value: values?.subdistrict_id, label: values?.subdistrict_name } : false }
            onChange={(val) => {
              setValues({ ...values, subdistrict_id: val?.value, subdistrict_name: val?.label })
              getVillage(val?.value)
            }}
            error={Boolean(errors?.subdistrict_id && touched?.subdistrict_id)}
            loading={isLoading?.subdistrict}
            isDisabled={!values?.regency_id || action === "READ"}
          />
          <SelectSearch
            label="Village"
            placeholder={action !== "READ" && "Select village"}
            option={dropdownVillage}
            value={ values.village_id ? { value: values?.village_id, label: values?.village_name } : false }
            onChange={(val) => setValues({ ...values, village_id: val?.value, village_name: val?.label })}
            error={Boolean(errors?.village_id && touched?.village_id)}
            loading={isLoading?.village}
            isDisabled={!values?.subdistrict_id || action === "READ"}
          />
          <TextArea
            label="Address"
            type="text"
            placeholder={action !== "READ" && "Input address"}
            value={values?.address}
            onChange={(e) => setValues({ ...values, address: e?.target?.value })}
            error={Boolean(errors?.address && touched?.address)}
            errorText={Boolean(errors?.address && touched?.address) && errors?.address}
            rows={2}
            disabled={action === "READ"}
          />
        </Col>
        <Col>
          <div className="mb-3"><b>SECCOND ADDRESS:</b></div>
          <SelectSearch
            label="Address Type"
            placeholder={action !== "READ" && "Select address type"}
            value={values?.address_type_id_2 ? dropdownAddressType?.find(item => item?.value === values?.address_type_id_2) : []}
            option={dropdownAddressType}
            onChange={(val) => setValues({ ...values, address_type_id_2: val?.value })}
            error={Boolean(errors?.address_type_id_2 && touched?.address_type_id_2)}
            loading={isLoading?.address_type_id_2}
            isDisabled={action === "READ"}
          />
          <SelectSearch
            label="Country"
            placeholder={action !== "READ" && "Select country"}
            value={values?.country_id_2 ? dropdownCountry?.find(item => item?.value === values?.country_id_2) : []}
            option={dropdownCountry}
            onChange={(val) => {
              setValues({ ...values, country_id_2: val?.value })
              getProvinceSecond(val?.value)
            }}
            error={Boolean(errors?.country_id_2 && touched?.country_id_2)}
            loading={isLoading?.country}
            isDisabled={action === "READ"}
          />
          <SelectSearch
            label="Province"
            placeholder={action !== "READ" && "Select province"}
            option={dropdownProvinceSecond}
            value={values.province_id_2 ? { value: values?.province_id_2, label: values?.province_name_2 } : false }
            onChange={(val) => {
              setValues({ ...values, province_id_2: val?.value, province_name_2: val?.label })
              getRegencySecond(val?.value)
            }}
            error={Boolean(errors?.province_id_2 && touched?.province_id_2)}
            loading={isLoading?.province_2}
            isDisabled={!values?.country_id_2 || action === "READ"}
          />
          <SelectSearch
            label="Regency"
            placeholder={action !== "READ" && "Select regency"}
            option={dropdownRegencySecond}
            value={values.regency_id_2 ? { value: values?.regency_id_2, label: values?.regency_name_2 } : false }
            onChange={(val) => {
                setValues({ ...values, regency_id_2: val?.value, regency_name_2: val?.label })
                getSubdistrictSecond(val?.value)
            }}
            error={Boolean(errors?.regency_id_2 && touched?.regency_id_2)}
            loading={isLoading?.regency_2}
            isDisabled={!values?.province_id_2 || action === "READ"}
          />
          <SelectSearch
            label="Subdistrict"
            placeholder={action !== "READ" && "Select subdistrict"}
            option={dropdownSubdistrictSecond}
            value={ values.subdistrict_id_2 ? { value: values?.subdistrict_id_2, label: values?.subdistrict_name_2 } : false }
            onChange={(val) => {
                setValues({ ...values, subdistrict_id_2: val?.value, subdistrict_name_2: val?.label })
                getVillageSecond(val?.value)
            }}
            error={Boolean(errors?.subdistrict_id_2 && touched?.subdistrict_id_2)}
            loading={isLoading?.subdistrict_2}
            isDisabled={!values?.regency_id_2 || action === "READ"}
          />
          <SelectSearch
            label="Village"
            placeholder={action !== "READ" && "Select village"}
            option={dropdownVillageSecond}
            value={ values.village_id_2 ? { value: values?.village_id_2, label: values?.village_name_2 } : false }
            onChange={(val) => setValues({ ...values, village_id_2: val?.value, village_name_2: val?.label })}
            error={Boolean(errors?.village_id_2 && touched?.village_id_2)}
            loading={isLoading?.village_2}
            isDisabled={!values?.subdistrict_id_2 || action === "READ"}
          />
          <TextArea
            label="Address"
            type="text"
            placeholder={action !== "READ" && "Input address"}
            value={values?.address_2}
            onChange={(e) => setValues({ ...values, address_2: e?.target?.value })}
            error={Boolean(errors?.address_2 && touched?.address_2)}
            errorText={Boolean(errors?.address_2 && touched?.address_2) && errors?.address_2}
            rows={2}
            disabled={action === "READ"}
          />
        </Col>
      </Row>
      {action !== "READ" && <div className="d-flex justify-content-end mt-3">
        <SaveButton icon forModal type="submit" disabled={isDisabled} />
      </div>}
    </>
  );
};
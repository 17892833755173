import React, { useState } from "react";
import { useFormikContext } from "formik";
import { Input, SaveButton } from "components";
import "../ImageUpload.css"

export const FormPersonalData = ({ action, setFileValue, loading, isDisabled }) => {
  const { values, errors, touched, setValues } = useFormikContext() ?? {}
  const [imageViews, setImageView] = useState({ file: null, view: null })
  const { REACT_APP_API_BASE_URL } = process.env

  const ImageProfile = () => (
    <div className="custom-file-upload fas">
      <div className="img-wrap" >
        <img 
          className="uploadFoto" 
          for="photo-upload" 
          src={imageViews?.view 
            ? imageViews?.view 
            : values?.Customer_Foto
            ? `${REACT_APP_API_BASE_URL}/getimage/?imgpath=${values?.Customer_Foto}`
            : "https://www.zedemy.com/wp-content/plugins/buddyboss-platform/bp-core/images/profile-avatar-buddyboss.png"
          }
        />
      </div>
    </div>
  )

  return (
    <>
      <div className="d-flex justify-content-center my-2"><ImageProfile /></div>
      <input 
        type="file" 
        onChange={(e) => {
          setFileValue(e.target.files[0])
          const selectedFile = e.target.files[0];
          console.log("SELECTFILE", selectedFile)

          // if(action === "UPDATE") {
          //   setFileValue(values?.Customer_Foto)
          // }

          if (selectedFile) {
            setImageView({ ...imageViews, file: selectedFile ?? values?.Customer_Foto});
            const reader = new FileReader();
            reader.onloadend = () => {setImageView({ ...imageViews, view: reader.result})};
            reader.readAsDataURL(selectedFile);
          } else {
            setImageView({ ...imageViews, file: null, view: null});
          }
        }}
      />
      <form autoComplete="off">
        <Input
          label="Employee Code"
          type="text"
          placeholder={action !== "READ" && "Input employee code"}
          value={values?.employee_code}
          onChange={(e) => setValues({ ...values, employee_code: e?.target?.value })}
          error={Boolean(errors?.employee_code && touched?.employee_code)}
          errorText={Boolean(errors?.employee_code && touched?.employee_code) && errors?.employee_code}
          readOnly
        />
      </form>
      <form autoComplete="off">
        <Input
          label="Full Name *"
          type="text"
          placeholder={action !== "READ" && "Input full name"}
          value={values?.employee_name}
          onChange={(e) => setValues({ ...values, employee_name: e?.target?.value })}
          error={Boolean(errors?.employee_name && touched?.employee_name)}
          errorText={Boolean(errors?.employee_name && touched?.employee_name) && errors?.employee_name}
          readOnly={action === "READ"}
        />
      </form>
      <form autoComplete="off">
        <Input
          label="Account *"
          type="text"
          autoComplete="off"
          placeholder={action !== "READ" && "Input account"}
          value={values?.account}
          onChange={(e) => setValues({ ...values, account: e?.target?.value })}
          error={Boolean(errors?.account && touched?.account)}
          errorText={Boolean(touched?.account && errors?.account) && errors?.account}
          readOnly={action === "READ"}
        />
      </form>
      <form autoComplete="off">
        <Input
          label="Password *"
          type="password"
          autoComplete="off"
          placeholder={action !== "READ" && "Input password"}
          value={values?.passwd}
          onChange={(e) => setValues({ ...values, passwd: e?.target?.value })}
          error={Boolean(errors?.passwd && touched?.passwd)}
          errorText={Boolean(errors?.passwd && touched?.passwd) && errors?.passwd}
          readOnly={action === "READ"}
        />
      </form>
      <form autoComplete="off">
        <Input
          label="Re-Password *"
          type="password"
          placeholder={action !== "READ" && "Input re-password"}
          value={values?.confirm_passwd}
          onChange={(e) => setValues({ ...values, confirm_passwd: e?.target?.value })}
          error={Boolean(errors?.confirm_passwd && touched?.confirm_passwd)}
          errorText={Boolean(errors?.confirm_passwd && touched?.confirm_passwd) && errors?.confirm_passwd}
          readOnly={action === "READ"}
        />
      </form>
      {action !== "READ" && <div className="d-flex justify-content-end mt-3">
        <SaveButton icon forModal type="submit" loading={loading} disabled={isDisabled} />
      </div>}
    </>
  );
};

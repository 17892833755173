export const formAddressValues = (data = {}) => ({
  address_type_id: data?.address_type_id ?? 1,
  country_id: data?.country_id ?? "",
  country_name: data?.country_name ?? "",
  province_id: data?.province_id ?? "",
  province_name: data?.province_name ?? "",
  regency_id: data?.regency_id ?? "",
  regency_name: data?.regency_name ?? "",
  subdistrict_id: data?.subdistrict_id ?? "",
  subdistrict_name: data?.subdistrict_name ?? "",
  village_id: data?.village_id ?? "",
  village_name: data?.village_name ?? "",
  address: data?.address ?? "",
  
  address_type_id_2: data?.address_type_id_2 ?? 2,
  country_id_2: data?.country_id_2 ?? "",
  country_name_2: data?.country_name_2 ?? "",
  province_id_2: data?.province_id_2 ?? "",
  province_name_2: data?.province_name_2 ?? "",
  regency_id_2: data?.regency_id_2 ?? "",
  regency_name_2: data?.regency_name_2 ?? "",
  subdistrict_id_2: data?.subdistrict_id_2 ?? "",
  subdistrict_name_2: data?.subdistrict_name_2 ?? "",
  village_id_2: data?.village_id_2 ?? "",
  village_name_2: data?.village_name_2 ?? "",
  address_2: data?.address_2 ?? "",
});
  
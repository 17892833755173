import React from 'react'
import { CardGroupList } from './CardGroup'
import { EmployeeList } from './Employee'
import { Tab, Tabs } from "react-bootstrap"

export const MainCardList = () => {
  return (
    <div style={{ marginTop: "153px", marginLeft: "-12px", marginRight: "-12px" }}>
        <Tabs defaultActiveKey="card_group" id="fill-tab-example" className="mb-3" fill>
            <Tab eventKey="card_group" title="Card Group">
                <CardGroupList />
            </Tab>
            <Tab eventKey="employee" title="Employee">
                <EmployeeList />
            </Tab>
        </Tabs>
    </div>
  )
}
import { Fragment, useState } from "react"
import { CRUDModalSection, CloseButton, ReadButton, InfoItemHorizontal } from "components"
import { FormProvider, useForm } from "react-hook-form"
import { baseData } from "../utils"
import { Row, Col } from "react-bootstrap"
import { CheckList } from "./CheckList"

export function Detail({ data }) {
  const [isOpen, setOpen] = useState(false)

  return (
    <Fragment>
      <ReadButton icon onClick={() => setOpen(true)} />
      {isOpen && <Modal data={data} toggle={() => setOpen(false)} />}
    </Fragment>
  )
}

function Modal({ data, toggle }) {
  // Form
  const { control, setValue } = useForm({
    defaultValues: data
  })

  return (
    <CRUDModalSection
      show
      size="lg"
      type="READ"
      title={baseData.title}
      onHide={toggle}
    >
      <FormProvider control={control} setValue={setValue}>
        <Row>
          <Col>
            <InfoItemHorizontal minWidth={200} label="Account Group" text={control?.group_coa_name ?? "-"} />
            <InfoItemHorizontal minWidth={200} label="Account Type" text={control?.account_type_name ?? "-"} />
            <InfoItemHorizontal minWidth={200} label="Level" text={control?.coa_level ?? "-"} />
            <InfoItemHorizontal minWidth={200} label="Account Number" text={`${control?.group_coa_code ?? ""}-${control?.coa_code ?? ""}` ?? "-"} />
            <InfoItemHorizontal minWidth={200} label="Normal Post" text={control?.normal_pos === 1 ? "Debet" : "Kredit" ?? "-"} />
            <InfoItemHorizontal minWidth={200} label="Note" text={control?.note ?? "-"} />
          </Col>
          <Col>
            <InfoItemHorizontal minWidth={200} label="Report Type" text={control?.report_type_name ?? "-"} />
            <InfoItemHorizontal minWidth={200} label="Parent" text={`${control?.group_coa_code ?? ""}-${control?.coa_code ?? ""}` ?? "-"} />
            <InfoItemHorizontal minWidth={200} label="Header Level" text={control?.header_level === 1 ? "Header" : control?.header_level === 2 ? "Sub Header" : "Detail" ?? "-"} />
            <InfoItemHorizontal minWidth={200} label="Account Name" text={control?.coa_name ?? "-"} />
            <InfoItemHorizontal minWidth={200} label="Opening Balance" text={control?.opening_balance ?? "-"} />
            <CheckList label="Cheque Account" checked={control?.cheque_coa} />
            <CheckList label="Link To Bugeting" checked={control?.budget_coa} />
          </Col>
        </Row>
        <hr className='my-3'/>
        <div className="mt-3 d-flex">
          <CloseButton label="Close" onClick={toggle} />
        </div>
      </FormProvider>
    </CRUDModalSection>
  )
}
// React
import { Fragment, useState } from "react"

// API
import Services from "services"

// Components
import { CRUDModalSection, SaveButton, UpdateButton } from "components"
import { FormSection } from "./FormSection"

// Form
import { FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

// Third-Party Libraries
import toast from "react-hot-toast"

// Utils
import { baseData, validationSchema } from "../utils"

export function Update({ data, onSuccess }) {
  // Hooks
  const [isOpen, setOpen] = useState(false)

  return (
    <Fragment>
      <UpdateButton icon onClick={() => setOpen(true)} />

      {isOpen && (
        <Modal
          data={data}
          toggle={() => setOpen(false)}
          onSuccess={() => {
            setOpen(false)
            onSuccess()
          }}
        />
      )}
    </Fragment>
  )
}

function Modal({ data, onSuccess, toggle }) {
  // Form
  const onSubmit = (value) => {
    return new Promise((resolve) => {
      toast.promise(
        Services.put(`/project/edit?project_id=${data.project_id}`, value),
        {
          loading: "Loading...",
          success: (res) => res.data.message,
          error: (err) => err.response.data.detail
        }
      ).then(onSuccess).catch(() => {}).finally(resolve)
    })
  }
  const { control, formState, handleSubmit, setValue } = useForm({
    defaultValues: data,
    resolver: yupResolver(validationSchema)
  })

  return (
    <CRUDModalSection
      show
      type="update"
      title={baseData.title}
      onHide={toggle}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormProvider control={control} setValue={setValue}>
          <FormSection />
          <SaveButton icon forModal type="submit" loading={formState.isSubmitting} />
        </FormProvider>
      </form>
    </CRUDModalSection>
  )
}
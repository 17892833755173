import React from 'react'
import { OverlayTrigger, Tooltip, Button } from 'react-bootstrap'
import { AiOutlinePlus } from 'react-icons/ai'

export const AddButton = ({onClick, tooltipText, tooltipPlacement, icon, label}) => {
  return (
    <OverlayTrigger
      placement={tooltipPlacement}
      overlay={
        <Tooltip>
          {tooltipText ? tooltipText : 'Add data'}
        </Tooltip>
      }
    >
      <Button 
        size="sm"
        className='d-flex align-items-center mr-1'
        style={{ backgroundColor: "#000000", border: "none" }}
        onClick={onClick}
      >
        {icon && <AiOutlinePlus className={label ? "mr-1" : ""} style={{ fontSize: '.9rem', color: "#FFFFFF" }} />}
        <div className='text-white'>{label ? label : icon ? "" : 'Add'}</div>
      </Button>
    </OverlayTrigger>
  )
}

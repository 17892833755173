import React, { useState, useEffect } from "react";
import { useFormikContext } from "formik";
import { InfoItemHorizontal, SelectSearch, Input, TextArea, CloseButton, SaveButton } from "components";
import Axios from "axios";
import TaxCodeApi from "../__TaxCodeApi__";

export const Content = ({ action, data, modalConfig, setModalConfig, onSubmit }) => {
  const { values, errors, touched, isSubmitting, setValues } = useFormikContext() ?? {}

  const [dropdownType, setDropdownType] = useState([]);
  const [dropdownCOACollect, setDropdownCOACollect] = useState([]);
  const [dropdownCOAPaid, setDropdownCOAPaid] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getData = () => {
    setIsLoading(true);

    Axios.all([
      TaxCodeApi.dropdownType(),
      TaxCodeApi.dropdownCOA(),
      TaxCodeApi.dropdownCOA()
    ])
      .then(Axios.spread((type, collect, paid) => {
        const getType = type?.data?.payload ? type?.data?.payload?.map(val => ({ label: val?.type_tax_name, value: val?.type_tax_id })) : []
        const getCoaCollect = collect?.data?.payload ? collect?.data?.payload?.map(val => ({ label: val?.coa_name, value: val?.coa_id })) : []
        const getCoaPaid = paid?.data?.payload ? paid?.data?.payload?.map(val => ({ label: val?.coa_name, value: val?.coa_id })) : []

        setDropdownType(getType)
        setDropdownCOACollect(getCoaCollect)
        setDropdownCOAPaid(getCoaPaid)
      }))
      .catch((err) => alert(err))
      .finally(() => setIsLoading(false));
  };
  useEffect(() => {
    getData();

    return () => setIsLoading(false)
  }, []);

  if (action === "READ") {
    return (
      <>
        <InfoItemHorizontal label="Code" text={data?.tax_code ?? "-"} />
        <InfoItemHorizontal label="Name" text={data?.tax_name ?? "-"} />
        <InfoItemHorizontal label="Type" text={data?.type_tax_name ?? "-"} />
        <InfoItemHorizontal label="Rate" text={data?.rate ?? "-"} />
        <InfoItemHorizontal label="COA Collect" text={`${data?.coa_collect_grp_code}-${data?.coa_collect_coa_code} ${data?.coa_collect_coa_name}` ?? "-"} />
        <InfoItemHorizontal label="COA Paid" text={`${data?.coa_paid_grp_code}-${data?.coa_paid_coa_code} ${data?.coa_paid_coa_name}` ?? "-"} />
        <InfoItemHorizontal label="Note" text={data?.note ?? "-"} />
      </>
    );
  }

  return (
    <>
      <Input
        label="Code"
        type="text"
        placeholder="Input code"
        value={values.tax_code}
        onChange={(e) => setValues({ ...values, tax_code: e?.target?.value })}
        error={Boolean(errors?.tax_code && touched?.tax_code)}
        errorText={Boolean(errors?.tax_code && touched?.tax_code) && errors?.tax_code}
      />
      <Input
        label="Name"
        type="text"
        placeholder="Input coa group"
        value={values.tax_name}
        onChange={(e) => setValues({ ...values, tax_name: e?.target?.value })}
        error={Boolean(errors?.tax_name && touched?.tax_name)}
        errorText={Boolean(errors?.tax_name && touched?.tax_name) && errors?.tax_name}
      />
      <SelectSearch
        label="Type"
        placeholder="Select item"
        defaultValue={values?.type_tax_id ? dropdownType?.find(item => item?.value === values?.type_tax_id) : []}
        option={dropdownType}
        onChange={(val) => setValues({ ...values, type_tax_id: val?.value })}
        error={Boolean(errors?.type_tax_id && touched?.type_tax_id)}
        errorText={Boolean(errors?.type_tax_id && touched?.type_tax_id) && errors?.type_tax_id}
        loading={isLoading}
      />
      <Input
        label="Rate"
        type="number"
        placeholder="Input coa group"
        value={values.rate}
        onChange={(e) => setValues({ ...values, rate: e?.target?.value })}
        error={Boolean(errors?.rate && touched?.rate)}
        errorText={Boolean(errors?.rate && touched?.rate) && errors?.rate}
      />
      <SelectSearch
        label="COA Collect"
        placeholder="Select item"
        defaultValue={values?.coa_collect_id ? dropdownCOACollect?.find(item => item?.value === values?.coa_collect_id) : []}
        option={dropdownCOACollect}
        onChange={(val) => setValues({ ...values, coa_collect_id: val?.value })}
        error={Boolean(errors?.coa_collect_id && touched?.coa_collect_id)}
        errorText={Boolean(errors?.coa_collect_id && touched?.coa_collect_id) && errors?.coa_collect_id}
        loading={isLoading}
      />
      <SelectSearch
        label="COA Paid"
        placeholder="Select item"
        defaultValue={values?.coa_paid_id ? dropdownCOAPaid?.find(item => item?.value === values?.coa_paid_id) : []}
        option={dropdownCOAPaid}
        onChange={(val) => setValues({ ...values, coa_paid_id: val?.value })}
        error={Boolean(errors?.coa_paid_id && touched?.coa_paid_id)}
        errorText={Boolean(errors?.coa_paid_id && touched?.coa_paid_id) && errors?.coa_paid_id}
        loading={isLoading}
      />
      <TextArea
        label="Note"
        type="text"
        placeholder="Input description"
        value={values.note}
        onChange={(e) => setValues({ ...values, note: e?.target?.value })}
        rows={2}
      />
      <hr className='my-3'/>
      <div className="mt-3 d-flex">
        <CloseButton icon onClick={() => setModalConfig({ ...modalConfig, show: false, action: "CREATE"  })} />
        <SaveButton icon forModal type="submit" loading={isSubmitting} onClick={onSubmit} />
      </div>
    </>
  );
};

const InfoItemHorizontal = ({ label, text, width, minWidth = 120, style, className }) => (
  <div className={`d-flex align-items-top ${className}`} style={style}>
    <div style={{ width: width ? width : 60, minWidth: minWidth, fontSize: 14 }}>
      <b style={{ fontFamily: "poppins medium" }}>{label ? label?.toUpperCase() : ""}</b>
    </div>
    <div className="pl-3 pr-2" style={{ fontSize: 14 }}>:</div>
    <div style={{ fontSize: 14 }}>
      {text}
    </div>
  </div>
)
export default InfoItemHorizontal
import React from 'react'
import { Dropdown } from 'react-bootstrap'
import "../Layout/Layout.css"

export const DropdownButton = ({ label, children, menuType, type }) => {
  const navButtonStyle = { 
    border: "none", 
    borderRadius: "0px", 
    borderLeft: '1px solid #767676',
    width: "100%", 
    transition: '0.3s', 
    backgroundColor: menuType === type && "#9A834E",
    marginBottom: "0px",
    paddingTop: "14px",
    paddingBottom: "14px"
  }
  
  return (
    <Dropdown style={{ width: "100%" }}>
      <Dropdown.Toggle 
        style={navButtonStyle} 
        variant="dark-gold" 
        id="dropdown-basic"
      >
        {label ? label.toUpperCase() : ""}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {children}
      </Dropdown.Menu>
    </Dropdown>
  )
}
import * as Yup from "yup";

export const formValidationAddress = Yup.object().shape({
    address_type_id: Yup.string().required("Address type is required !"),
    country_id: Yup.string().required("Country is required !"),
    province_id: Yup.string().required("Province is required !"),
    regency_id: Yup.string().required("Regency is required !"),
    subdistrict_id: Yup.string().required("Subdistrict is required !"),
    village_id: Yup.string().required("Village is required !"),
    
    address_type_id_2: Yup.string().required("Address type is required !"),
    country_id_2: Yup.string().required("Country is required !"),
    province_id_2: Yup.string().required("Province is required !"),
    regency_id_2: Yup.string().required("Regency is required !"),
    subdistrict_id_2: Yup.string().required("Subdistrict is required !"),
    village_id_2: Yup.string().required("Village is required !")
});

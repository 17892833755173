export const formProfileValues = (data = {}) => ({
  handphone: data?.handphone ?? "",
  email: data?.email ?? "",
  website: data?.website ?? "",
  card_label: data?.card_label ?? 3,
  group_card: data?.group_card ?? "",
  category_vendor_id: data?.category_vendor_id ?? "",
  source_from: data?.source_from ?? "",
  employee_id: data?.employee_id ?? "",
  media: data?.media ?? "",
  price_level_id: data?.price_level_vendor_id ?? "",
  payment_term_id: data?.payment_term_vendor_id ?? "",
  max_balance: data?.max_balance ?? "",
  bank_id: data?.bank_id ?? "",
  bank_account: data?.bank_account ?? "",
  bank_beneficiary: data?.bank_beneficiary ?? "",
  bank_branch: data?.bank_branch ?? "",
});
  
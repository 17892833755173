import * as Yup from "yup";

export const formValidationWorkStatus = Yup.object().shape({
    employee_status_id: Yup.string().required("Employee status is required !"),
    date_start: Yup.string().required("Date start is required !"),
    date_end: Yup.string().required("Date end is required !"),
    group_position_id: Yup.string().required("Group position is required !"),
    job_position_id: Yup.string().required("Job position is required !"),
    location_id: Yup.string().required("Main location is required !"),
    department_id: Yup.string().required("Departement is required !"),
    divisi_id: Yup.string().required("Divisi is required !"),
    gender: Yup.string().required("Gender is required !"),
    salary_type_id: Yup.string().required("Salary type is required !")
});

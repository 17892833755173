import * as Yup from "yup";

export const formValidationProfile = Yup.object().shape({
    type_nationality: Yup.string().required("Type of national is required !"),
    nomor: Yup.string().required("Number of national is required !"),
    birthdate: Yup.string().required("Date of birth is required !"),
    marital_status: Yup.string().required("Marital status is required !"),
    religion_id: Yup.string().required("Religion is required !"),
    handphone: Yup.string().required("Handphone is required !"),
    email: Yup.string().required("Email is required !"),
});

import * as Yup from "yup";

export const formValidationSchema = Yup.object().shape({
    coa_code: Yup.string().required("COA code is required !"),
    coa_name: Yup.string().required("COA name is required !"),
    account_type_id: Yup.string().required("Account type is required !"),
    coa_level: Yup.string().required("COA level is required !"),
    parent_id: Yup.string().required("Parent is required !"),
    header_level: Yup.string().required("Header level is required !"),
    normal_pos: Yup.string().required("Normal post is required !"),
    opening_balance: Yup.string().required("Opening balance is required !"),
    cheque_coa: Yup.string().required("Cheque COA is required !"),
    budget_coa: Yup.string().required("Budget COA is required !"),
    company_code: Yup.string().required("Company code is required !")
});

// React
import { useState } from "react"

export function useToggleParent() {
  // Hooks
  const [isActiveParent, setActive] = useState(false)

  const toggleParent = () => setActive(prev => !prev)

  return { isActiveParent, toggleParent }
}
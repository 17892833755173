import React from 'react'
import { Button } from 'react-bootstrap'
import { IoAddOutline } from 'react-icons/io5'

export const CreateButton = ({ onClick, label, icon, background }) => {
  return (
      <Button 
        variant="primary"
        style={{ backgroundColor: background ? background : "#0070C1", border: "none" }}
        onClick={onClick}
        className="mx-1 px-4"
      >
        {icon && <IoAddOutline size={18} className='mr-2' />}
        <span className="text-uppercase">
          {label ? label : 'Tambah'}
        </span>
      </Button>
  )
}

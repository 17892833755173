import React from 'react'
import { OverlayTrigger, Tooltip, Button } from 'react-bootstrap'
import { BiEdit } from 'react-icons/bi'

export const UpdateButton = ({ onClick, tooltipText, tooltipPlacement, icon, label, type, forModal }) => {
  return (
    <OverlayTrigger
      placement={tooltipPlacement}
      overlay={<Tooltip>{tooltipText ? tooltipText : 'Update data'}</Tooltip>}
    >
      <Button 
        size={ !forModal && "sm" }
        className='d-flex align-items-center ml-1'
        type={type}
        style={{ backgroundColor: forModal ? "black" : "transparent", borderRight: "100%", borderColor: "black" }}
        onClick={onClick}
      >
        {icon && <BiEdit className={`${!forModal && "text-black"} ${label ? "mr-1" : ""}`} />}
        <div className='text-uppercase'>{label ? label : icon ? "" : 'Update'}</div>
      </Button>
    </OverlayTrigger>
  )
}

import React from 'react'
import {
  IoSearchOutline
} from 'react-icons/io5'

const InputSearch = ({name, value, size, onChange, onSubmit}) => {
  return (
    <form onSubmit={onSubmit}>
      <div className="input-group input-group-merge mb-2">
          <div className="input-group-prepend">
              <span className="input-group-text bg-white" id="basic-addon-search2" style={{ borderTopLeftRadius: "9px", borderBottomLeftRadius: "9px" }}><IoSearchOutline /></span>
          </div>
          <input 
            type="text" 
            className={`form-control form-control-${size ? size : "sm"}`}
            name={name}
            placeholder="Cari..."
            onChange={onChange}
            value={value} 
            style={{ borderTopRightRadius: "9px", borderBottomRightRadius: "9px" }}
          />
      </div>
    </form>
  )
}

export default InputSearch

import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom"
import {
  CRUDLayout,
  DataStatus,
  UpdateButton,
  DeleteButton,
  Alert,
  THead,
  Tr,
  ThFixed,
  Th,
  TBody,
  TdFixed,
  Td,
  ReadButton,
  TableLayout,
  CRUDModalSection,
  CloseButton
} from "components";
import Axios from "axios";
import CreateNewCustomerApi from "./__CreateNewCustomerApi__"; 
import { IoIosWarning } from "react-icons/io";

export const CreateNewCustomerList = () => {
  const menuTitle = "CUSTOMER REGISTRATION"
  const history = useHistory()

  const [listCustomer, setListCustomer] = useState([]);
  const [generateCode, setGenerateCode] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [modalConfig, setModalConfig] = useState({
    show: false,
    action: "",
    data: {}
  });

  const getData = () => {
    setIsLoading(true);

    Axios.all([
      CreateNewCustomerApi.getList(),
      CreateNewCustomerApi.generateCode(),
    ])
      .then(Axios.spread((list, code) => {
        setListCustomer(list?.data?.payload)
        setGenerateCode(code?.data?.payload)
      }))
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };
  useEffect(() => {
    getData();

    return () => setIsLoading(false)
  }, []);

  const DeleteModal = () => {
    const deleteDataHandler = () => {
      CreateNewCustomerApi.delete(modalConfig?.data?.customer_id)
        .then(() => setModalConfig({ ...modalConfig, show: true, action: "DELETE_SUCCESS"  }))
        .catch((err) => setModalConfig({ ...modalConfig, show: true, action: "DELETE_ERROR", data: `Delete Error! (${err?.response?.data?.message})` }))
        .finally(() => {
          setModalConfig({ ...modalConfig, show: false, action: "DELETE"  })
          getData();
        });
    };

    return (
      <CRUDModalSection
        type="delete"
        deleteCenter
        title={menuTitle} 
        show={Boolean(modalConfig?.show && modalConfig?.action === "DELETE")}
        onHide={() => setModalConfig({ ...modalConfig, show: false, action: "DELETE"  })}
      >

        <div className="d-flex justify-content-center"><IoIosWarning style={{ fontSize: "150px", color: "#FFB534" }} /></div>
        <Alert textCenter text="Data akan dihapus secara permanen dan tidak dapat dikembalikan !" variant="danger" show={true} />

        <hr className='my-3'/>
        <div className="mt-3 d-flex">
          <CloseButton icon onClick={() => setModalConfig({ ...modalConfig, show: false, action: "UPDATE"  })} />
          <DeleteButton icon forModal label="Delete" onClick={deleteDataHandler} />
        </div>
      </CRUDModalSection>
    );
  };

  const Table = () => {
    return (
      <div className="px-3 pt-3">
        <CRUDLayout.Table>
          <THead>
            <Tr className="text-center">
              <ThFixed>No</ThFixed>
              <Th><div style={{ whiteSpace: "nowrap" }}>ID CUSTOMER</div></Th>
              <Th><div style={{ width: 300 }}>CUSTOMER</div></Th>
              <Th><div style={{ width: 300 }}>HANDPHONE</div></Th>
              <Th>EMAIL</Th>
              <Th>WEBSITE</Th>
              <Th>ADDRESS</Th>
              <ThFixed>ACTION</ThFixed>
            </Tr>
          </THead>
          <TBody>
            {listCustomer?.length < 0 
              ? <Tr><Td colSpan={8}><div className="text-center">Data Not Yet</div></Td></Tr>
              : listCustomer?.map((val, index) => {
                  return (
                    <Tr key={val.id}>
                      <TdFixed>{index + 1}</TdFixed>
                      <Td>{val.customer_code ?? "-"}</Td>
                      <Td>{val.customer_name ?? "-"}</Td>
                      <Td>{val.handphone ?? "-"}</Td>
                      <Td>{val.email ?? "-"}</Td>
                      <Td>{val.website ?? "-"}</Td>
                      <Td>{val.address ?? "-"}</Td>
                      <TdFixed >
                        <div className="d-flex">
                          <ReadButton icon onClick={() => history.push("/cards/transactions/create-new-customer/detail", { dataInfo: val })}/>
                          <UpdateButton icon onClick={() => history.push("/cards/transactions/create-new-customer/update", { dataInfo: val })} />
                          <DeleteButton icon onClick={() => setModalConfig({ ...modalConfig, show: true, action: "DELETE", data: val  })} />
                        </div>
                      </TdFixed>
                    </Tr>
                  );
                })
            }
          </TBody>
        </CRUDLayout.Table>
      </div>
    );
  };

  return (
    <CRUDLayout>
      {isLoading 
        ? <DataStatus frontPage loading={isLoading} text="Loading..." />
        : <TableLayout label={menuTitle} withCreateButton onClick={() => history.push("/cards/transactions/create-new-customer/create", { dataInfo: generateCode })}>
            <Table />
          </TableLayout>
      }
      <DeleteModal />
    </CRUDLayout>
  );
};

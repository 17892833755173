import Services from "services";

class CreateNewEmployeeApi {
  getList(params) {
    return Services.get("/employee/", { params });
  }
  checkAccount(account, id) {
    return Services.post(`/employee/checkaccount?account=${account}&employee_id=${id}`);
  }
  generateCode(params) {
    return Services.get("/employee/empcode", { params });
  }
  getListByFilter(departemen, job, location) {
    if(departemen && job && location) {
      return Services.get(`employee/bydeptjoblocation${departemen ? `?department=${departemen}` : ""}${job ? `&jobpos=${job}` : ""}${location ? `&location=${location}` : ""}`);
    }else{
      return Services.get(`employee/bydeptjoblocation`)
    }
  }
  getProfile(id) {
    return Services.get(`/employee/profile?employee_id=${id}`);
  }
  getAddress(id) {
    return Services.get(`/employee/address?employee_id=${id}`);
  }
  getWorkStatus(id) {
    return Services.get(`/employee/workstatus?employee_id=${id}`);
  }
  getEducation(id) {
    return Services.get(`/employee/education?employee_id=${id}`);
  }
  getBank(id) {
    return Services.get(`/employee/bank?employee_id=${id}`);
  }
  getAccess(id) {
    return Services.get(`/employee/access?employee_id=${id}`);
  }
  
  dropdownFeature(id) {
    return Services.get(`feature/?menu_id=${id}`);
  }
  dropdownMenu(id) {
    return Services.get(`menu/?module_id=${id}`);
  }
  dropdownModule(params) {
    return Services.get("/module/", { params });
  }
  dropdownMainLocation(params) {
    return Services.get("/location/", { params });
  }
  dropdownBank(params) {
    return Services.get("/bank/", { params });
  }
  dropdownDoctoral(params) {
    return Services.get("/doctoral/", { params });
  }
  dropdownMagister(params) {
    return Services.get("/magister/", { params });
  }
  dropdownUniversity(params) {
    return Services.get("/university/", { params });
  }
  dropdownAcademy(params) {
    return Services.get("/academy/", { params });
  }
  dropdownSMA(params) {
    return Services.get("/sma/", { params });
  }
  dropdownSMP(params) {
    return Services.get("/smp/", { params });
  }
  dropdownSD(params) {
    return Services.get("/sd/", { params });
  }
  dropdownSalaryType(params) {
    return Services.get("/salarytype/", { params });
  }
  dropdownDivisi(params) {
    return Services.get("/divisi/", { params });
  }
  dropdownMainLocation(params) {
    return Services.get("/location/", { params });
  }
  dropdownJobPosition(params) {
    return Services.get("/jobposition/", { params });
  }
  dropdownGroupPosition(params) {
    return Services.get("/groupposition/", { params });
  }
  dropdownEmployeeStatus(params) {
    return Services.get("/employeestatus/", { params });
  }
  dropdownDepartement(params) {
    return Services.get("/department/", { params });
  }
  dropdownJobPosition(params) {
    return Services.get("/jobposition/", { params });
  }
  dropdownReligion(params) {
    return Services.get("/religion/", { params });
  }
  dropdownCountry(params) {
    return Services.get("/countries/", { params });
  }
  dropdownProvince(id) {
    return Services.get(`/provinces/?county_id=${id}`);
  }
  dropdownRegencies(id) {
    return Services.get(`/regencies/?province_id=${id}`);
  }
  dropdownSubdistricts(id) {
    return Services.get(`subdistricts/?regency_id=${id}`);
  }
  dropdownVillages(id) {
    return Services.get(`villages/?subdistrict_id=${id}`);
  }

  createPersonalData(data) {
    return Services.post("/employee/add", data);
  }
  updatePersonalData(data, id) {
    return Services.put(`/employee/edit?employee_id=${id}`, data);
  }
  updateProfil(data, id) {
    return Services.put(`/employee/updateprofile?employee_id=${id}`, data);
  }
  updateAdress(data, id) {
    return Services.put(`/employee/updateaddress?employee_id=${id}`, data);
  }
  updateWorkStatus(data, id) {
    return Services.put(`/employee/updateworkstatus?employee_id=${id}`, data);
  }
  updateEducation(data, id) {
    return Services.put(`/employee/updateeducation?employee_id=${id}`, data);
  }
  updateBank(data, id) {
    return Services.put(`/employee/updatebank?employee_id=${id}`, data);
  }
  updateAccess(data, id) {
    return Services.put(`/employee/updateaccess?employee_id=${id}`, data);
  }
  delete(id) {
    return Services.delete(`/employee/delete?employee_id=${id}`);
  }
}

export default new CreateNewEmployeeApi();

import React, { useState, useEffect } from "react";
import { useFormikContext } from "formik";
import { SaveButton, SelectSearch, TextArea } from "components";
import CreateNewEmployeeApi from "../__CreateNewEmployeeApi__";
import Axios from "axios";

export const FormAddress = ({ action, isDisabled, loading }) => {
  const { values, errors, touched, setValues, isSubmitting } = useFormikContext() ?? {}
  console.log("da", isDisabled)

  const [dropdownCountry, setDropdownCountry] = useState([]);
  const [dropdownProvince, setDropdownProvince] = useState([]);
  const [dropdownRegency, setDropdownRegency] = useState([]);
  const [dropdownSubdistrict, setDropdownSubdistrict] = useState([]);
  const [dropdownVillage, setDropdownVillage] = useState([]);
  const [isLoading, setIsLoading] = useState({
    country: false,
    province: false,
    regency: false,
    subdistrict: false,
    village: false,
  });

  const getCountry = () => {
    setIsLoading({ ...isLoading, country: true });

    Axios.all([ 
        CreateNewEmployeeApi.dropdownCountry()
    ])
      .then(Axios.spread(country => {
        const getCountry = country?.data?.payload ? country?.data?.payload?.map(val => ({ label: val?.country_name, value: val?.country_id })) : []

        setDropdownCountry(getCountry)
      }))
      .catch((err) => console.log(err))
      .finally(() => setIsLoading({ ...isLoading, country: false }));
  };
  const getProvince = (id) => {
    setIsLoading({ ...isLoading, province: true });

    CreateNewEmployeeApi.dropdownProvince(id ?? values?.province_id)
      .then(province => {
        const getProvince = province?.data?.payload ? province?.data?.payload?.map(val => ({ label: val?.province_name, value: val?.province_id })) : []
        setDropdownProvince(getProvince)
      })
      .catch((err) => alert(err))
      .finally(() => setIsLoading({ ...isLoading, province: false }));
  };
  const getRegency = (id) => {
    setIsLoading({ ...isLoading, regency: true });

    CreateNewEmployeeApi.dropdownRegencies(id ?? values?.regency_id)
      .then(regency => {
        const getRegency = regency?.data?.payload ? regency?.data?.payload?.map(val => ({ label: val?.regency_name, value: val?.regency_id })) : []
        setDropdownRegency(getRegency)
      })
      .catch((err) => alert(err))
      .finally(() => setIsLoading({ ...isLoading, regency: false }));
  };
  const getSubdistrict = (id) => {
    setIsLoading({ ...isLoading, subdistrict: true });

    CreateNewEmployeeApi.dropdownSubdistricts(id ?? values?.subdistrict_id)
      .then(subdistrict => {
        const getSubdistrict = subdistrict?.data?.payload ? subdistrict?.data?.payload?.map(val => ({ label: val?.subdistrict_name, value: val?.subdistrict_id })) : []
        setDropdownSubdistrict(getSubdistrict)
      })
      .catch((err) => alert(err))
      .finally(() => setIsLoading({ ...isLoading, subdistrict: false }));
  };
  const getVillage = (id) => {
    setIsLoading({ ...isLoading, village: true });

    CreateNewEmployeeApi.dropdownVillages(id ?? values?.village_id)
      .then(village => {
        const getVillage = village?.data?.payload ? village?.data?.payload?.map(val => ({ label: val?.village_name, value: val?.village_id })) : []
        setDropdownVillage(getVillage)
      })
      .catch((err) => alert(err))
      .finally(() => setIsLoading({ ...isLoading, village: false }));
  };
  useEffect(() => {
    getCountry();

    return () => setIsLoading(false)
  }, []);

  if(action === "READ") {
    return (
      <>
        <SelectSearch
          label="Country"
          placeholder={action !== "READ" && "Select country"}
          value={values?.country_id ? dropdownCountry?.find(item => item?.value === values?.country_id) : []}
          option={dropdownCountry}
          onChange={(val) => {
            setValues({ ...values, country_id: val?.value })
            getProvince(val.value);
          }}
          error={Boolean(errors?.country_id && touched?.country_id)}
          loading={isLoading?.country}
          isDisabled={action === "READ"}
        />
        <SelectSearch
          label="Province"
          placeholder={action !== "READ" && "Select province"}
          value={ values.province_id ? { value: values?.province_id, label: values?.province_name } : false }
          option={dropdownProvince}
          onChange={(val) => {
            setValues({ ...values, province_id: val?.value })
            getRegency(val.value);
          }}
          error={Boolean(errors?.province_id && touched?.province_id)}
          loading={isLoading?.province}
          isDisabled={!values?.country_id || action === "READ"}
        />
        <SelectSearch
          label="Regency"
          placeholder={action !== "READ" && "Select regency"}
          option={dropdownRegency}
          value={ values.regency_id ? { value: values?.regency_id, label: values?.regency_name } : false }
          onChange={(val) => {
              setValues({ ...values, regency_id: val?.value })
              getSubdistrict(val?.value)
          }}
          error={Boolean(errors?.regency_id && touched?.regency_id)}
          loading={isLoading?.regency}
          isDisabled={!values?.province_id || action === "READ"}
        />
        <SelectSearch
          label="Subdistrict"
          placeholder={action !== "READ" && "Select subdistrict"}
          option={dropdownSubdistrict}
          value={ values.subdistrict_id ? { value: values?.subdistrict_id, label: values?.subdistrict_name } : false }
          onChange={(val) => {
              setValues({ ...values, subdistrict_id: val?.value })
              getVillage(val?.value)
          }}
          error={Boolean(errors?.subdistrict_id && touched?.subdistrict_id)}
          loading={isLoading?.subdistrict}
          isDisabled={!values?.regency_id || action === "READ"}
        />
        <SelectSearch
          label="Village"
          placeholder={action !== "READ" && "Select village"}
          option={dropdownVillage}
          value={ values.village_id ? { value: values?.village_id, label: values?.village_name } : false }
          onChange={(val) => setValues({ ...values, village_id: val?.value })}
          error={Boolean(errors?.village_id && touched?.village_id)}
          loading={isLoading?.village}
          isDisabled={!values?.subdistrict_id || action === "READ"}
        />
        <TextArea
          label="Address"
          type="text"
          placeholder={action !== "READ" && "Input address"}
          value={values?.address}
          onChange={(e) => setValues({ ...values, address: e?.target?.value })}
          error={Boolean(errors?.address && touched?.address)}
          errorText={Boolean(errors?.address && touched?.address) && errors?.address}
          rows={2}
          disabled={action === "READ"}
        />
        {action !== "READ" && <div className="d-flex justify-content-end mt-3">
          <SaveButton icon forModal type="submit" />
        </div>}
      </>
    )
  }
  if(action === "CREATE") {
    return (
      <>
        <SelectSearch
          label="Country"
          placeholder={action !== "READ" && "Select country"}
          defaultValue={values?.country_id ? dropdownCountry?.find(item => item?.value === values?.country_id) : []}
          option={dropdownCountry}
          onChange={(val) => {
            setValues({ ...values, country_id: val?.value })
            getProvince(val.value);
          }}
          error={Boolean(errors?.country_id && touched?.country_id)}
          loading={isLoading?.country}
          isDisabled={action === "READ"}
        />
        <SelectSearch
          label="Province"
          placeholder={action !== "READ" && "Select province"}
          defaultValue={values?.province_id ? dropdownProvince?.find(item => item?.value === values?.province_id) : []}
          option={dropdownProvince}
          onChange={(val) => {
            setValues({ ...values, province_id: val?.value })
            getRegency(val.value);
          }}
          error={Boolean(errors?.province_id && touched?.province_id)}
          loading={isLoading?.province}
          isDisabled={!values?.country_id || action === "READ"}
        />
        <SelectSearch
          label="Regency"
          placeholder={action !== "READ" && "Select regency"}
          option={dropdownRegency}
          defaultValue={values?.regency_id ? dropdownRegency?.find(item => item?.value === values?.regency_id) : []}
          onChange={(val) => {
              setValues({ ...values, regency_id: val?.value })
              getSubdistrict(val?.value)
          }}
          error={Boolean(errors?.regency_id && touched?.regency_id)}
          loading={isLoading?.regency}
          isDisabled={!values?.province_id || action === "READ"}
        />
        <SelectSearch
          label="Subdistrict"
          placeholder={action !== "READ" && "Select subdistrict"}
          option={dropdownSubdistrict}
          defaultValue={values?.subdistrict_id ? dropdownSubdistrict?.find(item => item?.value === values?.subdistrict_id) : []}
          onChange={(val) => {
              setValues({ ...values, subdistrict_id: val?.value })
              getVillage(val?.value)
          }}
          error={Boolean(errors?.subdistrict_id && touched?.subdistrict_id)}
          loading={isLoading?.subdistrict}
          isDisabled={!values?.regency_id || action === "READ"}
        />
        <SelectSearch
          label="Village"
          placeholder={action !== "READ" && "Select village"}
          option={dropdownVillage}
          defaultValue={values?.village_id ? dropdownVillage?.find(item => item?.value === values?.village_id) : []}
          onChange={(val) => setValues({ ...values, village_id: val?.value })}
          error={Boolean(errors?.village_id && touched?.village_id)}
          loading={isLoading?.village}
          isDisabled={!values?.subdistrict_id || action === "READ"}
        />
        <TextArea
          label="Address"
          type="text"
          placeholder={action !== "READ" && "Input address"}
          value={values?.address}
          onChange={(e) => setValues({ ...values, address: e?.target?.value })}
          error={Boolean(errors?.address && touched?.address)}
          errorText={Boolean(errors?.address && touched?.address) && errors?.address}
          rows={2}
          disabled={action === "READ"}
        />
        {action !== "READ" && <div className="d-flex justify-content-end mt-3">
          <SaveButton icon forModal type="submit" disabled={isDisabled} />
        </div>}
      </>
    )
  }

  return (
    <>
      <SelectSearch
        label="Country"
        placeholder={action !== "READ" && "Select country"}
        // defaultValue={values?.country_id ? dropdownCountry?.find(item => item?.value === values?.country_id) : []}
        value={values?.country_id ? dropdownCountry?.find(item => item?.value === values?.country_id) : []}
        option={dropdownCountry}
        onChange={(val) => {
          setValues({ ...values, country_id: val?.value })
          getProvince(val.value);
        }}
        error={Boolean(errors?.country_id && touched?.country_id)}
        loading={isLoading?.country}
        isDisabled={action === "READ"}
      />
      <SelectSearch
        label="Province"
        placeholder={action !== "READ" && "Select province"}
        // defaultValue={values?.province_id ? dropdownProvince?.find(item => item?.value === values?.province_id) : []}
        value={values.province_id ? { value: values?.province_id, label: values?.province_name } : false }
        option={dropdownProvince}
        onChange={(val) => {
          setValues({ ...values, province_id: val?.value, province_name: val?.label })
          getRegency(val.value);
        }}
        error={Boolean(errors?.province_id && touched?.province_id)}
        loading={isLoading?.province}
        isDisabled={!values?.country_id || action === "READ"}
      />
      <SelectSearch
        label="Regency"
        placeholder={action !== "READ" && "Select regency"}
        option={dropdownRegency}
        // defaultValue={values?.regency_id ? dropdownRegency?.find(item => item?.value === values?.regency_id) : []}
        value={values.regency_id ? { value: values?.regency_id, label: values?.regency_name } : false }
        onChange={(val) => {
            setValues({ ...values, regency_id: val?.value, regency_name: val?.label })
            getSubdistrict(val?.value)
        }}
        error={Boolean(errors?.regency_id && touched?.regency_id)}
        // loading={isLoading?.regency}
        loading={isLoading?.country}
        isDisabled={!values?.province_id || action === "READ"}
      />
      <SelectSearch
        label="Subdistrict"
        placeholder={action !== "READ" && "Select subdistrict"}
        option={dropdownSubdistrict}
        // defaultValue={values?.subdistrict_id ? dropdownSubdistrict?.find(item => item?.value === values?.subdistrict_id) : []}
        value={ values.subdistrict_id ? { value: values?.subdistrict_id, label: values?.subdistrict_name } : false }
        onChange={(val) => {
            setValues({ ...values, subdistrict_id: val?.value, subdistrict_name: val?.label })
            getVillage(val?.value)
        }}
        error={Boolean(errors?.subdistrict_id && touched?.subdistrict_id)}
        // loading={isLoading?.subdistrict}
        loading={isLoading?.country}
        isDisabled={!values?.regency_id || action === "READ"}
      />
      <SelectSearch
        label="Village"
        placeholder={action !== "READ" && "Select village"}
        option={dropdownVillage}
        // defaultValue={values?.village_id ? dropdownVillage?.find(item => item?.value === values?.village_id) : []}
        value={ values.village_id ? { value: values?.village_id, label: values?.village_name } : false }
        onChange={(val) => setValues({ ...values, village_id: val?.value, village_name: val?.label })}
        error={Boolean(errors?.village_id && touched?.village_id)}
        loading={isLoading?.village}
        isDisabled={!values?.subdistrict_id || action === "READ"}
      />
      <TextArea
        label="Address"
        type="text"
        placeholder={action !== "READ" && "Input address"}
        value={values?.address}
        onChange={(e) => setValues({ ...values, address: e?.target?.value })}
        error={Boolean(errors?.address && touched?.address)}
        errorText={Boolean(errors?.address && touched?.address) && errors?.address}
        rows={2}
        disabled={action === "READ"}
      />
      {action !== "READ" && <div className="d-flex justify-content-end mt-3">
        <SaveButton icon forModal type="submit" />
      </div>}
    </>
  );
};

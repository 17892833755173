import React from 'react'

const TBody = ({children}) => {
  return (
    <tbody style={{ backgroundColor: "#F0F0F0" }}>
      {children}
    </tbody>
  )
}

export default TBody

export const formPrivilageValues = (data = {}) => ({
    module_id: data?.module_id ?? "",
    module_name: data?.module_name ?? "",
    menu_id: data?.menu_id ?? "",
    menu_name: data?.menu_name ?? "",
    feature_id: data?.feature_id ?? "",
    feature_name: data?.feature_name ?? "",
    create: data?.create ?? false,
    read: data?.read ?? false,
    update: data?.update ?? false,
    delete: data?.delete ?? false
  });
  
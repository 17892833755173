import React, { useState } from 'react'
import {
    CRUDLayout,
    DataStatus,
    UpdateButton,
    DeleteButton,
    Alert,
    THead,
    Tr,
    ThFixed,
    Th,
    TBody,
    TdFixed,
    Td,
    ReadButton,
    TableLayout,
    CRUDModalSection,
    AddButton,
    Input,
    SelectSearch,
    TextArea,
    DatePicker,
    InputSearch
} from "components";
import { PageNumber } from "utilities";

export const CardGroupList = () => {
    const [dummy, setDummy] = useState([{ id: 1, name: "test" }])
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [dataLength, setDataLength] = useState(10);
    const [totalData, setTotalData] = useState("");
    const [isUpdateForm, setIsUpdateForm] = useState(false);
    const [isDeleteData, setIsDeleteData] = useState(false);
    const [isDetailData, setIsDetailData] = useState(false);
    const [updateData, setUpdateData] = useState([]);
    const [deleteData, setDeleteData] = useState([]);

    const Table = () => {
        return (
          <div className="px-3 pt-3">
            <CRUDLayout.Table>
              <THead>
                <Tr className="text-center">
                  <ThFixed>No</ThFixed>
                  <Th><div style={{ width: 250 }}>COMPANY</div></Th>
                  <Th><div style={{ width: 300 }}>CONTACT FULL NAME</div></Th>
                  <Th><div style={{ width: 50 }}>ROLE</div></Th>
                  <Th>CONTACT NUMBER</Th>
                  <Th>BALANCE OWING</Th>
                  <ThFixed>ACTION</ThFixed>
                </Tr>
              </THead>
              <TBody>
                {dummy?.length < 0 
                  ? <Tr><Td className="text-center" colSpan={9}>Not data yet</Td></Tr> 
                  : dummy?.map((val, index) => {
                      return (
                        <Tr key={val.id}>
                          <TdFixed>{PageNumber(page, dataLength, index)}</TdFixed>
                          <Td>{val.name ?? "01"}</Td>
                          <Td>{val.name ?? "Group"}</Td>
                          <Td>{val.name ?? "Group"}</Td>
                          <Td><div style={{ textAlign: "left" }}>{val.name ?? "Type"}</div></Td>
                          <Td>{val.name ?? "Test"}</Td>
                          <TdFixed >
                            <div className="d-flex">
                              <ReadButton icon onClick={() => setIsDetailData(true)}/>
                              <UpdateButton
                                icon
                                onClick={() => {
                                    setUpdateData(val);
                                    setIsUpdateForm(true);
                                }}
                              />
                              <DeleteButton
                                icon
                                onClick={() => {
                                    setDeleteData(val);
                                    setIsDeleteData(true);
                                }} 
                              />
                            </div>
                          </TdFixed>
                        </Tr>
                      );
                })}
              </TBody>
            </CRUDLayout.Table>
          </div>
        );
      };
  return (
    <div className='bg-white pt-4' style={{ marginTop: "-14px" }}>
        <div className='d-flex justify-content-between align-items-end px-3'>
            <div style={{ width: "300px" }}>
                <SelectSearch
                    label="Group"
                    name="employee_status"
                    placeholder="Select group"
                    // onChange={(val) => setFieldValue("employee_status", val.value)}
                />
            </div>
            <div style={{ width: "300px" }}>
                <InputSearch
                // onChange={(e) => {
                //     setTimeout(() => {
                //     setSearchKey(e.target.value);
                //     setPage(1);
                //     }, 1000);
                // }}
                // onSubmit={(e) => e.preventDefault()}
                />
            </div>
        </div>

        <div>
            <Table />
        </div>
    </div>
  )
}
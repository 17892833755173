import React from 'react';
import { Input, Select, Space } from 'antd';
import ClockCircleOutlined from '@ant-design/icons/ClockCircleOutlined';

const { Option } = Select;
const selectBefore = (
//   <Select className='bg-light' style={{ borderRadius: "10px" }} defaultValue="http://">
  <Select defaultValue="http://">
    <Option value="http://">http://</Option>
    <Option value="https://">https://</Option>
  </Select>
);
const selectAfter = (
//   <Select className='bg-light' style={{ borderRadius: "10px" }} defaultValue=".com">
  <Select defaultValue=".com">
    <Option value=".com">.com</Option>
    <Option value=".jp">.jp</Option>
    <Option value=".cn">.cn</Option>
    <Option value=".org">.org</Option>
  </Select>
);

export const InputLink = ({ label, defaultValue, placeholder, onChange, error, errorText }) => (
    <div className='mb-2'>
        <small style={{ fontFamily: "poppins medium" }}>{label ? label?.toUpperCase() : ""}</small>
        <Space style={{ width: "100%" }} direction="vertical">
            <Input 
                addonBefore={selectBefore} 
                addonAfter={selectAfter} 
                defaultValue={defaultValue} 
                placeholder={placeholder}
                onChange={onChange}
                status={error && "error"}
                suffix={error &&  <ClockCircleOutlined />}
            />
        </Space>
        <div className="invalid-feedback">{errorText}</div>
    </div>
);
import { Alert as BSAlert, Spinner } from 'react-bootstrap'

export const Alert = ({show, variant, text, onClose, isLoading, showCloseButton, textCenter}) => (
  <div className='mt-4'>
    {show && (
      <BSAlert  dismissible={showCloseButton} variant={variant} onClose={onClose} >
        {isLoading && (<Spinner size="sm" animation="border" variant={variant} className="mr-2 mb-1" />)}
        <div style={{ fontSize: "14px" }} className={textCenter ? "text-center" : ""}>{text}</div>
      </BSAlert>
    )}
  </div>
)
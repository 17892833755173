import React, { useState, useEffect } from "react";
import { useFormikContext } from "formik";
import { SaveButton, SelectSearch, THead, Tr, Th, TBody, TdFixed, Td, CRUDLayout } from "components";
import CreateNewEmployeeApi from "../__CreateNewEmployeeApi__";
import { Button } from "react-bootstrap";
import { FaPlus } from "react-icons/fa";
import { CheckList } from "pages/Module/Accounts/Register/ShowAll/__ShowAllComps__/CheckList";

export const FormPrivilage = ({ action, onSubmit, accessList }) => {
  const { values, errors, touched, setValues, isSubmitting } = useFormikContext() ?? {}

  const [dropdownModule, setDropdownModule] = useState([]);
  const [dropdownMenu, setDropdownMenu] = useState([]);
  const [dropdownFeature, setDropdownFeature] = useState([]);
  const [dataValue, setDataValue] = useState([{
    module_name: "",
    menu_name: "",
    feature_name: "",
  }])
  const [isLoading, setIsLoading] = useState({
    module: false,
    menu: false,
    fetaure: false,
  });
  console.log("values", values)

  const getModule = () => {
    setIsLoading({ ...isLoading, module: true });

    CreateNewEmployeeApi.dropdownModule()
      .then(modules => {
        const getModule = modules?.data?.payload ? modules?.data?.payload?.map(val => ({ label: val?.module_name, value: val?.module_id })) : []

        setDropdownModule(getModule)
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading({ ...isLoading, module: false }));
  };
  const getMenu = (id) => {
    setIsLoading({ ...isLoading, menu: true });

    CreateNewEmployeeApi.dropdownMenu(id)
      .then(menu => {
        const getMenu = menu?.data?.payload ? menu?.data?.payload?.map(val => ({ label: val?.menu_name, value: val?.menu_id })) : []

        setDropdownMenu(getMenu)
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading({ ...isLoading, menu: false }));
  };
  const getFeature = (id) => {
    setIsLoading({ ...isLoading, fetaure: true });

    CreateNewEmployeeApi.dropdownFeature(id)
      .then(feature => {
        const getFeature = feature?.data?.payload ? feature?.data?.payload?.map(val => ({ label: val?.feature_name, value: val?.feature_id })) : []

        setDropdownFeature(getFeature)
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading({ ...isLoading, fetaure: false }));
  };
  useEffect(() => {
    getModule();
  }, []);

  const Table = () => {
    return (
      <div className="pt-3">
        <CRUDLayout.Table>
          <THead>
            <Tr className="text-center">
              <Th rowSpan={2}>No</Th>
              <Th rowSpan={2}>MODULE</Th>
              <Th rowSpan={2}>MENU</Th>
              <Th rowSpan={2}>FEATURE</Th>
              <Th colSpan={4}>ROLE</Th>
              {action !== "READ" && <Th rowSpan={2} width={80}>ACTION</Th>}
            </Tr>
            <Tr>
              <Th>CREATE</Th>
              <Th>READ</Th>
              <Th>UPDATE</Th>
              <Th>DELETE</Th>
            </Tr>
          </THead>
          <TBody>
            {dataValue?.length < 0 
              ? <Tr><Td className="text-center" colSpan={9}>Not data yet</Td></Tr> 
              // : dataValue?.map((val, index) => {
              : 
                    <Tr>
                      {/* <TdFixed>{index + 1}</TdFixed> */}
                      <TdFixed>1</TdFixed>
                      <Td>{dataValue?.module_name ?? "-"}</Td>
                      <Td>{dataValue?.menu_name ?? "-"}</Td>
                      <Td>{dataValue?.feature_name ?? "-"}</Td>
                      <Td className="text-center pl-4"><CheckList checked={values?.create} onChange={(e) => setValues({ ...values, create: e.target.checked })} /></Td>
                      <Td className="text-center pl-4"><CheckList checked={values?.read} onChange={(e) => setValues({ ...values, read: e.target.checked })} /></Td>
                      <Td className="text-center pl-4"><CheckList checked={values?.update} onChange={(e) => setValues({ ...values, update: e.target.checked })} /></Td>
                      <Td className="text-center pl-4"><CheckList checked={values?.delete} onChange={(e) => setValues({ ...values, delete: e.target.checked })} /></Td>
                      {action !== "READ" && <TdFixed>
                        <SaveButton 
                          forModal 
                          label="Delete" 
                          onClick={() => {
                            setDataValue({ ...dataValue, module_name: "", menu_name: "", feature_name: "" })
                            setValues({ ...values, create: false, read: false, update: false, delete: false })
                          }}
                        />
                      </TdFixed>}
                    </Tr>
            //       );
            // })
            }
          </TBody>
        </CRUDLayout.Table>
      </div>
    );
  };
  const TableUpdate = () => {
    return (
      <div className="pt-3">
        <CRUDLayout.Table>
          <THead>
            <Tr className="text-center">
              <Th rowSpan={2}>No</Th>
              <Th rowSpan={2}>MODULE</Th>
              <Th rowSpan={2}>MENU</Th>
              <Th rowSpan={2}>FEATURE</Th>
              <Th colSpan={4}>ROLE</Th>
              {action !== "READ" && <Th rowSpan={2} width={80}>ACTION</Th>}
            </Tr>
            <Tr>
              <Th>CREATE</Th>
              <Th>READ</Th>
              <Th>UPDATE</Th>
              <Th>DELETE</Th>
            </Tr>
          </THead>
          <TBody>
            {dataValue?.length < 0 
              ? <Tr><Td className="text-center" colSpan={9}>Not data yet</Td></Tr> 
              : accessList?.map((val, index) => {
                return (
                    <Tr>
                      <TdFixed>{index + 1}</TdFixed>
                      <Td>{val?.module_name ?? "-"}</Td>
                      <Td>{val?.menu_name ?? "-"}</Td>
                      <Td>{val?.feature_name ?? "-"}</Td>
                      <Td className="text-center pl-4"><CheckList checked={action === "UPDATE" ? val?.create : values?.create} onChange={(e) => setValues({ ...values, create: e.target.checked })} /></Td>
                      <Td className="text-center pl-4"><CheckList checked={action === "UPDATE" ? val?.read : values?.read} onChange={(e) => setValues({ ...values, read: e.target.checked })} /></Td>
                      <Td className="text-center pl-4"><CheckList checked={action === "UPDATE" ? val?.update : values?.update} onChange={(e) => setValues({ ...values, update: e.target.checked })} /></Td>
                      <Td className="text-center pl-4"><CheckList checked={action === "UPDATE" ? val?.delete : values?.delete} onChange={(e) => setValues({ ...values, delete: e.target.checked })} /></Td>
                      {action !== "READ" && <TdFixed>
                        <SaveButton 
                          forModal 
                          label="Delete" 
                          onClick={() => {
                            setDataValue({ ...dataValue, module_name: "", menu_name: "", feature_name: "" })
                            setValues({ ...values, create: false, read: false, update: false, delete: false })
                          }}
                        />
                      </TdFixed>}
                    </Tr>
                  );
            })
            }
          </TBody>
        </CRUDLayout.Table>
      </div>
    );
  };
  const TableDetail = () => {
    return (
      <div className="pt-3">
        <CRUDLayout.Table>
          <THead>
            <Tr className="text-center">
              <Th rowSpan={2}>No</Th>
              <Th rowSpan={2}>MODULE</Th>
              <Th rowSpan={2}>MENU</Th>
              <Th rowSpan={2}>FEATURE</Th>
              <Th colSpan={4}>ROLE</Th>
              {action !== "READ" && <Th rowSpan={2} width={80}>ACTION</Th>}
            </Tr>
            <Tr>
              <Th>CREATE</Th>
              <Th>READ</Th>
              <Th>UPDATE</Th>
              <Th>DELETE</Th>
            </Tr>
          </THead>
          <TBody>
            {dataValue?.length < 0 
              ? <Tr><Td className="text-center" colSpan={9}>Not data yet</Td></Tr> 
              : accessList?.map((val, index) => {
                return (
                    <Tr>
                      <TdFixed>{index + 1}</TdFixed>
                      <Td>{val?.module_name ?? "-"}</Td>
                      <Td>{val?.menu_name ?? "-"}</Td>
                      <Td>{val?.feature_name ?? "-"}</Td>
                      <Td className="text-center pl-4"><CheckList checked={action === "READ" ? val?.create : values?.create} onChange={(e) => setValues({ ...values, create: e.target.checked })} /></Td>
                      <Td className="text-center pl-4"><CheckList checked={action === "READ" ? val?.read : values?.read} onChange={(e) => setValues({ ...values, read: e.target.checked })} /></Td>
                      <Td className="text-center pl-4"><CheckList checked={action === "READ" ? val?.update : values?.update} onChange={(e) => setValues({ ...values, update: e.target.checked })} /></Td>
                      <Td className="text-center pl-4"><CheckList checked={action === "READ" ? val?.delete : values?.delete} onChange={(e) => setValues({ ...values, delete: e.target.checked })} /></Td>
                      {action !== "READ" && <TdFixed>
                        <SaveButton 
                          forModal 
                          label="Delete" 
                          onClick={() => {
                            setDataValue({ ...dataValue, module_name: "", menu_name: "", feature_name: "" })
                            setValues({ ...values, create: false, read: false, update: false, delete: false })
                          }}
                        />
                      </TdFixed>}
                    </Tr>
                  );
            })
            }
          </TBody>
        </CRUDLayout.Table>
      </div>
    );
  };

  return (
    <>
      {action !== "READ" && <div className="d-flex align-items-end">
        <div className="mr-2" style={{ width: "200px" }}>
          <SelectSearch
            label="Module"
            placeholder={action !== "READ" && "Select module"}
            option={dropdownModule}
            defaultValue={values?.module_id ? dropdownModule?.find(item => item?.value === values?.module_id) : []}
            onChange={(val) => {
              setValues({ ...values, module_id: val?.value, module_name: val?.label })
              getMenu(val?.value)
            }}
            error={Boolean(errors?.module_id && touched?.module_id)}
            loading={isLoading?.module}
            isDisabled={action === "READ"}
          />
        </div>
        <div className="mr-2" style={{ width: "200px" }}>
          <SelectSearch
            label="Menu"
            placeholder={action !== "READ" && "Select menu"}
            option={dropdownMenu}
            defaultValue={values?.menu_id ? dropdownMenu?.find(item => item?.value === values?.menu_id) : []}
            onChange={(val) => {
              setValues({ ...values, menu_id: val?.value, menu_name: val?.label })
              getFeature(val?.value)
            }}
            error={Boolean(errors?.menu_id && touched?.menu_id)}
            loading={isLoading?.menu}
            isDisabled={action === "READ" || !values?.module_id}
          />
        </div>
        <div className="mr-2" style={{ width: "200px" }}>
          <SelectSearch
            label="Feature"
            placeholder={action !== "READ" && "Select feature"}
            option={dropdownFeature}
            defaultValue={values?.feature_id ? dropdownFeature?.find(item => item?.value === values?.feature_id) : []}
            onChange={(val) => setValues({ ...values, feature_id: val?.value, feature_name: val?.label })}
            error={Boolean(errors?.feature_id && touched?.feature_id)}
            loading={isLoading?.fetaure}
            isDisabled={action === "READ" || !values?.menu_id}
          />
        </div>
        {action !== "READ" && <div style={{ marginBottom: "9px" }}>
          <Button 
            size="sm"
            className='d-flex align-items-center ml-1 p-2 px-3'
            style={{ backgroundColor: "black", border: "none", borderRadius: "6px" }}
            onClick={() => setDataValue({ 
              ...dataValue,
              module_name: values?.module_name,
              menu_name: values?.menu_name,
              feature_name: values?.feature_name,
            })}
          >
            {<FaPlus style={{ fontSize: '.9rem' }} />}
          </Button>
        </div>}
      </div>}
      {action !== "READ" && action !== "UPDATE" && <Table />}
      {action === "READ" && <TableDetail />}
      {action === "UPDATE" && <TableUpdate />}
      {action !== "READ" && <div className="d-flex justify-content-end mt-3">
        <SaveButton icon forModal type="submit" loading={isSubmitting} />
      </div>}
    </>
  );
};

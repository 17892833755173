import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom"
import {
  CRUDLayout,
  DataStatus,
  UpdateButton,
  DeleteButton,
  Alert,
  THead,
  Tr,
  ThFixed,
  Th,
  TBody,
  TdFixed,
  Td,
  ReadButton,
  TableLayout,
  CRUDModalSection,
  AddButton,
  Input,
  SelectSearch,
  TextArea,
  DatePicker,
  Checkbox
} from "components";
import { Formik } from "formik";
import * as Yup from "yup";
import Axios from "axios";
// import CreateNewEmployeeApi from "./__CreateNewEmployeeApi__";
import { PageNumber } from "utilities";
import { Content } from "./__SpendMoneyComps__";
import { Card, Col, Row, Button } from "react-bootstrap";
import { FaPlus } from "react-icons/fa";

export const SpendMoneyList = ({ setNavbarTitle }) => {
  const menuTitle = "SPEND MONEY"
  const history = useHistory()

  const [searchKey, setSearchKey] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isSearching, setIsSearching] = useState(false);
  const [isCreateForm, setIsCreateForm] = useState(false);
  const [isCreateFormTurunan, setIsCreateFormTurunan] = useState(false);
  const [isUpdateForm, setIsUpdateForm] = useState(false);
  const [isDeleteData, setIsDeleteData] = useState(false);
  const [isDetailData, setIsDetailData] = useState(false);

  const [data, setData] = useState([]);
  const [dummy, setDummy] = useState([{ id: 1, name: "test" }])

  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [dataLength, setDataLength] = useState(10);
  const [totalData, setTotalData] = useState("");
  
  const [updateData, setUpdateData] = useState([]);
  const [deleteData, setDeleteData] = useState([]);

  const [showAlert, setShowAlert] = useState(false);
  const [alertConfig, setAlertConfig] = useState({ variant: "primary", text: "" });

  // const getData = () => {
  //   setIsLoading(true);
  //   setIsSearching(false);

  //   Axios.all([CreateNewEmployeeApi.getPage(page, dataLength, searchKey)])
  //     .then(
  //       Axios.spread((res) => {
  //         setData(res.data.data);
  //         setTotalPage(res.data.total_page);
  //         setTotalData(res.data.data_count);
  //       })
  //     )
  //     .catch((err) => alert(err))
  //     .finally(() => {
  //       if (searchKey != "") {
  //         setAlertConfig({
  //           variant: "primary",
  //           text: `Hasil Pencarian : ${searchKey}`,
  //         });
  //         setShowAlert(true);
  //       }
  //       setIsLoading(false);
  //     });
  // };
  // const changeDataStatus = (status, id) => {
  //   setIsLoading(true);
  //   setShowAlert(false);

  //   const value = {
  //     id_buaso: id,
  //   };

  //   const onLoadedSuccess = () => {
  //     setIsSearching(false);
  //     setAlertConfig({
  //       variant: "primary",
  //       text: "Ubah status data berhasil",
  //     });
  //     setShowAlert(true);
  //   };

  //   const onLoadedFailed = () => {
  //     setIsSearching(false);
  //     setAlertConfig({
  //       variant: "danger",
  //       text: "Ubah status data gagal",
  //     });
  //     setShowAlert(true);
  //   };

  //   status === true
  //     ? CreateNewEmployeeApi.show(value)
  //         .then(() => onLoadedSuccess())
  //         .catch(() => onLoadedFailed())
  //         .finally(() => getData())
  //     : CreateNewEmployeeApi.hide(value)
  //         .then(() => onLoadedSuccess())
  //         .catch(() => onLoadedFailed())
  //         .finally(() => getData());
  // };
  // const searchData = (e) => {
  //   e.preventDefault();
  //   setIsLoading(true);

  //   const key = searchKey; // SearchKey Value as key

  //   // SEARCH DATA WITH KEY FROM DATABASE
  //   CreateNewEmployeeApi.search(key)
  //     .then((res) => {
  //       setData(res.data.data);
  //       setTotalPage(res.data.total_page);
  //     })
  //     .catch((err) => alert(err))
  //     .finally(() => {
  //       setIsSearching(true);
  //       setIsLoading(false);
  //       setAlertConfig({
  //         variant: "primary",
  //         text: `Hasil Pencarian : ${key}`,
  //       });
  //       setShowAlert(true);
  //     });
  // };

  useEffect(() => {
    setNavbarTitle("BUASO");
    // getData();

    return () => {
      setIsLoading(false);
      setIsSearching(false);
    };
  }, [setNavbarTitle, page, dataLength, searchKey]);

  const formValidationSchema = Yup.object().shape({
    kode_buaso: Yup.number().required("Masukkan kode").typeError("Kode hanya dapat berupa angka"),
    nama_buaso: Yup.string().required("Masukkan nama buaso"),
    // keterangan_buaso: Yup.string().required("Masukkan keterangan"),
  });

  const TambahModal = () => {
    const formInitialValues = {
      kode_buaso: "",
      nama_buaso: "",
      keterangan_buaso: "",
    };
    const formSubmitHandler = (values) => {
      // CreateNewEmployeeApi.create(values)
      //   .then((res) => {
      //     // SUCCESS ALERT
      //     setAlertConfig({
      //       variant: "primary",
      //       text: "Tambah data berhasil!",
      //     });
      //   })
      //   .catch((err) => {
      //     // const errMsg = Object.values(err.response?.data?.error) ?? []
      //     // ERROR ALERT
      //     setAlertConfig({
      //       variant: "danger",
      //       text: `Tambah data gagal! (${err.response.data.message})`,
      //       // text: `Tambah data gagal! <ul> ${errMsg.map(e => `<li>${e}</li>`).join("")} </ul>`
      //     });
      //   })
      //   .finally(() => {
      //     setIsCreateForm(false);
      //     setShowAlert(true);
      //     getData();
      //   });
    };

    return (
      <Formik initialValues={formInitialValues} validationSchema={formValidationSchema} onSubmit={formSubmitHandler}>
        {({ isSubmitting, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <CRUDModalSection size="lg" type="create" title={menuTitle} loading={isSubmitting} sumbitType="submit" show={isCreateForm} onHide={() => setIsCreateForm(false)}>
              <Content action="create" />
            </CRUDModalSection>
          </form>
        )}
      </Formik>
    );
  };
  const UbahModal = () => {
    const formInitialValues = {
      kode_buaso: updateData.kode_buaso,
      nama_buaso: updateData.nama_buaso,
      keterangan_buaso: updateData.keterangan_buaso,
    };
    const formSubmitHandler = (values) => {
      // const finalValues = {
      //   id_buaso: updateData.id_buaso,
      //   ...values,
      // };
      // CreateNewEmployeeApi.update(finalValues)
      //   .then((res) => {
      //     // SUCCESS ALERT
      //     setAlertConfig({
      //       variant: "primary",
      //       text: "Ubah data berhasil!",
      //     });
      //   })
      //   .catch((err) => {
      //     // const errMsg = Object.values(err.response?.data?.error) ?? []
      //     // ERROR ALERT
      //     setAlertConfig({
      //       variant: "danger",
      //       text: `Ubah data gagal! (${err.response.data.message})`,
      //       // text: `Ubah data gagal! <ul> ${errMsg.map(e => `<li>${e}</li>`).join("")} </ul>`
      //     });
      //   })
      //   .finally(() => {
      //     // CLOSE UPDATE MODAL FORM
      //     setIsUpdateForm(false);
      //     // SHOW ALERT
      //     setShowAlert(true);
      //     // FETCH DATA DARI SERVER
      //     getData();
      //   });
    };

    return (
      <Formik initialValues={formInitialValues} validationSchema={formValidationSchema} onSubmit={formSubmitHandler}>
        {({ isSubmitting, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <CRUDModalSection size="lg" type="update" title={menuTitle} loading={isSubmitting} sumbitType="submit" show={isUpdateForm} onHide={() => setIsUpdateForm(false)}>
              <Content action="update" />
            </CRUDModalSection>
          </form>
        )}
      </Formik>
    );
  };
  const ModalDetail = () => {
    return (
      <Formik>
        <CRUDModalSection type="read" title={menuTitle} show={isDetailData} onHide={() => setIsDetailData(false)}>
          <Content action="detail" />
        </CRUDModalSection>
      </Formik>
    )
  }
  const HapusModal = () => {
    const deleteValue = { id_buaso: deleteData.id_buaso };
    const [btnLoading, setBtnLoading] = useState(false);

    const deleteDataHandler = () => {
      setBtnLoading(true);

      // CreateNewEmployeeApi.delete(deleteValue)
      //   .then(() => {
      //     // KONFIGURASI ALERT
      //     setAlertConfig({
      //       variant: "primary",
      //       text: "Hapus data berhasil!",
      //     });
      //   })
      //   .catch((err) => {
      //     // KONFIGURASI ALERT
      //     setAlertConfig({
      //       variant: "danger",
      //       text: `Hapus data gagal!(${err.response.data.message})`,
      //     });
      //   })
      //   .finally(() => {
      //     // CLOSE MODAL
      //     setIsDeleteData(false);
      //     // TAMPIL ALERT
      //     setShowAlert(true);
      //     // FETCH DATA DARI SERVER
      //     getData();
      //   });
    };

    return (
      <Formik>
        <CRUDModalSection type="delete" title={menuTitle} show={isDeleteData} onHide={() => setIsDeleteData(false)} loading={btnLoading} onClick={deleteDataHandler}>
          <Content action="detail" />
        </CRUDModalSection>
      </Formik>
    );
  };

  const Table = () => {
    return (
      <div className="px-3 pt-3">
        <CRUDLayout.Table>
          <THead>
            <Tr className="text-center">
              <ThFixed>No</ThFixed>
              <ThFixed><div style={{ whiteSpace: "nowrap" }}>ACCOUNT EKPENSES</div></ThFixed>
              <ThFixed><div style={{ width: 300 }}>MEMO</div></ThFixed>
              <ThFixed><div style={{ width: 300 }}>AMMOUNT</div></ThFixed>
              <Th>JOB ID</Th>
              <Th>REFERENCES</Th>
              <Th>TAX CODE</Th>
              <ThFixed>ACTION</ThFixed>
            </Tr>
          </THead>
          <TBody>
            {dummy?.length < 0 
              ? <Tr><Td className="text-center" colSpan={9}>Not data yet</Td></Tr> 
              : dummy?.map((val, index) => {
                  return (
                    <Tr key={val.id}>
                      <TdFixed>{PageNumber(page, dataLength, index)}</TdFixed>
                      <TdFixed>{val.name ?? "01"}</TdFixed>
                      <TdFixed>{val.name ?? "Group"}</TdFixed>
                      <TdFixed>{val.name ?? "Group"}</TdFixed>
                      <TdFixed>{val.name ?? "Group"}</TdFixed>
                      <TdFixed><div style={{ textAlign: "left" }}>{val.name ?? "Type"}</div></TdFixed>
                      <Td>{val.name ?? "Test"}</Td>
                      <TdFixed >
                        <div className="d-flex">
                          <ReadButton icon onClick={() => setIsDetailData(true)}/>
                          <UpdateButton
                            icon
                            onClick={() => {
                                setUpdateData(val);
                                setIsUpdateForm(true);
                            }}
                          />
                          <DeleteButton
                            icon
                            onClick={() => {
                                setDeleteData(val);
                                setIsDeleteData(true);
                            }} 
                          />
                        </div>
                      </TdFixed>
                    </Tr>
                  );
            })}
          </TBody>
        </CRUDLayout.Table>
        {/* {!isSearching && (
          <Pagination
            dataLength={dataLength}
            dataPage={
              totalData <= 10
                ? data.length
                : data.map((res, index) => {
                    if (index == data.length - 1) {
                      return TableNumber(page, dataLength, index);
                    }
                  })
            }
            dataNumber={data.map((res, index) => {
              if (index == 0) {
                return TableNumber(page, dataLength, index);
              }
            })}
            dataCount={totalData}
            onDataLengthChange={(e) => {
              setDataLength(e.target.value);
              setPage(1);
            }}
            currentPage={page}
            totalPage={totalPage}
            onPaginationChange={({ selected }) => setPage(selected + 1)}
          />
        )} */}
      </div>
    );
  };
  const TableJournal = () => {
    return (
      <div className="px-3 pt-3">
        <CRUDLayout.Table>
          <THead>
            <Tr className="text-center">
              <ThFixed>DATE</ThFixed>
              <ThFixed><div style={{ whiteSpace: "nowrap" }}>IDM</div></ThFixed>
              <ThFixed><div style={{ width: 300 }}>ACCOUNT</div></ThFixed>
              <ThFixed><div style={{ width: 300 }}>DEBET</div></ThFixed>
              <Th>CREDIT</Th>
            </Tr>
          </THead>
          <TBody>
            {dummy?.length < 0 
              ? <Tr><Td className="text-center" colSpan={9}>Not data yet</Td></Tr> 
              : dummy?.map((val, index) => {
                  return (
                    <Tr key={val.id}>
                      <TdFixed>{PageNumber(page, dataLength, index)}</TdFixed>
                      <TdFixed>{val.name ?? "01"}</TdFixed>
                      <TdFixed>{val.name ?? "Group"}</TdFixed>
                      <TdFixed>{val.name ?? "Group"}</TdFixed>
                      <TdFixed>{val.name ?? "Group"}</TdFixed>
                    </Tr>
                  );
            })}
          </TBody>
        </CRUDLayout.Table>
      </div>
    );
  };

  return (
    <CRUDLayout>
      <Alert
        show={showAlert}
        showCloseButton={true}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() => setShowAlert(false)}
      />

      {/* {isLoading 
        ? <DataStatus loading={isLoading} text="Memuat Data" />
        : !Array.isArray(data) 
        ? <DataStatus text="Tidak dapat memuat data" />
        : data.length > 0 
        ? <TableLayout label={menuTitle} withCreateButton onClick={() => history.push("/cards/transactions/create-new-employee/create")}>
            <Table />
          </TableLayout>
        : <DataStatus text="Tidak ada data" />
      } */}
      <TableLayout infoButton label={menuTitle} onClick={() => history.push("/cards/transactions/create-new-customer/create")}>
        <div className="px-3 pt-3 d-flex justify-content-between">
          <div className="mr-2" style={{ width: 250 }}>
            <SelectSearch
              label="Account"
              placeholder="Select account"
              // onChange={(val) => setFieldValue("main_location", val.value)}
            />
          </div>
          <div className="mr-2" style={{ width: 250 }}>
            <Input
              label="Payment Detail"
              // onChange={(val) => setFieldValue("main_location", val.value)}
            />
          </div>
        </div>

        <div className="mx-3 my-3">
          <div className="d-flex">
            <div className="mr-1" style={{ width: "100%" }}>
              <Card style={{ height: "350px" }}>
                <Card.Body>
                  <Row>
                    <Col>
                      <SelectSearch
                        label="Account"
                        placeholder="Select account"
                        // onChange={(val) => setFieldValue("main_location", val.value)}
                      />
                    </Col>
                    <Col>
                      <SelectSearch
                        label="Account"
                        placeholder="Select account"
                        // onChange={(val) => setFieldValue("main_location", val.value)}
                      />
                    </Col>
                  </Row>
                  <SelectSearch
                    label="Account"
                    placeholder="Select account"
                    // onChange={(val) => setFieldValue("main_location", val.value)}
                  />
                  <TextArea label="Memo" rows={4} />
                </Card.Body>
              </Card>
            </div>
            <div className="ml-1" style={{ width: "100%" }}>
              <Card style={{ height: "350px" }}>
                <Card.Body>
                  <Input
                    label="Balance"
                    placeholder="Input balance"
                    // onChange={(val) => setFieldValue("main_location", val.value)}
                  />
                  <DatePicker
                    label="Transaction date"
                    placeholder="Select date"
                    // onChange={(val) => setFieldValue("main_location", val.value)}
                  />
                  <Input
                    label="Reference No."
                    placeholder="Input reference no"
                    // onChange={(val) => setFieldValue("main_location", val.value)}
                  />
                  <Input
                    label="Creadit"
                    placeholder="Input credit"
                    // onChange={(val) => setFieldValue("main_location", val.value)}
                  />
                  <Checkbox label="Tax Inclusive" />
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>

        <div className="d-flex align-items-end mx-3 mt-4">
          <div className="mr-2" style={{ width: "200px" }}>
            <SelectSearch
              label="Account Expenses"
              name="module"
              placeholder="Select module"
              // onChange={(val) => setFieldValue("module", val.value)}
              // error={errors.module && touched.module && true}
              // errorText={errors.module && touched.module && true}
            />
          </div>
          <div className="mr-2" style={{ width: "200px" }}>
            <Input
              label="Memo"
              name="menu"
              placeholder="Select menu"
              // onChange={(val) => setFieldValue("menu", val.value)}
              // error={errors.menu && touched.menu && true}
              // errorText={errors.menu && touched.menu && true}
            />
          </div>
          <div className="mr-2" style={{ width: "200px" }}>
            <Input
              label="Ammount"
              name="menu"
              placeholder="Select menu"
              // onChange={(val) => setFieldValue("menu", val.value)}
              // error={errors.menu && touched.menu && true}
              // errorText={errors.menu && touched.menu && true}
            />
          </div>
          <div className="mr-2" style={{ width: "200px" }}>
            <SelectSearch
              label="Project Id"
              name="module"
              placeholder="Select module"
              // onChange={(val) => setFieldValue("module", val.value)}
              // error={errors.module && touched.module && true}
              // errorText={errors.module && touched.module && true}
            />
          </div>
          <div className="mr-2" style={{ width: "200px" }}>
            <Input
              label="Reference"
              name="menu"
              placeholder="Select menu"
              // onChange={(val) => setFieldValue("menu", val.value)}
              // error={errors.menu && touched.menu && true}
              // errorText={errors.menu && touched.menu && true}
            />
          </div>
          <div className="mr-2" style={{ width: "200px" }}>
            <SelectSearch
              label="Tax Code"
              name="module"
              placeholder="Select module"
              // onChange={(val) => setFieldValue("module", val.value)}
              // error={errors.module && touched.module && true}
              // errorText={errors.module && touched.module && true}
            />
          </div>
          <div style={{ marginBottom: "9px" }}>
            <Button 
              size="sm"
              // variant="black"
              className='d-flex align-items-center ml-1 p-2 px-3'
              style={{ backgroundColor: "black", border: "none", borderRadius: "6px" }}
              // onClick={onClick}
            >
              {<FaPlus style={{ fontSize: '.9rem' }} />}
            </Button>
          </div>
        </div>

        <Table />

        <div className="mx-3 my-3 d-flex" style={{ marginBottom: "9px" }}>
          <div className="mr-1" style={{ width: "100%" }}>
            <Card>
              <Card.Body>
                <Button 
                  size="sm"
                  // variant="black"
                  className='d-flex align-items-center ml-1 p-2 px-3'
                  style={{ backgroundColor: "black", border: "none", borderRadius: "6px" }}
                  // onClick={onClick}
                >
                  UPLOAD FILE
                </Button>
              </Card.Body>
            </Card>
          </div>
          <div className="ml-1" style={{ width: "100%" }}>
            <Card>
              <Card.Body>
                  <Input
                    label="Subtotal"
                    readOnly
                    // onChange={(val) => setFieldValue("main_location", val.value)}
                  />
                  <Input
                    label="Tax"
                    readOnly
                    // onChange={(val) => setFieldValue("main_location", val.value)}
                  />
                  <Input
                    label="Allocated"
                    readOnly
                    // onChange={(val) => setFieldValue("main_location", val.value)}
                  />
                  <Input
                    label="Balance"
                    readOnly
                    // onChange={(val) => setFieldValue("main_location", val.value)}
                  />
                </Card.Body>
              </Card>
          </div>
        </div>

        <TableJournal />

        <div className="mx-3 my-3 d-flex justify-content-between">
          <div className="d-flex">
            <div className="mr-1">
              <Button 
                size="sm"
                // variant="black"
                className='d-flex align-items-center ml-1 p-2 px-3'
                style={{ backgroundColor: "black", border: "none", borderRadius: "6px" }}
                // onClick={onClick}
              >
                PRINT
              </Button>
            </div>
            <div className="mr-1">
              <Button 
                size="sm"
                // variant="black"
                className='d-flex align-items-center ml-1 p-2 px-3'
                style={{ backgroundColor: "black", border: "none", borderRadius: "6px" }}
                // onClick={onClick}
              >
                EMAIL
              </Button>
            </div>
            <div className="mr-1">
              <Button 
                size="sm"
                // variant="black"
                className='d-flex align-items-center ml-1 p-2 px-3'
                style={{ backgroundColor: "black", border: "none", borderRadius: "6px" }}
                // onClick={onClick}
              >
                FIND
              </Button>
            </div>
          </div>

          <Button 
            size="sm"
            // variant="black"
            className='d-flex align-items-center ml-1 p-2 px-3'
            style={{ backgroundColor: "black", border: "none", borderRadius: "6px" }}
            // onClick={onClick}
          >
            SAVE
          </Button>
        </div>
      </TableLayout>

      <TambahModal />
      <UbahModal />
      <HapusModal />
      <ModalDetail />
    </CRUDLayout>
  );
};
